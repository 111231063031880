<form [formGroup]="formGroup" (ngSubmit)="submit();" class="">

	<ng-container>

		<p-messages severity="warning" styleClass="p-mb-5" *ngIf="!is2faEnabled">
			<ng-template pTemplate>
				<div class="p-d-flex p-flex-column">
					<h3 class="p-m-0 p-pb-2">Twee Factor Authenticatie (2FA)</h3>
					<div class="">Met Twee Factor Authenticatie kunt u uw account beter beveiligen tegen hackers. Om 2FA in te schakelen voert u de volgende stapppen uit:
						<ul>
							<li>Installeer een TOTP-authenticator app, zoals Google Authenticator of Microsoft Authenticator</li>
							<li>Scan met deze app onderstaande qr-code</li>
							<li>Voer uw wachtwoord in, en kies 'inschakelen'</li>
						</ul>
					</div>
				</div>
			</ng-template>
		</p-messages>
		<p-messages severity="info" styleClass="p-mb-5" *ngIf="is2faEnabled">
			<ng-template pTemplate>
				<div class="p-d-flex p-flex-column">
					<h3 class="p-m-0 p-pb-2">Twee Factor Authenticatie (2FA)</h3>
					<div class="">
						2FA is ingeschakeld voor uw account. Als u dit wilt uitschakelen, vul dan uw wachtwoord in en klik op 'uitschakelen'.
					</div>
				</div>
			</ng-template>
		</p-messages>

		<div class="p-fluid">

			<div class="p-field" *ngIf="is2faEnabled !== null && !is2faEnabled">
				<img [src]="qrImageUrl" />
			</div>
			<div class="p-field">
				<label for="name" class="p-text-normal">Wachtwoord</label>

				<input id="name" type="password" pInputText autofocus formControlName="password"
					   [ngClass]="{'p-invalid': hasError(controls.password)}"/>

				<small id="name-help" class="p-invalid" *ngIf="hasError(controls.password, 'required')">
					Wachtwoord is verplicht.
				</small>
			</div>

			<div class="p-d-flex p-ai-center p-mt-5">
				<div class="p-text-light" *ngIf="pending">
					<i class="fas fa-spinner fa-spin"></i>
					<span class="p-pl-2">Bezig..</span>
				</div>

				<div class="p-ml-auto">
					<button pButton pRipple type="submit" *ngIf="!is2faEnabled"
							label="Inschakelen"
							icon="fa fa-save"
							[disabled]="pending || !controls.password.value"
							class="p-button-primary">
					</button>
					<button pButton pRipple type="submit" *ngIf="is2faEnabled"
							label="Uitschakelen"
							icon="fa fa-save"
							[disabled]="pending || !controls.password.value"
							class="p-button-danger">
					</button>
				</div>
			</div>
		</div>

	</ng-container>

</form>

import {Component, Input, OnInit} from '@angular/core';
import {IncidentsService} from "../../../services/data/incidents.service";
import {AnalysePanel} from "../../../classes/models/AnalysePanel";
import _ from "lodash";
import {FilterField} from "../../../types/sherpa";
import ExtentInteraction, {ExtentEvent, Options} from "ol/interaction/Extent";
import {shiftKeyOnly} from "ol/events/condition";
import {Fill, Style} from "ol/style";
import {Extent} from "ol/extent";
import {MapService} from "../../../services/map.service";
import {Coordinate} from "ol/coordinate";
import {Point} from "ol/geom";
import {Map as OLMap} from "ol";
import VectorLayer from "ol/layer/Vector";
import Geometry from "ol/geom/Geometry";
import SimpleGeometry from "ol/geom/SimpleGeometry";
import {StyleFunction} from "ol/style/Style";
import Feature from "ol/Feature";
import {StyleService} from "../../../services/style.service";

@Component({
	selector: 'base-incident-detail',
	templateUrl: './incident-detail.component.html',
	styleUrls: ['./incident-detail.component.scss']
})
export class IncidentDetailComponent implements OnInit {

	@Input() incident_id: number;
	@Input() inputPanel: AnalysePanel;

	panel: AnalysePanel;
	incident: any = null;

	// openlayers
	map: OLMap;
	layer: VectorLayer;

	constructor(private incidentsService: IncidentsService, private mapService: MapService, private styles: StyleService) {
	}

	ngOnInit(): void {
		this.panel = _.cloneDeep(this.inputPanel);
		const filterField = {key: 'incident_id', expectedValue: this.incident_id} as FilterField;

		this.panel.includeFields = [
			"incidentnumber",
			"importsource",
			"date",
			"time",
			"geom",
			"lineplanningnumber",
			"linename",
			"t_code",
			"abc_code",
			"dataownercode",
			"quaycode",
			"quayname",
			"location_type",
			"source",
			"concession"
		];
		this.panel.andFilterFields.push(filterField);

		this.incidentsService.getIncidentsForTable(this.panel)
			.then(result => {
				this.incident = _.head(result);
				this.initMap();
			});
	}

	initMap() {
		_.delay(() => {
			this.map = this.mapService.createMap('incident-map');

			this.layer = this.mapService.getLayer(StyleService.incidentStyle as StyleFunction, 10);
			this.map.addLayer(this.layer);

			if (this.incident != null && this.incident.geom !== null) {

				let feature = new Feature(new Point(this.incident.geom as Coordinate));
				this.layer.getSource().addFeature(feature);


				this.incident.geomAs4326 = this.mapService.transformTo4326(feature.getGeometry().getCoordinates());


				this.mapService.fit(this.map, feature.getGeometry());
			}
		}, 0);

	}



}

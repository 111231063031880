<div class="p-d-flex p-flex-column p-ai-stretch" [style.height]="'100%'">
	<div>
		<h1 class="p-m-0 p-pb-1">Incident inzien</h1>
		<h3 class="p-m-0 p-pb-3">Alle informatie betreft incident: #{{incident_id}}</h3>
	</div>
	<div class="p-sidebar-content p-mt-3">

		<div class="p-fluid" *ngIf="incident !== null">
			<div class="p-field">
				<label for="a">Incidentnummer</label>
				<input id="a" type="text" pInputText disabled [value]="incident.incidentnumber">
			</div>
			<div class="p-field">
				<label for="l">Bronbestand</label>
				<input id="l" type="text" pInputText disabled [value]="incident.importsource">
			</div>
			<div class="p-field">
				<label for="m">Datum</label>
				<input id="m" type="text" pInputText disabled [value]="incident.date">
			</div>
			<div class="p-field">
				<label for="n">Tijdstip</label>
				<input id="n" type="text" pInputText disabled [value]="incident.time">
			</div>
			<div class="p-field">
				<label for="incident-map">Locatie</label>
				<div id="incident-map" class="map" style="border: 1px solid #c7c7c7;"></div>
				<small id="incident-map-help" style="color: #4e4e4e">{{incident.geomAs4326 | json}}</small>
			</div>
			<div class="p-field">
				<label for="b">Concessie</label>
				<input id="b" type="text" pInputText disabled [value]="incident.concession">
			</div>
			<div class="p-field">
				<label for="c">Lijnnummer</label>
				<input id="c" type="text" pInputText disabled [value]="incident.lineplanningnumber">
			</div>
			<div class="p-field">
				<label for="d">Lijnnaam</label>
				<input id="d" type="text" pInputText disabled [value]="incident.linename">
			</div>
			<div class="p-field">
				<label for="e">Organisatie</label>
				<input id="e" type="text" pInputText disabled [value]="incident.dataownercode">
			</div>
			<div class="p-field">
				<label for="f">ABC-code</label>
				<input id="f" type="text" pInputText disabled [value]="incident.abc_code">
			</div>
			<div class="p-field">
				<label for="g">T-code</label>
				<input id="g" type="text" pInputText disabled [value]="incident.t_code">
			</div>
			<div class="p-field">
				<label for="h">Haltecode</label>
				<input id="h" type="text" pInputText disabled [value]="incident.quaycode">
			</div>
			<div class="p-field">
				<label for="i">Haltenaam</label>
				<input id="i" type="text" pInputText disabled [value]="incident.quayname">
			</div>
			<div class="p-field">
				<label for="j">Plek incident</label>
				<input id="j" type="text" pInputText disabled [value]="incident.location_type">
			</div>
			<div class="p-field">
				<label for="k">Bron</label>
				<input id="k" type="text" pInputText disabled [value]="incident.source">
			</div>
		</div>

	</div>
</div>
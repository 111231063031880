import { Component, OnInit } from '@angular/core';
import {AnalysePanel} from "../../../classes/models/AnalysePanel";
import {DynamicDialogConfig, DynamicDialogRef} from "primeng/dynamicdialog";

@Component({
  selector: 'base-edit-panel-order',
  templateUrl: './edit-panel-order.component.html',
  styleUrls: ['./edit-panel-order.component.scss']
})
export class EditPanelOrderComponent implements OnInit {


	panels: AnalysePanel[];


	constructor(public ref: DynamicDialogRef, private config: DynamicDialogConfig) {
	}

	ngOnInit() {
		this.panels = this.config.data.panels;
	}

	submit() {
		this.ref.close(this.panels);
	}

}

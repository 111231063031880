import {ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {MapService} from "../../../services/map.service";
import {StyleService} from "../../../services/style.service";
import _, {split} from "lodash";
import {IncidentsService} from "../../../services/data/incidents.service";
import {
	Chapter,
	EnumTables,
	Filter,
	GroupOption,
	MultiChartDataWithConfig,
	StoredConfig,
	StoredConfigsWrapper,
	SvovdaFields
} from "../../../types/sherpa";
import {MenuItem, MessageService} from "primeng/api";
import {Globals} from "../../../classes/Globals";
import {global} from "../../../../globals";
import {ConfigsService} from "../../../services/data/configs.service";
import {ActivatedRoute, Router} from "@angular/router";
import {BehaviorSubject, Subscription} from "rxjs";
import {AnalysePanel, PanelType} from "../../../classes/models/AnalysePanel";
import {StaticDataService} from "../../../services/data/static-data.service";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {sherpa, util} from "../../../../services";
import {DialogService} from "primeng/dynamicdialog";
import marked from "marked";
import {Location, registerLocaleData} from "@angular/common";
import localeNl from '@angular/common/locales/nl';
import {SessionService} from "../../../modules/auth/services/session.service";
import {UtilService} from "../../../services/util.service";

registerLocaleData(localeNl, 'nl');

@Component({
	selector: 'base-incidents',
	templateUrl: './incidents.component.html',
	styleUrls: ['./incidents.component.scss']
})
export class IncidentsComponent implements OnInit {

	global: Globals = global;
	hideAll = false;
	presentationMode = false;
	showReportPages: boolean;
	showCustomPage: boolean;
	showFrontpage: boolean;
	showImportSummary: boolean = true;

	// subscriptions
	navigationSubscription: Subscription;
	queryParamSubscription: Subscription;

	// subject for broadcasting to other components like sidebar + filters
	panelSubject: BehaviorSubject<AnalysePanel> = new BehaviorSubject<AnalysePanel>(null);
	filterSubject: BehaviorSubject<AnalysePanel> = new BehaviorSubject<AnalysePanel>(null);

	// page resolvers
	storedConfigs: StoredConfigsWrapper;

	// data
	fields: SvovdaFields = null;
	filtersABC: Filter[];
	filtersPolice: Filter[];
	panels: AnalysePanel[] = [];
	groupByOptions$: Promise<GroupOption[]>;
	groupByOptionsPolice$: Promise<GroupOption[]>;
	selectedStoredConfig: StoredConfig = null; // bookmarks table-item
	storedConfig: StoredConfig = null;
	myStoredConfigs: StoredConfig[] = [];

	// helpers
	stored_config_id: number;
	stored_config_temp_id: number;
	submitted = true;
	showSidebar = false;
	filterPathClean: string = null;
	filterPath = 'analyse';
	simplePanels: {index: number, label: string}[] = [];

	// menu
	@ViewChild('myFiltersPanel') myFiltersPanel: any;
	saveMenu: MenuItem[];

	// dialogs
	showSaveFilterDialog = false;
	showSharingDialog = false;

	pdfGenerating = false;
	periodDescription: string = null;
	enumtables: EnumTables;
	mode: 'analyses' | 'kpis';
	importSummaryColumns: string[];
	importSummaryRows: any = null;
	importSummaryTables: any = null;

	constructor(private cd: ChangeDetectorRef, public mapService: MapService, private util: UtilService, private route: ActivatedRoute, private router: Router, public styles: StyleService, public incidentsService: IncidentsService, public configsService: ConfigsService, private readonly http: HttpClient, public session: SessionService, private msg: MessageService) {
		this.setMenu();
	}

	ngOnInit(): void {
		this.filtersABC = this.route.snapshot.data['filters'] as Filter[];
		this.filtersPolice = this.route.snapshot.data['filtersPolice'] as Filter[];
		this.storedConfigs = this.route.snapshot.data['storedConfigs'] as StoredConfigsWrapper;
		this.mode = this.route.snapshot.data['mode'];

		this.myStoredConfigs = this.storedConfigs.myFilters;
		this.groupByOptions$ = this.incidentsService.getGroupOptions('incident');
		this.groupByOptionsPolice$ = this.incidentsService.getGroupOptions('mk_incident');

		this.initSubscribers();
	}

	initSubscribers() {
		// keep track of the queryParamMap to fetch new panels
		this.queryParamSubscription = this.route.queryParamMap.subscribe(async params => {

			/* PRESENTATION MODE */
			this.presentationMode = params.has('hideAll');
			this.showReportPages = params.has('asReport');
			this.showFrontpage = params.has('withFrontpage');
			this.showImportSummary = params.has('withImportSummary');

			global.hideAll = this.presentationMode;
			this.util.globalChanged.next();

			if (global.hideAll) {
				this.showSidebar = false;
				await this.configsService.sleep(5000);
			}

			if (this.showReportPages && false) {
				this.incidentsService.getFields().then(result => {
					this.fields = result;
					this.drawAppendixTable();
				});

				this.incidentsService.getEnumTables().then(result => {
					this.enumtables = result;
				});
			}

			if(this.showImportSummary) {

				this.incidentsService.getImportSummary().then(r => {
					this.importSummaryColumns = _.uniq(_.map(r, 'month'));
					this.importSummaryRows = {};
					this.importSummaryTables = {};

					const concessions = _.uniq(_.map(r, 'concessioncode'));

					if(concessions.length > 0) {
						this.importSummaryRows = {};

						_.forEach(concessions, code => {
							this.importSummaryRows[code] = [];

							_.forEach(this.importSummaryColumns, month => {

								let item = _.find(r, {month: month, concessioncode: code});
								if(item) {
									this.importSummaryRows[code].push(item);
								}

							});
						});

						const showRows = 10;
						const result: any = {};
						let index: number = 0;
						_.forEach(this.importSummaryRows, (value, key) => {
							const table = _.floor(index / showRows);
							(result[table] || (result[table] = [])).push({key, value});
							index++;
						});

						this.importSummaryTables = result;
					}
				});
			}

			/* STORE PATH */
			const isAnalyse = params.has('filter');
			this.filterPath = isAnalyse ? params.get('filter') : 'analyse';
			this.filterPathClean = isAnalyse ? _.replace(this.filterPath, new RegExp('-', 'g'), ' ') : null;

			/* SHARED CONFIG */
			let sharingCode = null;
			if(params.has('code')) {
				sharingCode = params.get('code');
			}

			/* INIT */
			// the + converts a string to a number
			this.stored_config_id = +params.get('id');
			this.stored_config_temp_id = +params.get('tmp_id');

			this.init(sharingCode);
		});
	}

	init(sharingCode: string = null) {
		this.panels = [];
		this.showCustomPage = false;

		// 0 means -> not set in url
		if (this.stored_config_id === 0 && this.stored_config_temp_id === 0 && sharingCode == null) {
			this.storedConfig = null;
			this.createPanel(false);
			this.initPanels(this.panels); // no sure if needed
			return;
		}

		const cb = (wrapper: MultiChartDataWithConfig) => {
			this.storedConfig = _.find(this.myStoredConfigs, ['stored_config_id', this.stored_config_id]) || null;
			this.periodDescription = wrapper.periodDescription;

			this.setMenu();
			this.createPanelsByConfig(wrapper);

			if(this.storedConfig != null) {
				this.showCustomPage = !_.isNil(this.storedConfig.title) || !_.isNil(this.storedConfig.description);
			}
		}

		if(sharingCode == null) {
			const is_temp = this.stored_config_temp_id > 0;
			const fetch_id = is_temp ? this.stored_config_temp_id : this.stored_config_id;

			this.configsService.getPanelsForConfigId(fetch_id).then(cb);
		}
		else {
			this.configsService.getPanelsForSharingCode(sharingCode).then(wrapper => {
				this.stored_config_id = 0;
				this.stored_config_temp_id = 0;
				cb(wrapper);

				this.storedConfig = {} as StoredConfig;
				this.storedConfig.label = wrapper.storedConfig.label;
				this.storedConfig.title = wrapper.storedConfig.title;
				this.storedConfig.description = wrapper.storedConfig.description;
				this.storedConfig.show_in_menu = false;

				this.showSharingDialog = true;
			});
		}
	}


	/* PANELS */

	createPanelsByConfig(wrapper: MultiChartDataWithConfig) {
		// set resultData
		_.forEach(wrapper.config, (config, key) => {
			if (config !== null && config.type !== null && config.type !== PanelType.map) {
				config.resultData = wrapper.chartData[key];
			}
		});

		const panels: AnalysePanel[] = [];
		const configs = _.filter(wrapper.config, _.negate(_.isNull));
		let i = 0;

		_.forEach(configs, config => {
			const panel = _.assignIn(new AnalysePanel(), config) as AnalysePanel;
			panel.index = i;
			i++;
			panels.push(panel);
		});

		this.initPanels(panels);
	}

	createPanel(toStart: boolean) {
		const panel = new AnalysePanel();

		if (toStart) {
			this.panels = [panel, ...this.panels];
		} else {
			this.panels.push(panel);
		}

		this.updatePanelSequence();

		// scroll to end
		_.delay(() => {
			const el = document.querySelector('#grid-container .grid-panel:last-child');

			el.scrollIntoView({
				behavior: 'smooth',
				block: 'end'
			});
		}, 0);
	}

	initPanels(panels: AnalysePanel[]) {
		_.forEach(panels, panel => {
			if(panel.table == 'mk_incident') {
				panel.filters = _.cloneDeep(this.filtersPolice);
				panel.groupByOptions$ = this.groupByOptionsPolice$;
			}
			else {
				panel.filters = _.cloneDeep(this.filtersABC);
				panel.groupByOptions$ = this.groupByOptions$;
			}

			panel.orgResultData = _.cloneDeep(panel.resultData);
		})

		this.panels = panels;
	}

	deselectPanel(panel: AnalysePanel) {
		this.panels = _.reject(this.panels, ['index', panel.index]);
		this.updatePanelSequence();
	}

	panelDuplicateData(event: { panel: AnalysePanel, index: number, callback: Function }) {
		const panel = event.panel;
		const target = this.panels[event.index];

		panel.andFilterFields = target.andFilterFields;
		panel.includeFields = target.includeFields;
		panel.groupBy = target.groupBy;
		panel.orderByIncidents = target.orderByIncidents;
		panel.sortOrder = target.sortOrder;
		panel.relevantFiltersOnly = target.relevantFiltersOnly;
		panel.comparison = target.comparison;
		panel.extent = target.extent;
		panel.top = target.top;

		// sent updated panel back
		event.callback(panel);
		this.panelUpdated(panel);
	}

	panelUpdated(panel: AnalysePanel, broadcast = false) {
		// panel is updated in child-component, override for future saving
		this.panels[panel.index] = panel;
	}

	/* PANEL GRID */

	movePanel(event: { panel: AnalysePanel, toSequence: number }) {
		if (event.toSequence < 0) {
			event.toSequence = 0;
		}

		// first remove
		this.panels = _.reject(this.panels, ['index', event.panel.index]);

		// add to new position
		this.panels.splice(event.toSequence, 0, event.panel);

		// update all keys
		this.updatePanelSequence();
	}

	updatePanelSequence() {
		_.forEach(this.panels, (panel, key) => {
			panel.index = key;
		});
	}


	/* FILTER */

	applyFilters(event: AnalysePanel) {
		this.filterSubject.next(this.panels[event.index]);
	}

	openSaveFilterDialog(myConfig: StoredConfig, updateConfig: boolean, createNewConfig: boolean) {
		if (myConfig !== null) {
			this.selectedStoredConfig = myConfig;
			if (this.selectedStoredConfig !== null && this.selectedStoredConfig !== undefined) {
				this.selectedStoredConfig.config = null; // we dont know this, null will be skipped in backend
				this.selectedStoredConfig._updateConfig = updateConfig;
				this.selectedStoredConfig._createNewConfig = createNewConfig;
			}
		}

		this.showSaveFilterDialog = true;
	}

	openPanelFilter(index: number) {
		this.showSidebar = !this.showSidebar;
		// broadcast new active panel
		this.panelSubject.next(this.panels[index]);
	}

	afterSaveFilter(storedConfig: StoredConfig) {

		if (storedConfig.stored_config_id !== null) {

			// remove from menu first, will be add later if "show_in_menu"
			_.remove(global.menuItems, (menuItem: MenuItem) => {
				return menuItem.queryParams?.id === storedConfig.stored_config_id;
			});

			this.myStoredConfigs = util.upsert(this.myStoredConfigs, storedConfig, 'stored_config_id');

			if(this.storedConfig != null) {
				this.storedConfig.title = storedConfig.title;
				this.storedConfig.description = storedConfig.description;
			}
			this.showSharingDialog = false; // in case this was open
		}

		let menuItem = {
			label: storedConfig.label,
			routerLink: '/analyses',
			queryParams: {filter: _.kebabCase(storedConfig.label), id: storedConfig.stored_config_id}
		} as MenuItem;

		if (storedConfig.show_in_menu) {
			global.menuItems.push(menuItem);
		}

		this.cd.markForCheck();
		this.cd.detectChanges();

		this.util.menuChanged.next();

		this.msg.add({
			key: 'dova-toast',
			severity:'success',
			summary:'Geslaagd',
			detail:'Analyse is succesvol opgeslagen'
		});

		this.router.navigate([menuItem.routerLink], {queryParams: menuItem.queryParams});
	}

	afterDeleteFilter(id: any) {

		_.remove(global.menuItems, (menuItem: MenuItem) => {
			return menuItem.queryParams?.id === id;
		});

		this.router.navigate(['/analyses', 'nieuw']);
	}

	onRowSelect(event: any) {
		this.myFiltersPanel.hide();

		const storedConfig = event.data as StoredConfig;
		const params = {filter: _.kebabCase(storedConfig.label), id: storedConfig.stored_config_id};

		this.router.navigate(['/analyses'], {queryParams: params});
	}


	/* PRESENTATION MODE */

	storeTempConfig(): Promise<StoredConfig> {
		let cleanPanels = [] as AnalysePanel[];
		let panels = _.cloneDeep(this.panels);

		_.forEach(panels, panel => {
			let clean = _.omit(panel, this.incidentsService.panelAdditionProperties) as AnalysePanel;
			cleanPanels.push(clean);
		});

		let range = _.range(cleanPanels.length);
		_.forEach(range, i => {
			if (!this.panels[i].visible) {
				cleanPanels[i] = null;
			}
		});

		const concept = {
			config: cleanPanels,
			label: `temp_${this.stored_config_id}_${new Date().getTime()}`,
			show_in_menu: false
		} as StoredConfig;

		return this.configsService.saveTempStoredConfig(concept);
	}

	openPresentation() {
		// save concept in db
		this.storeTempConfig().then(config => {
			const params = {
				filter: this.filterPath,
				id: this.stored_config_id,
				tmp_id: config.stored_config_id,
				hideAll: true
			};

			this.router.navigate(['/analyses'], {queryParams: params});
		});
	}

	closePresentation() {
		const params = {
			filter: this.filterPath,
			id: this.stored_config_id,
			tmp_id: this.stored_config_temp_id
		};


		this.router.navigate(['/analyses'], {queryParams: params});
	}

	isPrintable(panel: AnalysePanel) {
		if (panel.resultData.datasets.length > 4 && panel.show_labels) {
			return false;
		}

		if (panel.resultData.datasets.length > 18 && !panel.show_labels) {
			return false;
		}

		return true;
	}

	downloadFile(): void {
		const id = this.stored_config_temp_id > 0 ? this.stored_config_temp_id : this.stored_config_id;

		const baseUrl = `/rest/render/${this.session.token()}/${id}/pdf`;
		const headers = new HttpHeaders();
		//headers.set('authorization','Bearer '+token);

		this.pdfGenerating = true;

		this.http.get(baseUrl, {headers, responseType: 'blob' as 'json'}).subscribe(
			(response: any) => {
				let dataType = 'application/pdf';
				let binaryData = [];
				binaryData.push(response);

				let downloadLink = document.createElement('a');
				downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
				downloadLink.setAttribute('download', this.filterPath);

				document.body.appendChild(downloadLink);
				downloadLink.click();

				this.pdfGenerating = false;
			}
		)
	}

	drawAppendixTable() {

		let id = 1;
		const maxHeight = 760;
		const keys = ['title', 'type', 'instructions.instruction_text', 'requirements.required'];

		let page = document.getElementById('page-appendix-' + id);
		let body = page.querySelector('.body.index');
		let table = body.querySelector('.appendix-table');
		let tableBody = table.querySelector('tbody');


		_.forEach(this.fields.incident_fields, field => {

			const row = document.createElement('tr');

			_.forEach(keys, path => {
				const td = document.createElement('td');
				//td.style.lineHeight = '22px';
				td.style.lineHeight = 'unset';
				let value = _.get(field, path, '');

				if (path === 'title' && _.includes(['Concessienaam', 'Concessieverlener'], value)) {
					td.style.overflowWrap = 'break-word';
				}

				if (path === 'requirements.required') {
					td.textContent = (value as boolean) ? 'Ja' : 'Nee';
					;
				} else if (path === 'instructions.instruction_text') {
					const content = document.createElement('div');
					content.innerHTML = this.asMarkdown(value);
					td.colSpan = 3;
					td.appendChild(content);
				} else {
					td.textContent = value;
				}

				row.appendChild(td);
			});


			tableBody.appendChild(row);


			if (tableBody.clientHeight > maxHeight) {

				tableBody.removeChild(row);
				id++;

				page = this.createPage(page, id);

				body = page.querySelector('.body.index');
				table = body.querySelector('.appendix-table');
				tableBody = table.querySelector('tbody');

				tableBody.appendChild(row);
			}

			return true;
		});
	}

	createPage(currentPage: HTMLElement, newId: number): HTMLElement {

		const page = document.createElement('div');
		page.id = 'page-appendix-' + newId;
		page.classList.add('page4', 'p-d-flex', 'p-flex-column', 'p-p-5');

		const head = document.createElement('div');
		head.classList.add('head', 'p-mb-5');
		const logo = document.createElement('img');
		logo.src = 'assets/img/rapport_logo_dova.jpg';
		logo.style.paddingLeft = '2rem';
		logo.style.height = '3.25rem';
		head.appendChild(logo);

		const body = document.createElement('div');
		body.classList.add('body', 'index', 'p-d-flex', 'p-flex-column', 'p-p-5');
		body.style.height = '100%';

		const footer = document.createElement('div');
		footer.classList.add('footer', 'p-mt-6', 'p-mb-2', 'p-d-flex');
		const left = document.createElement('div');
		const right = document.createElement('div');
		right.className = 'p-ml-auto';
		left.textContent = 'Maandelijkse rapportage sociale veiligheid in het openbaar vervoer';
		right.textContent = 'n+' + newId;
		footer.style.color = '#5A5A5A';
		footer.style.fontSize = '12px';
		footer.appendChild(left);
		footer.appendChild(right);

		const tableDiv = document.createElement('div');
		tableDiv.classList.add('appendix-table', 'p-datatable-dova', 'p-datatable');
		const table = document.createElement('table');
		const thead = document.createElement('thead');
		thead.className = 'p-datatable-thead';
		const tbody = document.createElement('tbody');
		tbody.className = 'p-datatable-tbody';
		const tr = document.createElement('tr');

		const fields = ['Naam', 'Type', 'Definitie', 'Verplicht'];
		_.forEach(fields, label => {
			const th = document.createElement('th');
			th.textContent = label;
			th.colSpan = label === 'Definitie' ? 3 : 1;
			tr.appendChild(th);
		});

		thead.appendChild(tr);

		table.appendChild(thead);
		table.appendChild(tbody);

		tableDiv.appendChild(table);

		body.appendChild(tableDiv);

		page.appendChild(head);
		page.appendChild(body);
		page.appendChild(footer);

		currentPage.parentElement.append(page);

		return page;
	}

	setMenu() {
		this.saveMenu = [{
			label: this.storedConfig?.label || 'Analyse' + ':',
			items: [{
				label: 'Opslaan..',
				disabled: _.isNil(this.storedConfig),
				command: () => {
					this.storedConfig.config = [];
					this.openSaveFilterDialog(this.storedConfig, true, false);
				}
			}, {
				label: 'Opslaan als..',
				command: () => {

					this.openSaveFilterDialog(this.storedConfig, true, true);
				}
			}]
		}];
	}

	getChapters(start_page_number: number, start_section_number: number) {
		let chapters: Chapter[] = [];

		_.forEach(this.panels, (p, index) => {
			let c: Chapter = {
				header: p.caption, page: start_page_number + index, section: start_section_number + index
			}
			chapters.push(c);
		});

		let cb: Chapter = {
			header: 'Bijlagen', page: start_page_number + this.panels.length, section: null
		}
		//chapters.push(cb);
		return chapters;
	}


	/* HELPERS */

	print() {
		window.print();
	}

	copyUrl() {
		sherpa.api$.toPromise().then(s => {
			const baseUrl = _.chain(s.json.baseurl).trimEnd( 'api/').trimEnd('/');
			const shareUrl = baseUrl + '/analyses?code=' + this.storedConfig.sharing_code;

			this.util.copy(shareUrl, 'Unieke URL');
		});
	}

	asMarkdown(txt: string) {
		return marked(txt);
	}

	getDate() {
		return new Date();
	}

	getUserInfoString(withMail = false) {
		const info = this.session.getUser().name;
		return withMail ? `${info}, ${this.session.getUser().email}` : info;
	}

	/* unsubscribe when changing pages */
	ngOnDestroy() {
		this.panelSubject.unsubscribe();

		if (this.navigationSubscription) {
			this.navigationSubscription.unsubscribe();
		}
		if (this.queryParamSubscription) {
			this.queryParamSubscription.unsubscribe();
		}
	}

	getNow() {
		return new Date();
	}

	months: string[] = ['Jan', 'Feb', 'Maa', 'Apr', 'Mei', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dec'];

	friendlyMonth(month: string) {
		const s = split(month, '-');
		const i = _.toInteger(s[1]);
		return this.months[i-1] + '-' + s[0]
	}
}

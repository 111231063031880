import {Component, ElementRef, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import Feature from "ol/Feature";
import _ from "lodash";
import Point from "ol/geom/Point";
import Geometry from "ol/geom/Geometry";
import {IncidentsGrid} from "../../../types/sherpa";
import Polygon from "ol/geom/Polygon";
import VectorLayer from "ol/layer/Vector";
import {StyleService} from "../../../services/style.service";
import {StyleFunction} from "ol/style/Style";
import Select from "ol/interaction/Select";
import {click} from "ol/events/condition";
import {AnalysePanel, PanelType} from "../../../classes/models/AnalysePanel";
import {Extent} from "ol/extent";
import View from "ol/View";
import * as olExtent from "ol/extent";
import {Map as OLMap} from "ol";
import VectorSource from "ol/source/Vector";
import {BehaviorSubject, Subscription} from "rxjs";
import {IncidentsService} from "../../../services/data/incidents.service";
import {MapService} from "../../../services/map.service";
import {global} from "../../../../globals";
import {MessageService} from "primeng/api";
import {UtilService} from "../../../services/util.service";

@Component({
	selector: 'base-card-map',
	templateUrl: './card-map.component.html',
	styleUrls: ['./card-map.component.scss']
})
export class CardMapComponent implements OnInit, OnDestroy {

	@ViewChild('mapContainer') mapContainer: ElementRef;
	@Input() panel: AnalysePanel = null;
	@Input() reloadSubject: BehaviorSubject<AnalysePanel>;
	@Input() presentationMode = false;

	reloadSubscription: Subscription;
	previousMenu = global.menu;
	loading = false;

	// openlayers
	mapElementId = _.uniqueId('incidents-map-');
	map: OLMap;
	vectorIncidentSource = new VectorSource();
	vectorPoliceSource = new VectorSource();
	incidentsLayer: VectorLayer;
	policeLayer: VectorLayer;
	gridLayer: VectorLayer;
	currZoom = 0;
	zoomSwitchLevel = 12;
	gridCache = new Map<number, any>();
	policeColor = '#AD5F00';
	mapColors: {color: string, label: string}[] = [];


	constructor(private mapService: MapService, private styles: StyleService, private incidentsService: IncidentsService, private util: UtilService, private msg: MessageService) {
	}

	ngOnInit(): void {
		this.reloadSubscription = this.reloadSubject.subscribe(panelWithUpdates => {
			if(panelWithUpdates == null) {
				return;
			}

			if(panelWithUpdates.index == this.panel.index) {
				this.gridCache.clear(); // could be new data, so clear
				this.panel = panelWithUpdates;
				this.setMapExtentConstraint();
				this.moveEndHandler(true); // force fetching data by zoomLevel
			}
		});

		this.setup();
	}

	async setup() {
		const mapColors = await this.incidentsService.getColors('abc_main_code');
		this.mapColors = _.sortBy(mapColors, 'label');

		this.initMap();
		this.getIncidentsGrid().then(result => {
			this.initGrid(result);
		});
	}

	getIncidentsGrid(opt_grid?: number) {
		this.loading = true;
		return this.incidentsService.getIncidentsGrid(this.panel, opt_grid).then(result => {
			this.panel.resultData = result;
			this.loading = false;
			return result;
		});
	}

	getIncidents(extent: Extent, checkbox = false) {

		this.incidentsService.getIncidents(this.panel, extent, checkbox).then(result => {
			_.delay(() => {

				if(this.panel.table == 'incident') {
					if(checkbox) {
						this.initPoliceLayer(result);
					}
					else {
						this.panel.resultData = result;
						this.initIncidentsLayer(result);
					}
				} else {
					if(checkbox) {
						this.initIncidentsLayer(result);
					}
					else {
						this.panel.resultData = result;
						this.initPoliceLayer(result);
					}
				}

			}, 0);
		});
	}

	getPoliceIncidents(extent: Extent) {

		const checkbox = this.panel.show_police;

		if(this.panel.table == 'incident') {
			if(!checkbox) {
				this.vectorPoliceSource.clear();
				this.vectorPoliceSource.changed();
			}
		}
		else {
			if(!checkbox) {
				this.vectorIncidentSource.clear();
				this.vectorIncidentSource.changed();
			}
		}

		this.getIncidents(extent, checkbox);
	}

	setPresentationMap() {
		const mapPanel = this.panel;

		this.map.getControls().forEach(control => {
			this.map.removeControl(control);
		});

		this.map.getInteractions().forEach(interaction => {
			interaction.setActive(false);
		});

		if (mapPanel.latest_extent === null) {
			return;
		}

		const view = new View({
			center: olExtent.getCenter(mapPanel.latest_extent),
			extent: mapPanel.latest_extent,
			zoom: this.map.getView().getZoom()
		});

		this.map.setView(view);
	}


	/* OPENLAYERS */

	initIncidentsLayer(data: any[]) {
		this.vectorIncidentSource.clear(true);
		let features: Feature[] = [];
		let emptyGeomCounter: number = 0;

		const bronze = this.policeColor;
		const a = _.find(this.mapColors, ['label', 'A']);
		const b = _.find(this.mapColors, ['label', 'B']);
		const c = _.find(this.mapColors, ['label', 'C']);

		_.forEach(data, item => {
			if (item.geom === null || item.geom === undefined || item.geom.length === 0) {
				emptyGeomCounter++;
				return; // continue
			}

			let geom = new Point(item.geom) as Geometry;
			let feat = new Feature(geom);
			feat.setId(item.incident_id);

			if (item.abc_code?.length > 0) {
				let code = item.abc_code[0];
				let color;
				if (code === a.label) {
					color = a.color;
				}
				if (code === b.label) {
					color = b.color;
				}
				if (code === c.label) {
					color = c.color;
				}

				feat.set('abc_code', code);
				feat.set('color', color);

			} else {
				feat.set('abc_code', 'P');
				feat.set('color', bronze);
			}

			features.push(feat);
		});

		if (emptyGeomCounter > 0) {
			//console.log(`Skipped ${emptyGeomCounter} items with empty geom`);
		}

		//console.log(`${features.length} items added`);

		this.vectorIncidentSource.addFeatures(features);
		this.vectorIncidentSource.changed();
	}

	initPoliceLayer(data: any[]) {
		this.vectorPoliceSource.clear(true);
		const features: Feature[] = [];
		let emptyGeomCounter = 0;

		_.forEach(data, item => {
			if (item.geom === null) {
				emptyGeomCounter++;
				return; // continue
			}

			const geom = new Point(item.geom);
			const feat = new Feature(geom);
			feat.setId(item.mk_incident_id);
			feat.set('abc_code', 'P');
			feat.set('color', this.policeColor);
			features.push(feat);
		});

		if (emptyGeomCounter > 0) {
			//console.log(`Skipped ${emptyGeomCounter} items with empty geom`);
		}

		//console.log(`${features.length} police-items added`);

		this.vectorPoliceSource.addFeatures(features);
		this.vectorPoliceSource.changed();
	}

	initGrid(data: IncidentsGrid[]) {
		this.gridLayer.getSource().clear();
		const features: Feature[] = [];

		_.forEach(data, (item: IncidentsGrid) => {
			const feat = new Feature(new Polygon(item.geom));
			feat.set('value', item.incidents)
			features.push(feat);
		});

		this.gridLayer.getSource().addFeatures(features);
		this.gridLayer.getSource().changed();
	}

	initMap() {
		this.map = this.mapService.createMap(this.mapElementId);
		this.setMapExtentConstraint();

		this.incidentsLayer = new VectorLayer({
			source: this.vectorIncidentSource,
			style: StyleService.abcMainStyle as StyleFunction,
			visible: false,
			zIndex: 2
		});
		this.policeLayer = new VectorLayer({
			source: this.vectorPoliceSource,
			style: StyleService.abcMainStyle as StyleFunction,
			visible: false,
			zIndex: 1
		});
		this.gridLayer = this.mapService.getLayer(this.styles.heatMapStyle as StyleFunction, 10);

		this.map.addLayer(this.incidentsLayer);
		this.map.addLayer(this.policeLayer);
		this.map.addLayer(this.gridLayer);

		this.currZoom = this.map.getView().getZoom();
		this.map.on('moveend', e => this.moveEndHandler());

		if (this.presentationMode) {
			this.setPresentationMap();
		}
	}

	setMapExtentConstraint() {
		if(_.isNil(this.map)) {
			return;
		}

		const extent = this.panel.extent as Extent;
		const zoom = this.map.getView().getZoom();
		let view;

		if (_.isNil(extent)) {
			view = new View({
				center: this.map.getView().getCenter(),
				zoom: zoom
			});
		} else {
			view = new View({
				center: olExtent.getCenter(extent),
				zoom: zoom,
				extent: extent
			});
		}

		view.setMaxZoom(17);
		this.map.setView(view);

		if (this.panel.latest_extent !== null) {
			this.map.getView().fit(this.panel.latest_extent);
		}

		this.updateMapSize();
	}

	updateMapSize() {
		if (_.isNil(this.map)) {
			return;
		}

		_.delay(() => {
			this.map.updateSize();
		}, 100);
	}

	moveEndHandler(force: boolean = false) {
		if(!this.map) {return;}

		const mapPanel = this.panel;
		const newZoom = this.map.getView().getZoom();
		const extent = this.map.getView().calculateExtent(this.map.getSize());

		// update size when menu toggles
		if (this.previousMenu != global.menu) {
			this.map.updateSize();
			this.previousMenu = global.menu;
		}

		if (newZoom > this.zoomSwitchLevel) {
			this.gridLayer.setVisible(false);

			const checkbox = mapPanel.show_police;

			if(this.panel.table == 'incident') {
				this.incidentsLayer.setVisible(true);
				this.policeLayer.setVisible(checkbox);
				this.getIncidents(extent);

				if(checkbox) {
					this.getPoliceIncidents(extent);
				}
			}
			else {
				this.incidentsLayer.setVisible(checkbox);
				this.policeLayer.setVisible(true);
				this.getPoliceIncidents(extent);

				if(checkbox) {
					this.getIncidents(extent);
				}
			}

		} else {
			this.gridLayer.setVisible(true);
			this.incidentsLayer.setVisible(false);
			this.policeLayer.setVisible(false);

			let grid = 10000;

			if (newZoom !== this.currZoom || force) {

				if (newZoom > 11) {
					grid = 500;
				} else if (newZoom > 10) {
					grid = 1000;
				} else if (newZoom > 9) {
					grid = 2500;
				} else if (newZoom > 8) {
					grid = 5000;
				}

				if (this.gridCache.has(grid)) {
					//console.log('use cache', grid);
					this.initGrid(this.gridCache.get(grid));
				} else {
					this.getIncidentsGrid(grid).then(result => {
						//console.log('store cache', grid);
						this.gridCache.set(grid, result);
						this.initGrid(result);
					});
				}
			}
		}

		this.panel.latest_extent = extent;
		this.currZoom = newZoom;
	}


	/* HELPERS */

	copyRequest() {
		const canvas = this.mapContainer.nativeElement.getElementsByTagName('canvas')[0];
		this.util.copyCanvas(canvas);
	}

	downloadRequest() {
		const canvas = this.mapContainer.nativeElement.getElementsByTagName('canvas')[0];
		const anchor = document.createElement("a");
		anchor.href = canvas.toDataURL("image/png");
		anchor.download = "export.png";
		anchor.click();
	}

	ngOnDestroy() {
		if(this.reloadSubscription) {
			this.reloadSubscription.unsubscribe();
		}
	}
}

import { Component, OnInit } from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {DynamicDialogConfig} from "primeng/dynamicdialog";
import {MessageService} from "primeng/api";
import _ from "lodash";
import {SessionService} from "../../services/session.service";
import {authApi, authJson} from "../../guards/auth.guard";

@Component({
  selector: 'base-twofactor',
  templateUrl: './twofactor.component.html',
  styleUrls: ['./twofactor.component.scss']
})
export class TwofactorComponent implements OnInit {

	formGroup: FormGroup;
	pending: boolean = false;
	done: boolean = false;
	is2faEnabled: boolean = null;
	qrImageUrl: string = null;

	public get controls() {
		return this.formGroup.controls;
	}

	constructor(private fb: FormBuilder, public config: DynamicDialogConfig, private sessionService: SessionService, private msg: MessageService) {

		this.sessionService.is2faEnabled().then((b) => {
			this.is2faEnabled = b;
		});

		const authBaseUrl = _.chain(authJson.baseurl).trimEnd('/').trimEnd('/api').value();
		this.qrImageUrl = authBaseUrl + '/img/' + this.sessionService.token();
	}

	ngOnInit() {
		this.formGroup = this.fb.group({
			password: ['', Validators.required]
		});
	}

	hasError(control: AbstractControl, errorCode: string = null): boolean {

		// no interaction yet
		if (control.pristine || control.untouched) {
			return false;
		}

		// match specific errorCode
		if (errorCode !== null) {
			return control.hasError(errorCode);
		}

		// return true when some errorCode
		return !_.isNil(control.errors);
	}

	submit() {
		if (this.formGroup.invalid) {
			return;
		}

		this.pending = true;

		const user = this.config.data.me;

		const obj = {
			user_id: user.user_id,
			tfa_password: this.controls.password.value
		};

		_.delay(() => {
			this.pending = false;
		}, 2000);

		// todo
		this.sessionService.enable2fa(obj.tfa_password, !this.is2faEnabled).then((r) => {

			this.sessionService.is2faEnabled().then((b) => {
				this.is2faEnabled = b;
				this.msg.add({
					severity: 'success',
					summary: '2fa' ,
					detail: 'Two-factor authentication is nu ' + (b ? 'ingeschakeld' : 'uitgeschakeld')
				})
			});
		});
	}

}

<form [formGroup]="form" novalidate>
	<div class="p-fluid p-formgrid p-grid">
		<div class="p-field-checkbox p-col-12">
			<p-checkbox name="showInMenu" formControlName="showInMenu" inputId="showInMenu"
						[binary]="true"></p-checkbox>
			<label class="p-unselectable-text" [for]="'showInMenu'">Toon deze analyse als menu-item</label>
		</div>
		<div class="p-field p-col-12">
			<label for="name" class="p-text-normal">
				<span>Naam</span>
				<i class="fas fa-info-circle fa-sm p-pl-1"
				   appendTo="body"
				   tooltipZIndex="12000"
				   pTooltip="Voer een duidelijke naam in, bijv: A-incidenten, Midden Overijssel, 2022"
				   tooltipPosition="right"></i>
			</label>
			<input id="name" type="text" pInputText formControlName="name">
			<small id="name-help2" class="p-invalid" *ngIf="controls.name.invalid && controls.name.dirty">
				Naam is verplicht
			</small>
			<small class="p-invalid" id="name-error" style="float: left" *ngIf="duplicateError">
				Deze naam bestaat al, kies een andere naam
			</small>
		</div>
		<div class="p-field p-col-12">
			<label for="title" class="p-text-normal">Titel</label>
			<input id="title" type="text" pInputText formControlName="title">
		</div>
		<div class="p-field p-col-12">
			<label for="descr2" class="p-text-normal">
				<span>Beschrijving</span>
				<i class="fas fa-info-circle fa-sm p-pl-1"
				   appendTo="body"
				   tooltipZIndex="12000"
				   pTooltip="U kunt 'Markdown' gebruiken voor de opmaak"
				   tooltipPosition="right"></i>
			</label>
			<textarea id="descr2" pInputTextarea formControlName="description"
					  [style]="{'width': '100%'}"
					  [rows]="8"
					  [autoResize]="true"></textarea>
		</div>

		<div class="p-field p-col-12">
			<label for="recipients" class="p-text-normal">
				<span>Ontvangers</span>
				<i class="fas fa-info-circle fa-sm p-pl-1"
				   appendTo="body"
				   tooltipZIndex="12000"
				   pTooltip="Deze analyse wordt maandelijkse naar deze e-mailadressen verstuurd."
				   tooltipPosition="right"></i>
			</label>

			<p-chips id="recipients" formControlName="recipients" styleClass="p-chips-multiple-container"
					 [allowDuplicate]="false" [addOnBlur]="true" [addOnTab]="true"
					 separator="," placeholder="Gebruik 'enter' na het invoeren van een e-mailadres"></p-chips>
			<small id="recipients-help"></small>

			<small class="p-invalid" id="recipient-error" style="float: left"
				   *ngIf="controls.recipients.errors?.invalidRecipient">
				Bevat een ongeldige ontvanger: <strong>{{controls.recipients.errors?.invalidRecipient}}</strong>. Alleen
				"@{{myDomain}}" is toegestaan.
			</small>
		</div>
	</div>

	<ng-container *ngIf="controls.recipients.value != null && controls.recipients.value.length && !controls.recipients.errors?.invalidRecipient">
		<button pButton pRipple label="Direct versturen" icon="far fa-envelope" class="p-button-outlined"
				(click)="emailRecipients()"></button>
	</ng-container>
</form>

<p-toolbar styleClass="p-p-0 p-mt-5">
	<div class="p-toolbar-group-left">
		<button pButton pRipple type="button" icon="fa fa-trash-alt"
				label="Verwijder"
				class="p-button-danger p-button-rounded"
				*ngIf="storedConfig?.stored_config_id"
				(click)="confirmDelete()"></button>
	</div>

	<div class="p-toolbar-group-right">
		<button pButton pRipple type="button" label="Sluiten"
				class="p-button-secondary p-button-text p-button-rounded p-mr-2"
				(click)="close()"></button>
		<button pButton pRipple type="button" label="Opslaan" class="p-button-rounded p-text-uppercase"
				*ngIf="!pending; else loading;"
				(click)="submit()"
				[disabled]="form.invalid ? true : null"></button>

		<ng-template #loading>
			<button pButton [disabled]="true" icon="far fa-spinner-third fa-spin"
					class="p-button-primary p-button-rounded p-button-icon-only"></button>
		</ng-template>
	</div>
</p-toolbar>

<p-confirmDialog #cd [style]="{width: '30rem'}" [baseZIndex]="10000" key="confirm-delete">
	<p-footer>
		<button pButton pRipple type="button" label="Nee"
				class="p-button-secondary p-button-text p-button-rounded p-mr-2"
				(click)="cd.reject()"></button>
		<button pButton pRipple type="button" label="Ja, verwijder" class="p-button-rounded p-button-danger"
				(click)="cd.accept()"></button>
	</p-footer>
</p-confirmDialog>

<p-confirmDialog #cd2 [style]="{width: '32rem'}" [baseZIndex]="10000" key="confirm-email-to-recipients">
	<p-footer>
		<button pButton pRipple type="button" label="Annuleren"
				class="p-button-secondary p-button-text p-button-rounded p-mr-2"
				(click)="cd2.reject()"></button>
		<button pButton pRipple type="button" label="Opslaan en versturen" icon="far fa-envelope"
				class="p-button-rounded"
				(click)="cd2.accept()"></button>
	</p-footer>
</p-confirmDialog>

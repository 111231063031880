<ng-container *ngIf="fields !== null">
	<h1>ABC-Incidenten</h1>
	<p-table
		[value]="fields.incident_fields"
		[sortField]="'order'"
		[sortOrder]="1"
		styleClass="p-datatable-dova"
	>
	<ng-template pTemplate="header">
		<tr>
			<th>Naam</th>
			<th>Toegestane header(s)</th>
			<th>Type</th>
			<th>Verplicht</th>
			<th colspan="2">Beschrijving</th>
		</tr>
	</ng-template>
	<ng-template pTemplate="body" let-f>
		<tr>
			<td>
				<span *ngIf="f.ignore"><i class="fa fa-exclamation-triangle"></i></span>
				{{ f.title }}
			</td>
			<td>{{ arrToString(f.kv_headers) }}</td>
			<td>
				{{ f.type }}
			</td>
			<td>{{ f.requirements?.required ? 'Ja' : 'Nee' }}</td>
			<td colspan="2">
				<div class="content" [innerHTML]="asMarkdown(f.instructions.instruction_text)"></div>
			</td>
		</tr>
	</ng-template>
	</p-table>
	<h1>MK-incidenten</h1>
	<p-table
		[value]="fields.mk_incident_fields"
		[sortField]="'order'"
		[sortOrder]="1"
		styleClass="p-datatable-dova"
	>
		<ng-template pTemplate="header">
			<tr>
				<th>Naam</th>
				<th>Toegestane header(s)</th>
				<th>Type</th>
				<th>Verplicht</th>
				<th colspan="2">Beschrijving</th>
			</tr>
		</ng-template>
		<ng-template pTemplate="body" let-f>
			<tr>
				<td>
					<span *ngIf="f.ignore"><i class="fa fa-exclamation-triangle"></i></span>
					{{ f.title }}
				</td>
				<td>{{ arrToString(f.kv_headers) }}</td>
				<td>{{ f.type }}</td>
				<td>{{ f.requirements?.required ? 'Ja' : 'Nee' }}</td>
				<td colspan="2">
					<div class="content" [innerHTML]="asMarkdown(f.instructions.instruction_text)"></div>
				</td>
			</tr>
		</ng-template>
	</p-table>
</ng-container>
import {Injectable} from '@angular/core';
import {BackendFunctions, Sherpa} from "../../classes/Sherpa";
import {sherpa, util} from "../../../services";
import {SherpaError} from "../../classes/SherpaError";
import {
	IncidentsGrid,
	SourceView,
	SourceViewWithMessages,
	SvovdaFields,
	Filter, GroupOption, QueryConfiguration, MultiChartQueryConfiguration, UpdateIncident, EnumTables
} from "../../types/sherpa";
import _ from "lodash";
import {AnalysePanel} from "../../classes/models/AnalysePanel";
import {SessionService} from "../../modules/auth/services/session.service";

@Injectable({
	providedIn: 'root'
})
export class IncidentsService {

	private api: BackendFunctions = {};
	public panelAdditionProperties = ['index', 'visible', 'reload', 'name', 'resultData', 'orgResultData', 'filters'];

	constructor(private session: SessionService) {
		sherpa.api$.subscribe((sherpa: Sherpa) => {
			this.api = sherpa.api;
		});
	}

	getSourceMessages(importsource_id: number): Promise<SourceViewWithMessages> {
		return this.api.getSourceMessages<SourceViewWithMessages>(this.session.token(), importsource_id)
			.catch((e: SherpaError) => util.handleError(e));
	}

	getImports(): Promise<SourceView[]> {
		return sherpa.api$.toPromise().then((sherpa: Sherpa) => {
			return sherpa.api.getImports<SourceView[]>(this.session.token())
				.catch((e: SherpaError) => util.handleError(e));
		});
	}

	commitImport(importsource_id: number, overwrite_non_uniques: boolean): Promise<any> {
		return this.api.commitImport<any>(this.session.token(), importsource_id, overwrite_non_uniques)
			.catch((e: SherpaError) => util.handleError(e));
	}

	cancelImport(importsource_id: number): Promise<any> {
		return this.api.cancelImport<SourceViewWithMessages>(this.session.token(), importsource_id)
			.catch((e: SherpaError) => util.handleError(e));
	}

	getFields(): Promise<SvovdaFields> {
		return sherpa.api$.toPromise().then((sherpa: Sherpa) => {
			return sherpa.api.getFields<SvovdaFields>(this.session.token())
				.catch((e: SherpaError) => util.handleError(e));
		});
	}

	getFilters(panel: AnalysePanel): Promise<Filter[]> {
		let config = _.omit(panel, this.panelAdditionProperties);

		return sherpa.api$.toPromise().then((sherpa: Sherpa) => {
			return sherpa.api.getFilters<Filter[]>(this.session.token(), config)
				.catch((e: SherpaError) => util.handleError(e));
		});
	}

	getGroupOptions(type: 'incident' | 'mk_incident'): Promise<GroupOption[]> {
		return sherpa.api$.toPromise().then((sherpa: Sherpa) => {
			return sherpa.api.getGroupOptions<GroupOption[]>(this.session.token(), type)
				.catch((e: SherpaError) => util.handleError(e));
		});
	}

	getIncidentsGrid(panel: AnalysePanel, grid: number = 10000, opt_extent: any = null): Promise<IncidentsGrid[]> {
		panel = _.cloneDeep(panel);

		let config = _.omit(panel, this.panelAdditionProperties);
		config.includeFields = ["geom", "abc_code"];

		if(opt_extent !== null) {
			panel.extent = opt_extent;
		}

		if(panel.table == 'incident') {
			config.andFilterFields = _.reject(config.andFilterFields, {key: 'mk_code'});
		}

		return this.api.getIncidentsGrid<IncidentsGrid[]>(this.session.token(), grid, config)
			.catch((e: SherpaError) => util.handleError(e));
	}

	getResultForPanel(panel: AnalysePanel): Promise<any> {
		let config = _.omit(panel, this.panelAdditionProperties);

		return this.api.getResultForPanel<any>(this.session.token(), config)
			.catch((e: SherpaError) => util.handleError(e));
	}

	getIncidentsForTable(panel: AnalysePanel) {
		panel = _.cloneDeep(panel);
		const partial = _.omit(panel, this.panelAdditionProperties);

		return this.api.getIncidents<any>(this.session.token(), partial)
			.catch((e: SherpaError) => util.handleError(e));
	}

	getIncidents(panel: AnalysePanel, opt_extent: any = null, checkbox = false): Promise<any> {
		panel = _.cloneDeep(panel);

		let config = _.omit(panel, this.panelAdditionProperties);
		config.includeFields = ["geom", "abc_code"];

		if(opt_extent !== null) {
			config.extent = opt_extent;
		}

		// checkbox = map-toggle-checkbox
		if((panel.table == 'incident' && checkbox) || (panel.table == 'mk_incident' && !checkbox)) {
			config.table = 'mk_incident';
			config.andFilterFields = _.filter(config.andFilterFields, field => {
				const validFilterKeys = ['mk_code', 'date'];
				return _.includes(validFilterKeys, field.key);
			});
			config.includeFields = ["geom", "mk_code"];
			config.groupBy = null;
		}
		else if((panel.table == 'incident' && !checkbox) || (panel.table == 'mk_incident' && checkbox)) {
			config.table = 'incident';
			config.andFilterFields = _.filter(config.andFilterFields, field => {
				return field.key !== 'mk_code';
			});
		}

		return this.api.getIncidents<any>(this.session.token(), config)
			.catch((e: SherpaError) => util.handleError(e));
	}

	deleteIncidents(items: UpdateIncident[]): Promise<void> {
		return this.api.deleteIncidents<void>(this.session.token(), items)
			.catch((e: SherpaError) => util.handleError(e));
	}

	saveFilterSettings(filter : Filter[]): Promise<any> {
		return this.api.saveFilterSettings<any>(this.session.token(), filter)
			.catch((e: SherpaError) => util.handleError(e));
	}

	checkIsVervoerder() {
		return this.api.checkIsVervoerder<boolean>(this.session.token())
			.catch((e: SherpaError) => util.handleError(e));
	}

	checkIsAdmin() {
		return this.api.checkIsAdmin<boolean>(this.session.token())
			.catch((e: SherpaError) => util.handleError(e));
	}

	requestExportCSV(panel: AnalysePanel) {
		panel = _.cloneDeep(panel);
		let config = _.omit(panel, this.panelAdditionProperties);
		config.table = "incident";

		return this.api.exportToCSV<string>(this.session.token(), config)
			.catch((e: SherpaError) => util.handleError(e));
	}

	getColors(key: string): Promise<any[]> {
		return this.api.getColors<any[]>(this.session.token(), key)
			.catch((e: SherpaError) => util.handleError(e));
	}

	getEnumTables(): Promise<EnumTables> {
		return this.api.getEnumTables<EnumTables>(this.session.token())
			.catch((e: SherpaError) => util.handleError(e));
	}

	getImportSummary() {
		return this.api.getImportSummary<any>(this.session.token())
			.catch((e: SherpaError) => util.handleError(e));

	}
}

import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AnalysePanel} from "../../../classes/models/AnalysePanel";
import {Filter, FilterField, StoredConfig} from "../../../types/sherpa";
import {IncidentsService} from "../../../services/data/incidents.service";
import _ from "lodash";
import {ConfigsService} from "../../../services/data/configs.service";
import {util} from "../../../../services";

@Component({
	selector: 'base-my-default-filters',
	templateUrl: './my-default-filters.component.html',
	styleUrls: ['./my-default-filters.component.scss']
})
export class MyDefaultFiltersComponent implements OnInit {

	@Input() defaultFilters: any = null;

	@Output() onClose: EventEmitter<void> = new EventEmitter<void>();
	@Output() onChange: EventEmitter<any> = new EventEmitter<any>();

	dummyPanel: AnalysePanel = new AnalysePanel();
	allFilters$: Promise<Filter[]>;
	filtersMap: Map<string, Filter> = new Map<string, Filter>();

	constructor(private incidentsService: IncidentsService, private configsService: ConfigsService) {
	}

	ngOnInit(): void {
		this.allFilters$ = this.incidentsService.getFilters({} as AnalysePanel);

		this.allFilters$.then(filters => {
			_.forEach(filters, filter => {
				this.filtersMap.set(filter.key, filter);
			});
		});

		this.dummyPanel.andFilterFields = _.cloneDeep(this.defaultFilters.filters);
		this.dummyPanel.visible = true; // use as trigger in this case
	}

	filterChanged($event: FilterField) {
		this.defaultFilters.filters = util.upsert(this.defaultFilters.filters, $event, 'key');
		this.onChange.emit(this.defaultFilters);
	}

	getHeader(field: FilterField) {
		const totalSelected = _.sumBy(this.defaultFilters.filters, (item: FilterField) => {
			if(item.key === field.key) {
				return item.expectedValue.length;
			}
		});

		if(totalSelected === 0) {
			return '';
		}

		return `(${totalSelected} geselecteerd)`;
	}

	close() {
		this.onClose.emit();
	}
}

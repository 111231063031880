import {Component, ElementRef, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {AnalysePanel, PanelType} from "../../../classes/models/AnalysePanel";
import Chart from "chart.js";
import _ from "lodash";
import {isArray} from "rxjs/internal-compatibility";
import {StaticDataService} from "../../../services/data/static-data.service";
import ChartDataLabels from "chartjs-plugin-datalabels";
import {UIChart} from "primeng/chart";
import {BehaviorSubject, Subscription} from "rxjs";
import {UtilService} from "../../../services/util.service";
import {draw, generate} from 'patternomaly';

type ShapeType = 'plus' | 'cross' | 'dash' | 'cross-dash' | 'dot' | 'dot-dash' | 'disc' | 'ring' | 'line' | 'line-vertical' | 'weave' | 'zigzag' | 'zigzag-vertical' | 'diagonal' | 'diagonal-right-left' | 'square' | 'box' | 'triangle' | 'triangle-inverted' | 'diamond' | 'diamond-box';

@Component({
	selector: 'base-card-chart',
	templateUrl: './card-chart.component.html',
	styleUrls: ['./card-chart.component.scss']
})
export class CardChartComponent implements OnInit, OnDestroy {

	@ViewChild('chartContainer') chartContainer: ElementRef;
	@ViewChild('chart') chart: UIChart;
	plugin = ChartDataLabels; // chart plugin for labeling

	@Input() panel: AnalysePanel = null;
	@Input() reloadSubject: BehaviorSubject<AnalysePanel>;
	@Input() presentationMode = false;

	reloadSubscription: Subscription;
	patternType: ShapeType = 'dot'; // mk-incident chart pattern

	constructor(private staticData: StaticDataService, private util: UtilService) {
		Chart.helpers.merge(Chart.defaults.global.plugins.datalabels, {
			display: false
		});
	}

	ngOnInit(): void {
		this.reloadSubscription = this.reloadSubject.subscribe(panelWithUpdates => {
			if(panelWithUpdates == null) {
				return;
			}

			if(panelWithUpdates.index == this.panel.index) {
				this.panel = panelWithUpdates;
				this.setupChart();
			}
		});

		//console.log('setup', this.panel.index);
		this.setupChart();
		// window.addEventListener('beforeprint', () => {
		// });
		// window.addEventListener('afterprint', () => {
		// });
	}

	setupChart() {
		this.setChartOptions();
		this.setChartData();
		this.refreshChart();
	}



	refreshChart() {
		if(this.chart) {
			this.chart.refresh();
		}
	}

	setChartOptions() {
		this.panel.chartOptions = null;
		const type = this.panel.type;
		const stacked = this.panel.stacked;
		const showLegend = this.showLegend();

		// all chart types
		const options = {
			legend: {
				display: showLegend,
				position: 'top',
				align: 'start',
				labels: {
					boxWidth: 14,
					fontSize: 14,
					fontStyle: '300',
					fontFamily: 'MuseoSans',
					fontColor: '#000'
				}
			},
			animation: {
				duration: 0
			},
			scales: {

			}
		} as Chart.ChartOptions;

		if((type === PanelType.radar || type === PanelType.pie) && this.panel.size === 12) {
			// better use of space
			options.legend.position = 'left';
		}

		// custom additions
		if(type === PanelType.bar || type === PanelType.horizontalBar || type === PanelType.line) {
			options.scales = {
				yAxes: [{
					position: 'right',
					ticks: {
						beginAtZero: true,
						padding: 5
					},
					stacked: stacked
				}],
				xAxes: [{
					ticks: {
						beginAtZero: true
					},
					stacked: stacked,
				}]
			};
		}

		this.panel.chartOptions = options;
		// console.log(options);
		// console.log(this.panels[index].resultData);
	}

	setChartData() {
		const isMK = this.panel.table == 'mk_incident';

		switch (this.panel.type) {
			case PanelType.bar:
			case PanelType.horizontalBar:
				this.panel.resultData = this.asBarChartData(this.panel.orgResultData, isMK);
				break;
			case PanelType.line:
				this.panel.resultData = this.asLineChartData(this.panel.orgResultData);
				break;
			case PanelType.pie:
				this.panel.resultData = this.asPieChartData(this.panel.orgResultData, isMK);
				break;
			case PanelType.radar:
				this.panel.resultData = this.asRadarChartData(this.panel.orgResultData, isMK);
				break;
			default:
				this.panel.resultData = _.cloneDeep(this.panel.orgResultData);
		}
	}

	asLineChartData(chartData: any) {
		if (chartData === null) {
			return chartData;
		}

		let clone = _.cloneDeep(chartData);

		_.forEach(clone.datasets, (dataset: Chart.ChartDataSets) => {

			dataset.showLine = true;
			dataset.fill = false;
			if(isArray(dataset.backgroundColor)){
				dataset.backgroundColor = dataset.backgroundColor[0];
			}
			dataset.borderColor = dataset.backgroundColor;

			// hacky
			if(dataset.label === 'Trend') {
				dataset.fill = true;
				dataset.backgroundColor = 'rgba(0,0,0,0)';
			}
		});

		return clone;
	}

	asPieChartData(chartData: any, isMK: boolean) {
		if (chartData === null) {
			return chartData;
		}

		const clone = _.cloneDeep(chartData);

		_.remove(clone.datasets, (dataset: Chart.ChartDataSets) => {
			return dataset.label === 'Trend';
		});

		_.forEach(clone.datasets, (dataset: Chart.ChartDataSets) => {

			if(!isArray(dataset.backgroundColor) || _.uniq(dataset.backgroundColor).length === 1){
				dataset.backgroundColor = this.staticData.getColorPalette();
			}

			if(isMK) {
				dataset.backgroundColor = this.getChartBackgroundCanvasPattern(dataset);
			}
			dataset.borderColor = '#f9f9f9';
			dataset.datalabels = {
				color: '#5a5858',
				display: context => {
					return this.panel.show_labels ? 'auto' : false;
				}
			}
		});

		return clone;
	}

	asRadarChartData(chartData: any, isMK: boolean) {

		if (chartData === null) {
			return chartData;
		}

		let clone = _.cloneDeep(chartData);

		_.remove(clone.datasets, (dataset: Chart.ChartDataSets) => {
			return dataset.label === 'Trend';
		});

		_.forEach(clone.datasets, (dataset: Chart.ChartDataSets) => {
			dataset.showLine = false;
			dataset.fill = false;

			if(isMK) {
				dataset.backgroundColor = this.getChartBackgroundCanvasPattern(dataset);
			}

			if(isArray(dataset.backgroundColor)){
				dataset.borderColor = dataset.backgroundColor[0];
			}

			dataset.datalabels = {
				color: '#9f9f9f',
				display: context => {
					return this.panel.show_labels ? 'auto' : false;
				}
			}
		});

		return clone;
	}

	asBarChartData(chartData: any, isMK: boolean) {

		if (chartData === null) {
			return chartData;
		}

		let clone = _.cloneDeep(chartData);

		_.forEach(clone.datasets, (dataset: Chart.ChartDataSets) => {
			dataset.fill = true;

			if(dataset.label !== 'Trend') {

				if(isMK) {
					dataset.backgroundColor = this.getChartBackgroundCanvasPattern(dataset);
				}
				dataset.showLine = false;
				dataset.borderColor = '#9f9f9f';
				dataset.datalabels = {
					color: '#1d1d1d',
					display: context => {
						const value = context.dataset.data[context.dataIndex];

						if(value === null || value === 0) {
							return false;
						}

						return this.panel.show_labels ? 'auto' : false;
					},
					anchor: 'end',
					align: 'end',
					offset: 0,
					clamp: true
				}
			}
			else {
				dataset.backgroundColor = 'rgba(0,0,0,0)';
			}
		});

		return clone;
	}

	showLegend() {
		// always use legend in pie charts
		if(this.panel.type === PanelType.pie || this.panel.type == PanelType.radar){
			return true;
		}
		else if(this.panel.groupBy?.length > 1){
			return true;
		}
		else if(this.panel.resultData !== null) {
			return _.some(this.panel.resultData.datasets, ['label', 'Trend']);
		}

		return this.panel.show_legend;
	}

	copyRequest() {
		const canvas = this.chartContainer.nativeElement.getElementsByTagName('canvas')[0];
		this.util.copyCanvas(canvas);
	}

	downloadRequest() {
		const canvas = this.chartContainer.nativeElement.getElementsByTagName('canvas')[0];
		const anchor = document.createElement("a");

		anchor.href = canvas.toDataURL("image/png");
		anchor.download = "export.png";
		anchor.click();
	}

	ngOnDestroy() {
		this.reloadSubscription.unsubscribe();
	}

	getChartBackgroundCanvasPattern(dataset: Chart.ChartDataSets) {
		const colorOrColors = dataset.backgroundColor;

		if(isArray(colorOrColors)) {
			return _.map(colorOrColors as string[], hex => {
				return draw(this.patternType, hex, 'rgba(255,255,255,.4)');
			});
		}

		return draw(this.patternType, colorOrColors as string, 'rgba(255,255,255,.4)');
	}
}

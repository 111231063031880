import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from "@angular/router";
import {global} from "../../globals";
import {IncidentsService} from "../services/data/incidents.service";
import {Filter, QueryConfiguration} from "../types/sherpa";
import {AnalysePanel} from "../classes/models/AnalysePanel";

@Injectable({
	providedIn: 'root'
})
export class FiltersResolver implements Resolve<Filter[]> {

	constructor(private incidentsService: IncidentsService) {
	}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<Filter[]> {
		global.enableLoader();
		return this.incidentsService.getFilters({} as AnalysePanel);
	}
}
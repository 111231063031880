import {Filter, GroupOption} from "../../types/sherpa";
import _ from "lodash";
import {Extent} from "ol/extent";
import {PeriodComparison} from "./PeriodComparison";

export class AnalysePanel {
	public table: string = 'incident';
	public caption: string = '';
	public type: PanelType;
	public top: number | null = null;
	public orderByIncidents: boolean = false;
	public sortOrder: boolean = false;
	public andFilterFields: any[] = [];
	public includeFields: any[] = [];
	public groupBy: GroupOption[] = []; // limit 2
	public extent: Extent = null;
	public resultData: any = null;
	public analyse_type: string = null;
	public latest_extent: Extent = null;
	public description: string = null;
	public show_police: boolean;
	public sequence: number;

	// additional properties
	public size: number = 12;
	public name: string;
	public orgResultData?: any; // clone of resultData
	public comparison: PeriodComparison = null;
	public stacked: boolean = false;
	public chartOptions: any = null;
	public reload: boolean = true; // can be used for re-rendering
	public relevantFiltersOnly: boolean = false;
	public filters: Filter[] = [];
	public vertical_size: number = 1;
	public show_labels: boolean = true;
	public show_legend: Boolean;
	public visible = true;
	public index: number;
	public use_short_labels = false;
	month_window_size: number;
	public groupByOptions$: Promise<GroupOption[]>;

	constructor(groupOption = {key: 'date_year_month'} as GroupOption) {
		this.caption = '';
		this.size = null;
		this.groupBy = [groupOption];
		this.name = `Visualisatie`;
		this.stacked = false;
		this.resultData = null;
		this.orgResultData = null;
		this.chartOptions = null;
		this.relevantFiltersOnly = false;
		this.filters = [];
		this.vertical_size = 1;
		this.show_labels = true;
		this.month_window_size = null;
	}
}

export class PanelDisplay {
	constructor(public type: PanelType, public stacked: boolean = false) {
	}
}

export enum PanelType {
	'bar' = 'bar',
	'horizontalBar' = 'horizontalBar',
	'line' = 'line',
	'pie' = 'pie',
	'radar' = 'radar',
	'table' = 'table',
	'map' = 'map'
}
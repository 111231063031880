<form [formGroup]="form" (ngSubmit)="submit();" class="dovaForm">
	<div class="p-fluid">

		<div class="p-field">
			<label for="user" class="p-text-normal">E-mailadres</label>
			<input id="user" pInputText autofocus autocomplete="username" type="email" formControlName="username"
				   class="p-inputtext-lg" [ngClass]="{'p-error': hasError(controls.username)}" />
			<small id="user-help" class="p-invalid" *ngIf="hasError(controls.username, 'required')">
				E-mailadres is verplicht.
			</small>
			<small id="user-help2" class="p-invalid" *ngIf="hasError(controls.username, 'email')">
				Geen geldig e-mailadres.
			</small>
		</div>
		<div class="p-field p-mt-5">
			<label for="pw" class="p-text-normal">Wachtwoord</label>
			<input id="pw" pInputText autocomplete="current-password" type="password" formControlName="password"
				   class="p-inputtext-lg" [ngClass]="{'p-error': hasError(controls.password)}" />
			<small id="pw-help" class="p-invalid" *ngIf="hasError(controls.password, 'required')">
				Wachtwoord is verplicht.
			</small>
			<button pButton type="button" class="p-d-flex p-button-link button-ww" label="Wachtwoord vergeten?" (click)="resetPassword()">
			</button>
		</div>

		<div class="p-field" *ngIf="trustedToken != null">
			<div class="is-trusted">
				<i class="fa fa-shield-check"></i>
				<span class="p-pl-2">Dit is een vertrouwd apparaat</span>
			</div>

			<button pButton type="button" class="p-button-link button-ww"
					label="2FA-code invullen" (click)="trustedToken = null;">
			</button>
		</div>

		<div class="p-field p-mt-5" *ngIf="trustedToken == null">
			<label for="2fa" class="p-text-normal p-d-flex">Twee-Factor (2FA) code: </label>
			<input id="2fa" pInputText formControlName="twofa" class="p-inputtext-lg" />
			<small style="font-size: 12px" class="p-ml-auto">(alleen nodig als u 2fa ingeschakeld heeft, anders kunt u dit veld leeg laten)</small>
		</div>

	</div>

	<div class="p-grid p-formgrid p-mt-5">
		<div class="p-col-12 p-text-right">
			<button pButton pRipple type="submit" label="Inloggen"
					class="p-button-rounded p-text-uppercase"
					[ngClass]="{'is-loading':signing}">
			</button>
		</div>
	</div>

</form>
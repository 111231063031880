import {ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {IncidentsService} from '../../../services/data/incidents.service';
import {Filter, GroupOption, UpdateIncident} from '../../../types/sherpa';
import {Table} from 'primeng/table';
import {BehaviorSubject} from "rxjs";
import {ActivatedRoute} from "@angular/router";
import {AnalysePanel, PanelType} from "../../../classes/models/AnalysePanel";
import {TableColumn} from "../../../classes/primeng/TableColumn";
import _ from "lodash";
import {ConfirmationService} from "primeng/api";
import {SessionService} from "../../../modules/auth/services/session.service";

@Component({
	selector: 'base-incidents-overview',
	templateUrl: './incidents-overview.component.html',
	styleUrls: ['./incidents-overview.component.scss']
})
export class IncidentsOverviewComponent implements OnInit {

	panelSubject: BehaviorSubject<AnalysePanel> = new BehaviorSubject<AnalysePanel>(null);

	@ViewChild('dt') table: Table;
	loading: boolean = true;
	columns: TableColumn[] = [];
	fields: string[] = [];

	filters$: Promise<Filter[]>;
	groupOptions$: Promise<GroupOption[]>;

	panel: AnalysePanel;
	filters: Filter[];
	incidents: any[] = [];

	showSidebar: boolean = false;
	showIncidentSidebar: boolean = false;
	submitted: boolean = false;
	selectedIncident: any = null;
	selectedIncidents: any[] = [];
	public isVervoerder: boolean = null;
	public dataownercode: string;
	public isAdmin: boolean = null;

	constructor(private route: ActivatedRoute,
				private cd: ChangeDetectorRef,
				private incidentsService: IncidentsService,
				private confirmationService: ConfirmationService,
				private session : SessionService) {
		this.columns = [
			{ field: 'incidentnumber', header: 'Nummer' },
			{ field: 'date', header: 'Datum' },
			{ field: 'dataownercode', header: 'Organisatie' },
			{ field: 'importsource', header: 'Bronbestand' },
			{ field: 'concession', header: 'Concessie' },
			{ field: 'quaycode', header: 'Haltecode' }
		];
	}

	ngOnInit(): void {
		this.filters$ = this.incidentsService.getFilters({analyse_type : 'incident_overview'} as AnalysePanel);
		this.groupOptions$ = this.incidentsService.getGroupOptions('incident');

		this.fields = _.map(this.columns, 'field');

		this.filters$.then(result => {
			this.filters = result;
			this.initPanel();
		});

		this.checkIsVervoerder();
	}

	initPanel() {
		this.panel = new AnalysePanel();
		this.panel.type = PanelType.table;
		this.panel.analyse_type = 'overview_table';
		this.panel.includeFields = this.fields;
		this.panel.filters = this.filters;

		this.getIncidents(this.panel);
	}

	openPanelFilter() {
		this.showSidebar = !this.showSidebar;
		this.panelSubject.next(this.panel);
	}


	/* TABLE */

	search(target: any) {
		this.table.filterGlobal(target.value, 'contains');
	}

	searchColumn(target: any, field: string, mode: 'startsWith' | 'contains' | 'endsWith' | 'equals' | 'notEquals' | 'in' | 'lt' | 'lte' | 'gt' | 'gte') {
		this.table.filter(target.value, field, mode);
	}

	onRowSelect(event: any) {
		this.showIncidentSidebar = !this.showIncidentSidebar;
	}

	onRowUnselect(event: any) {
		this.showIncidentSidebar = !this.showIncidentSidebar;
	}

	onRowClick(item: any) {
		this.selectedIncident = null;
		this.cd.detectChanges();
		this.selectedIncident = item;
		this.showIncidentSidebar = !this.showIncidentSidebar;
	}


	/* SIDEBAR CALLBACK */

	panelUpdated(panel: AnalysePanel) {
		this.getIncidents(panel);
		this.panel = panel;
		this.panel.analyse_type = 'overview_table';
	}


	/* FETCH DATA */

	getIncidents(byPanel: AnalysePanel = {} as AnalysePanel) {
		this.loading = true;

		this.incidentsService.getIncidentsForTable(byPanel).then(incidents => {
			this.incidents = incidents;
			this.loading = false;
			this.submitted = true;
		});
	}

	deleteSelected() {
		let bulk: UpdateIncident[] = [];


		_.forEach(this.selectedIncidents, item => {
			let ui = _.pick(item, ['dataownercode', 'incidentnumber']) as UpdateIncident;

			if(this.isVervoerder && ui.dataownercode?.toUpperCase() === this.dataownercode?.toUpperCase()) {
				bulk.push(ui);
			}
			else if(!this.isVervoerder){
				bulk.push(ui);
			}
		});

		this.loading = true;
		this.incidentsService.deleteIncidents(bulk).then(() => {
			this.getIncidents(this.panel);
			this.selectedIncidents = [];
		});
	}

	getSelected(){

		let filtered = _.filter(this.selectedIncidents, (item : any) => {
			if(this.isVervoerder && _.upperCase(this.dataownercode) === _.upperCase(item.dataownercode)) {
				return true;
			}
			else if(!this.isVervoerder){
				return true;
			}
			else {
				return false;
			}
		});

		return filtered;

	}

	checkIsVervoerder() {
		this.loading = false;
		this.incidentsService.checkIsVervoerder()
			.then((result1: boolean) => {
				this.incidentsService.checkIsAdmin()
					.then((result2: boolean) => {
						this.isVervoerder = result1;
						this.dataownercode = this.session.getUserParameterValue('dataownercode');
						this.isAdmin = result2;
					});
			});
	}

	isCorrectVervoerder(dataownercode : string) {
		let currentDataOwner = this.session.getUserParameterValue('dataownercode');
		if(currentDataOwner == null){
			return true;
		}
		if(currentDataOwner != null && _.upperCase(this.dataownercode) === _.upperCase(currentDataOwner)){
			return true;
		}
		return false;
	}


	/* HELPERS */

	canDelete(item : any){
		if(this.isAdmin){
			return true;
		}
		else if(this.isVervoerder && _.upperCase(this.dataownercode) === _.upperCase(item.dataownercode)){
			return true;
		}
		return false;
	}

	confirmDelete() {
		this.confirmationService.confirm({
			message: `Weet u zeker dat u ${this.selectedIncidents.length} incidenten wilt verwijderen?`,
			header: 'Verwijderen bevestigen',
			icon: 'pi pi-info-circle',
			accept: () => {
				this.deleteSelected();
			},
			reject: () => {
			}
		});
	}

	/* unsubscribe when changing pages */
	ngOnDestroy() {
		this.panelSubject.unsubscribe();
	}
}

import {Injectable} from '@angular/core';
import {authApi} from "../guards/auth.guard";
import {SessionService} from "./session.service";
import {UserModel} from "../classes/UserModel";
import {TrustedDevice} from "../classes/TrustedDevice";
import {ProfileComponent} from "../components/profile/profile.component";
import {PasswordResetComponent} from "../components/password-reset/password-reset.component";
import {PasswordComponent} from "../components/password/password.component";
import {DialogService} from "primeng/dynamicdialog";

@Injectable({
	providedIn: 'root'
})
export class UserService {

	constructor(private session: SessionService, private dialogService: DialogService) {
	}

	updateUser(user: Partial<UserModel>): Promise<UserModel> {
		return authApi.updateUser(this.session.token(), user);
	}

	requestPasswordReset(email: string): Promise<void> {
		return authApi.requestPasswordReset(email, this.session.appCode);
	}

	changePassword(new_password: string): Promise<void> {
		return authApi.changePasswordCurrentUser(this.session.token(), new_password);
	}

	getTrustedDevices(): Promise<TrustedDevice[]> {
		return authApi.getTrustedDevices(this.session.token());
	}

	updateTrustedDevice(trustedDevice: Partial<TrustedDevice>): Promise<void> {
		return authApi.updateTrustedDevice(this.session.token(), trustedDevice);
	}

	deleteTrustedDevice(trustedDeviceId: number): Promise<void> {
		return authApi.deleteTrustedDevice(this.session.token(), trustedDeviceId)
			.then(() => {
				// dont just delete for now, we dont know if the trustedDeviceId matches the code in the localstorage
				// this.session.removeTrustedToken();
			});
	}


	/* modals */

	updateProfileModal() {
		const ref = this.dialogService.open(ProfileComponent, {
			width: '36rem',
			modal: true,
			showHeader: true,
			dismissableMask: true,
			closeOnEscape: true,
			closable: true,
			header: 'Profiel aanpassen',
			data: {
				me: this.session.getUser()
			},
			baseZIndex: 1500
		});
	}

	resetPasswordModal() {
		const ref = this.dialogService.open(PasswordResetComponent, {
			width: '36rem',
			modal: true,
			showHeader: true,
			dismissableMask: true,
			closeOnEscape: true,
			closable: true,
			header: 'Wachtwoord vergeten',
			data: {},
			baseZIndex: 1500
		});
	}

	updatePasswordModal() {
		const ref = this.dialogService.open(PasswordComponent, {
			width: '36rem',
			modal: true,
			showHeader: true,
			dismissableMask: true,
			closeOnEscape: true,
			closable: true,
			header: 'Wachtwoord wijzigen',
			data: {},
			baseZIndex: 1500
		});
	}

}

import {Component, OnInit} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {map} from "rxjs/operators";
import {of} from "rxjs";
import {global} from "../../../../../globals";
import {DynamicDialogRef} from "primeng/dynamicdialog";
import {MessageService} from "primeng/api";
import {SessionService} from "../../services/session.service";
import {AppLoader} from "../../../../classes/Globals";
import {util} from "../../../../../services";
import {UserService} from "../../services/user.service";

@Component({
	selector: 'user-password',
	templateUrl: './password.component.html',
	styleUrls: ['./password.component.scss']
})
export class PasswordComponent implements OnInit {

	passwordForm: FormGroup;
	showPassword: boolean = false;

	public get controls() {
		return this.passwordForm.controls;
	}

	constructor(private _fb: FormBuilder, public ref: DynamicDialogRef, public messageService: MessageService, private user: UserService) {
	}

	containError(code: string) {
		return this.controls.errors.getError(code);
	}

	passwordEqualValidator(control: AbstractControl) {
		return of(this.controls.password.value !== control.value)
			.pipe(
				map(result => result ? { equal: true } : null)
			);
	}

	ngOnInit() {
		this.passwordForm = this._fb.group({
			password: ['', [Validators.required, Validators.minLength(8)]],
			password_repeat: ['', Validators.required, this.passwordEqualValidator.bind(this)]
		});
	}

	submit() {
		if(this.passwordForm.invalid) {
			return;
		}

		const new_password = this.controls.password.value;

		global.disableLoader();
		this.user.changePassword(new_password)
			.then(() => {
				util.toast('Nieuw wachtwoord opgeslagen', 'Geslaagd', 'success');
				this.ref.close();
			})
			.finally(() => {
				global.loader = AppLoader.PAGE;
			});
	}

}
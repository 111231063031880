import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {Globals} from "./classes/Globals";
import {global} from 'src/globals';
import {ConfirmationService, MenuItem, MessageService, PrimeNGConfig} from 'primeng/api';
import {util} from "../services";
import _ from "lodash";
import {ConfigsService} from "./services/data/configs.service";
import {StoredConfig, StoredConfigsWrapper} from "./types/sherpa";
import {Subscription} from "rxjs";
import {SessionService} from "./modules/auth/services/session.service";
import {UtilService} from "./services/util.service";

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

	global: Globals = global;
	hideAll: boolean = global.hideAll;
	manualUrl: string = '/rest/manual/';
	subscription: Subscription;
	menuSubscription: Subscription;
	globalSubscription: Subscription;
	defaultMenuItem: MenuItem = {label: 'Nieuwe analyse maken', routerLink: '/analyses/nieuw'};

	items: MenuItem[] = [];
	accountItems: MenuItem[] = [];
	kpiItems: MenuItem[] = [];
	defaultFilters: any = null;
	userPrefs: any = {};
	reloaded: boolean = true;

	showDefaultFiltersDialog: boolean = false;
	showHelpDialog: boolean = false;
	showAgreementDialog: boolean = false;
	showUserPrefsDialog: boolean = false;

	menuFetched: boolean = false;
	authenticated: boolean = false;
	userPrefsFetched: boolean = false;

	constructor(private primengConfig: PrimeNGConfig, private util: UtilService, public messageService: MessageService, public sessionService: SessionService, private confirmationService: ConfirmationService, public configsService: ConfigsService, public cd: ChangeDetectorRef) {
	}

	ngOnInit() {
		this.primengConfig.ripple = true;
		global.menuItems = [this.defaultMenuItem];

		this.globalSubscription = this.util.globalChanged.subscribe(() => {
			this.hideAll = global.hideAll;
			this.cd.detectChanges();
		});

		this.menuSubscription = this.util.menuChanged.subscribe(() => {
			if(!this.authenticated) {
				return;
			}

			this.items[1].items = global.menuItems;

			this.reloaded = false;
			_.delay(() => {
				this.reloaded = true;
			},0)
		});

		this.subscription = this.sessionService.authSubject.subscribe(ok => {

			// no access
			if(!ok) {
				this.authenticated = false;
				this.menuFetched = false;
				this.items = [];
				this.accountItems = [];
				this.kpiItems = [];
				return;
			}

			// authenticated + not a report call
			if(!global.report && !this.userPrefsFetched) {
				this.userPrefsFetched = true;
				this.getDefaultFilters();
				this.getLoggingAgreement();
			}

			// already authenticated, no need to continue
			if(this.authenticated) {
				return;
			}

			this.authenticated = true;

			this.setMenu(); // defaults
			this.getMenu(); // custom
		});
	}

	close() {
		this.messageService.clear('dova-toast');
	}

	getLoggingAgreement(force: boolean = false) {
		this.configsService.getLoggingAgreement().then(ok => {
			if(ok !== null && !force) {
				return;
			}

			let message: string = 'Om deze tool optimaal te kunnen gebruiken is het nodig om bepaalde gegevens voor u op te slaan. Het gaat hierbij om het opslaan van gekozen favoriete filteropties, en voorkeursinstellingen voor KPIs. Analyses worden nooit gelogd, totdat u zelf aangeeft de analyse op te willen slaan. Gaat u hiermee akkoord?';
			let state: string = ok ? 'Akkoord' : 'Niet akkoord';

			this.confirmationService.confirm({
				message: message,
				header: `Toestemming voor gegevensopslag`,
				icon: 'pi pi-exclamation-triangle',
				key: "agreementDialog",
				acceptLabel: 'Akkoord',
				rejectLabel: 'Niet akkoord',
				rejectButtonStyleClass: 'p-button-rounded p-button-danger p-button-text',
				acceptButtonStyleClass: 'p-button-rounded',
				accept: () => {
					this.configsService.setLoggingAgreement(true).then();
				},
				reject: () => {
					this.configsService.setLoggingAgreement(false).then(x => {
						this.showDefaultFiltersDialog = false;
					});
				}
			});
		});
	}

	getDefaultFilters(force: boolean = false) {
		this.configsService.getUserDefaultFilters().then(df => {
			this.defaultFilters = df;

			if(df.disableAtLogin && !force) {
				return;
			}

			this.showDefaultFiltersDialog = true;
		});
	}

	setDefaultFilters() {
		this.configsService.storeUserDefaultFilter(this.defaultFilters)
			.then(() => {
				this.showDefaultFiltersDialog = false;
				util.toast('Uw voorkeuren zijn opgeslagen', null, 'success');
			});
	}

	getUserPrefs() {
		this.configsService.getUserPreferences().then(result => {
			this.userPrefs = result;
			this.showUserPrefsDialog = true;
		});
	}

	setUserPrefs() {
		this.configsService.setUserPreferences(this.userPrefs)
			.then(result => {
				this.userPrefs = result;
				this.showUserPrefsDialog = false;
				util.toast('Uw voorkeuren zijn opgeslagen', null, 'success');
			});
	}

	getMenu() {
		this.menuFetched = false;

		this.configsService.getStoredConfigs().then((wrapper: StoredConfigsWrapper) => {

			_.forEach(wrapper.kpis, (item: StoredConfig) => {
				let menuItem = {
					label: item.label,
					routerLink: '/kpis',
					queryParams: {filter: _.kebabCase(item.label), id: item.stored_config_id}
				} as MenuItem;
				this.kpiItems.push(menuItem);
			});

			const stored_configs_for_menu = _.filter(wrapper.myFilters, 'show_in_menu');

			_.forEach(stored_configs_for_menu, (item: StoredConfig) => {
				const menuItem = {
					label: item.label,
					routerLink: '/analyses',
					queryParams: {filter: _.kebabCase(item.label), id: item.stored_config_id}
				} as MenuItem;
				global.menuItems.push(menuItem);
			});

			this.util.menuChanged.next();
			this.menuFetched = true;
		});
	}

	setMenu(): void {
		this.items = [
			{label: 'KPI\'s', items: this.kpiItems},
			{label: 'Analyses', items: []},
			{label: 'Incidentenoverzicht', routerLink: '/incidenten'},
			{label: 'Importeren', routerLink: '/imports'}
		] as MenuItem[];

		this.accountItems = [{
			label: 'Account',
			items: [{
				label: 'Hulp nodig?',
				icon: 'pi pi-fw pi-info-circle',
				command: event => {
					this.showHelpDialog = true;
				}
			},{
				label: 'Algemene voorkeuren',
				icon: 'pi pi-fw pi-cog',
				command: event => {
					this.getUserPrefs();
				}
			}, {
				label: 'Concessie voorkeuren',
				icon: 'pi pi-fw pi-check-square',
				command: event => {
					this.getDefaultFilters(true);
				}
			}, {
				label: 'Toestemmingen',
				icon: 'pi pi-fw pi-eye',
				command: event => {
					this.getLoggingAgreement(true);
				}
			}, {
				label: 'Accountinstellingen',
				icon: 'pi pi-fw pi-user',
				routerLink: '/auth'
			}, {
				label: 'Uitloggen',
				styleClass: 'last-item',
				icon: 'pi pi-fw pi-power-off',
				command: event => this._logout()
			}]
		}] as MenuItem[];
	}

	private _logout() {
		return this.sessionService.logout()
			.then(() => {
				this.sessionService.clear(false);
				this.sessionService.loginModal('/')
			});
	}

	ngOnDestroy() {
		if(this.subscription) {
			this.subscription.unsubscribe();
		}
		if(this.menuSubscription) {
			this.menuSubscription.unsubscribe();
		}
		if(this.globalSubscription) {
			this.globalSubscription.unsubscribe();
		}
	}
}

<form [formGroup]="formGroup" (ngSubmit)="submit();" class="">

	<ng-container>

		<p class="p-mt-0 p-mb-4">
			Beheer hier uw apparaat.
		</p>

		<div class="p-fluid">

			<div class="p-field">
				<label for="name" class="p-text-normal">Apparaatnaam</label>

				<input id="name" pInputText autofocus formControlName="agent"
					   [ngClass]="{'p-invalid': hasError(controls.agent)}"/>

				<small id="name-help" class="p-invalid" *ngIf="hasError(controls.agent, 'required')">
					Apparaatnaam is verplicht.
				</small>
			</div>

			<div class="p-field p-d-flex p-ai-center" *ngIf="friendlyUserAgent !== null">
				<label class="p-m-0">Suggestie:</label>
				<button pButton pRipple type="button" class="p-d-flex p-button-sm p-button-link"
						style="width: inherit"
						[label]="friendlyUserAgent"
						(click)="controls.agent.setValue(friendlyUserAgent)">
				</button>
			</div>



			<div class="p-d-flex p-ai-center p-mt-5">
				<div class="p-text-light" *ngIf="pending && !done">Bezig met opslaan..</div>
				<div class="p-text-light" *ngIf="pending && done">Opgeslagen!</div>

				<div class="p-ml-auto">
					<button pButton pRipple type="submit"
							label="Opslaan"
							[disabled]="pending"
							class="p-button-primary p-text-uppercase">
					</button>
				</div>
			</div>
		</div>

	</ng-container>

</form>

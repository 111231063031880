<div class="p-d-flex p-flex-column p-ai-stretch" [style.height]="'100%'">
	<div>
		<h1 class="p-m-0 p-pb-1">Data filteren</h1>
		<h3 class="p-m-0 p-pb-3">Bepaal welke filters, sortering en categorieen je wilt gebruiken</h3>
	</div>
	<div class="filter-content">

		<div class="p-field-checkbox p-unselectable-text p-mt-2" *ngIf="panel !== null">
			<p-checkbox [(ngModel)]="panel.relevantFiltersOnly" (onChange)="getFilters()" [binary]="true"
						inputId="dependencyFiltersCB"></p-checkbox>
			<label [for]="'dependencyFiltersCB'">Toon alleen relevante filteropties</label>
			<i class="fa fa-info-circle p-ml-1"
			   pTooltip="Door deze optie aan te vinken zullen de filters zich automatisch aanpassen aan de hand van selecties. Bijvoorbeeld bij het selecteren van een concessie zullen alleen de lijnen zichtbaar zijn in het lijnfilter die aan deze concessie gekoppeld zijn."
			   tooltipPosition="bottom"></i>
		</div>

		<!-- FILTER CHECKBOXES -->
		<div class="p-d-flex p-jc-end" *ngIf="panel?.table == 'incident'">
			<button pButton pRipple (click)="toggleMoreFilters = !toggleMoreFilters"
					class="p-button-link" style="box-shadow: none;"
					[icon]="!toggleMoreFilters ? 'pi pi-angle-down' : 'pi pi-angle-up'"
					[label]="!toggleMoreFilters ? 'Meer filters kiezen' : 'Selecties verbergen'"></button>
		</div>


		<div class="p-grid p-nogutter p-pt-3 p-pb-3" *ngIf="toggleMoreFilters || panel?.table == 'mk_incident'">
			<div class="p-col-4 p-p-1">
				<div class="p-field-checkbox p-m-0">
					<p-checkbox [binary]="true" [(ngModel)]="filterExtentEnabled" [inputId]="'extent'"></p-checkbox>
					<label [for]="'extent'">Gebied</label>
				</div>
			</div>
			<div class="p-col-4 p-p-1" *ngFor="let filter of filters">
				<div class="p-field-checkbox p-m-0">
					<p-checkbox [binary]="true" [(ngModel)]="filter.enabled"
								(onChange)="saveFilterSettings()"
								[inputId]="filter.key"></p-checkbox>
					<label [for]="filter.key">{{filter.title}}</label>
				</div>
			</div>
		</div>

		<!-- MAIN FILTERS -->
		<label class="">Filteren op</label>
		<div class="p-grid p-nogutter p-pt-1 p-pb-3">

			<base-filter-extent
				*ngIf="filterExtentEnabled"
				class="p-col-12"
				[panelObservable]="panelSubject"
				(onFilterFieldChanged)="filterChanged($event)">
			</base-filter-extent>

			<ng-container *ngFor="let filter of filters; let i = index;">
				<ng-container *ngIf="filter.enabled">
					<base-filter-date
						*ngIf="filter.type !== null && filter.type.isMultiSelect && filter.type.customType === 'date'"
						class="p-col-12"
						[panelObservable]="panelSubject"
						[filter]="filter"
						(onFilterFieldChanged)="filterChanged($event)">
					</base-filter-date>

					<base-filter-tree
						*ngIf="filter.type !== null && filter.type.isMultiSelect && filter.type.customType === 'tree'"
						class="p-col-12"
						[panelObservable]="panelSubject"
						[filter]="filter"
						(onFilterFieldChanged)="filterChanged($event)">
					</base-filter-tree>

					<base-filter-single
						*ngIf="filter.type === null"
						class="p-col-12"
						[filterFieldsConfig]="panelSubject"
						[filter]="filter"
						(onFilterFieldChanged)="filterChanged($event)">
					</base-filter-single>

					<base-filter-multiple
						*ngIf="filter.type !== null && filter.type.isMultiSelect && filter.type.customType === null"
						class="p-col-12"
						[panelObservable]="panelSubject"
						[filter]="filter"
						(onFilterFieldChanged)="filterChanged($event)">
					</base-filter-multiple>

				</ng-container>
			</ng-container>
		</div>

		<!-- SORTING FILTERS -->
		<div class="p-grid p-nogutter p-pb-3">
			<base-filter-basic
				class="p-col-7"
				[panelObservable]="panelSubject"
				[values]="sortFilterOptions"
				title="Sorteren op"
				path="orderByIncidents"
				(onFilterFieldChanged)="filterChanged($event)">
			</base-filter-basic>
			<base-filter-basic
				class="p-col-3" style="padding-left: 1px; padding-right: 1px;"
				[panelObservable]="panelSubject"
				[values]="orderFilterOptions"
				title="Volgorde"
				path="sortOrder"
				(onFilterFieldChanged)="filterChanged($event)">
			</base-filter-basic>
			<base-filter-basic
				class="p-col-2"
				[panelObservable]="panelSubject"
				[values]="topFilterOptions"
				title="Top-n"
				path="top"
				(onFilterFieldChanged)="filterChanged($event)">
			</base-filter-basic>
		</div>

		<!-- GROUPING FILTERS-->
		<label class="">Categorieen (data op x-as)</label>
		<div class="p-d-grid p-nogutter p-pt-1 p-pb-4">
			<base-filter-groupby
				*ngIf="groupOptions.length"
				class="p-col-12"
				[panelObservable]="panelSubject"
				[options]="groupOptions"
				(onFilterFieldChanged)="filterChanged($event)">
			</base-filter-groupby>
		</div>

		<!-- Chart display option -->
		<label class="">Weergave</label>
		<div class="p-field-checkbox p-unselectable-text p-mt-2">
			<p-checkbox inputId="displayShortLabels"
				[(ngModel)]="useShortLabels"
				[binary]="true"></p-checkbox>
			<label [for]="'displayShortLabels'">Toon korte labels</label>
			<i class="fa fa-info-circle p-ml-1"
			   pTooltip="Door deze optie aan te vinken zullen korte labels worden getoond indien beschikbaar."
			   tooltipPosition="bottom"></i>
		</div>

		<!-- Period -->
		<label class="">Periode</label>
		<div class="p-field-checkbox p-unselectable-text p-mt-2">
			<p-checkbox inputId="periodWindow"
						[(ngModel)]="monthWindowSizeChecked"
						[disabled]="hasDateFilter || periodComparisonChecked"
						(onChange)="initMonthWindow()"
						[binary]="true"></p-checkbox>
			<label [for]="'periodWindow'">Stel automatische datum in</label>
			<i class="fa fa-info-circle p-ml-1"
			   pTooltip="Door deze optie aan te vinken kan worden ingesteld over hoeveel laatste maanden de incidenten meegenomen moeten worden. Hiermee is het bijvoorbeeld mogelijk om altijd automatisch 'de laatste 2 maanden' te bekijken, zonder steeds het datumfilter te hoeven aanpassen."
			   tooltipPosition="bottom"></i>

			<div class="p-ml-auto p-text-light p-text-italic" *ngIf="hasDateFilter">Zet hiervoor datum-filter uit</div>
		</div>


		<div class="p-pb-3 p-d-flex p-ai-center p-jc-between" *ngIf="monthWindowSizeChecked && !hasDateFilter">
			Aantal maanden vanaf huidige datum:
			<p-inputNumber inputId="periodWindowSize" [(ngModel)]="panel.month_window_size"
						   [showButtons]="true"
						   [min]="1" [max]="48" [suffix]="panel.month_window_size == 1 ? ' maand' : ' maanden'"></p-inputNumber>
		</div>

		<div class="p-field-checkbox p-unselectable-text p-mt-2">
			<p-checkbox inputId="periodCB"
				[(ngModel)]="periodComparisonChecked"
				(onChange)="initComparison(null)"
				[binary]="true"></p-checkbox>
			<label [for]="'periodCB'">Activeer periode vergelijking</label>
			<i class="fa fa-info-circle p-ml-1"
			   pTooltip="Door deze optie aan te vinken zal de data als vergelijking berekend worden."
			   tooltipPosition="bottom"></i>
		</div>

		<div class="p-pb-3" *ngIf="periodComparisonChecked">
			<base-filter-comparison
				[panelObservable]="panelSubject"
				(onComparisonChanged)="comparisonChanged($event)">
			</base-filter-comparison>
		</div>

	</div>

	<!-- FOOTER SUBMIT -->
	<div class="p-d-flex p-ai-center p-pt-3">
		<div class="p-field-checkbox p-unselectable-text p-mr-auto">
			<p-checkbox [(ngModel)]="autoApply" [binary]="true" inputId="instantSubmitCB"></p-checkbox>
			<label [for]="'instantSubmitCB'">Automatisch toepassen</label>
			<i class="fa fa-info-circle p-ml-1" tooltipPosition="bottom" pTooltip="Automatisch toepassen na elke selectiewijziging"></i>
		</div>


		<button type="button" pTooltip="Alle filters terugzetten" tooltipPosition="top" (click)="clearFilters()"
				class="p-button p-button-icon-only p-button-sm p-button-rounded p-button-text p-button-secondary p-mr-2 p-p-0">
			<ibm-icon-renew size="20"></ibm-icon-renew>
		</button>


		<button pButton pRipple class="p-button-rounded" (click)="applyFilters()"
				[ngClass]="{'p-button-outlined p-button-secondary' : autoApply}"
				[label]="autoApply ? 'AUTO-TOEPASSEN AAN' : 'TOEPASSEN'">
			<span *ngIf="autoApply" class="p-button-icon p-button-icon-left pi pi-check-square" aria-hidden="true"></span>
		</button>
	</div>
</div>







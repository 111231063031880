import {Injectable} from '@angular/core';
import TileLayer from "ol/layer/Tile";
import XYZ from "ol/source/XYZ";
import {ATTRIBUTION} from "ol/source/OSM";
import Map from 'ol/Map';
import {TileCacheType} from "../enums/tileCacheType";
import {Coordinate} from "ol/coordinate";
import {Extent} from "ol/extent";
import SimpleGeometry from "ol/geom/SimpleGeometry";
import {MapOptions} from "ol/PluggableMap";
import {FitOptions} from "ol/View";
import {DefaultsOptions as InteractionOptions} from 'ol/interaction';
import proj4 from 'proj4';
import {get, ProjectionLike, transformExtent, transform} from 'ol/proj';
import {register} from 'ol/proj/proj4'
import {Control, DefaultsOptions as ControlOptions} from 'ol/control';
import ZoomToExtent, {Options as ZoomToExtentOptions} from 'ol/control/ZoomToExtent';
import {defaults as controlDefaults} from 'ol/control';
import {defaults as interactionDefaults} from 'ol/interaction';
import {Options as ZoomOptions} from 'ol/control/Zoom';
import {Options as AttrOptions} from 'ol/control/Attribution';
import BaseLayer from "ol/layer/Base";
import _ from 'lodash';
import Style, {StyleFunction, StyleLike} from "ol/style/Style";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import { Options as LayerOptions } from 'ol/layer/BaseVector';
import {Feature, View} from "ol";

@Injectable({
	providedIn: 'root'
})
export class MapService {

	PROJ_4326: ProjectionLike = get('EPSG:4326');
	PROJ_3857: ProjectionLike = get('EPSG:3857');
	PROJ_28992: ProjectionLike = null;

	// The Netherlands => [minx, miny, maxx, maxy]
	the_netherlands_bbox: [number,number,number,number] = [2.862080, 50.719120, 8.064106, 53.696754];
	nl_extent: Extent = transformExtent(this.the_netherlands_bbox, this.PROJ_4326, this.PROJ_3857);

	// default tile endpoint
	tiles_url: string = 'https://tileserver.irias.nl/rest/tiles/';
	tiles_url_2x: string = 'https://tileserver.irias.nl/rest/tiles/';

	constructor() {
		proj4.defs(
			'EPSG:28992',
			'+proj=sterea +lat_0=52.15616055555555 +lon_0=5.38763888888889 +k=0.9999079 +x_0=155000 +y_0=463000 +ellps=bessel +towgs84=565.417,50.3319,465.552,-0.398957,0.343988,-1.8774,4.0725 +units=m +no_defs');
		register(proj4);

		this.PROJ_28992 = get('EPSG:28992');
	}

	createMap(target: string, withTiles: boolean = true, showTiles: boolean = true): Map {
		let map_options = {
			target: target,
			view: new View({
				maxZoom: 17
			}),
			interactions: interactionDefaults(this._getInteractions()),
			controls: controlDefaults(this._getControls(withTiles)),
			layers: this._getLayers(withTiles, showTiles)
		} as MapOptions;

		let map = new Map(map_options);

		// zoom to extent
		let zoom_options = {
			extent: this.nl_extent,
			label: '',
			tipLabel: 'Zoom naar Nederland',
			className: 'ol-zoom-extent mapicon-extent'
		} as ZoomToExtentOptions;

		this.setZoomExtent(map, zoom_options);
		return map;
	}

	private _getControls(showAttribution: boolean): ControlOptions {
		const zoom_options = {
			className: 'ol-zoom mapicon-zoom',
			zoomInLabel: '',
			zoomInTipLabel: 'Inzoomen',
			zoomOutLabel: '',
			zoomOutTipLabel: 'Uitzoomen'
		} as ZoomOptions;

		const attr_options = {
			collapsible: true,
			collapsed: true,
			className: 'ol-attribution mapicon-info',
			label: '',
			tipLabel: 'Attributions',
			collapseLabel: ''
		} as AttrOptions;

		return {
			zoom: true,
			rotate: false,
			zoomOptions: zoom_options,
			attribution: showAttribution,
			attributionOptions: attr_options
		} as ControlOptions;
	}

	private _getInteractions(): InteractionOptions {
		return {
			onFocusOnly: true,
			pinchZoom: true,
			mouseWheelZoom: true,
			doubleClickZoom: true,
			altShiftDragRotate: false
		} as InteractionOptions;
	}

	private _getLayers(tiles: boolean, visible: boolean): BaseLayer[] {
		if(!tiles) {
			return [];
		}

		let tileLayer: TileLayer = this._getTileCacheLayer(TileCacheType.OSM);
		tileLayer.setVisible(visible);
		return [tileLayer];
	}

	private _getTileCacheLayer(type: TileCacheType): TileLayer {
		let url = this.tiles_url + type + '/{z}/{x}/{y}.png';
		let url_2x = this.tiles_url_2x + type + '@2x/{z}/{x}/{y}.png';

		let ratio = 1;
		let ration_2 = 2;

		// disable 2x for now
		url_2x = url;
		ration_2 = ratio;

		const XYZ_source = new XYZ({
			crossOrigin: 'anonymous',
			url: (this.tiles_url_2x && window.devicePixelRatio > 1.5) ? url_2x : url,
			tilePixelRatio: (this.tiles_url_2x && window.devicePixelRatio > 1.5) ? ration_2 : ratio,
			attributions: [
				ATTRIBUTION // https://www.mapbox.com/help/how-attribution-works/#other-mapping-frameworks
			]
		});

		return new TileLayer({source: XYZ_source});
	};

	setZoomExtent(map: Map, options: ZoomToExtentOptions): void {
		map.getControls().forEach((control: Control) => {
			if(control instanceof ZoomToExtent) {
				map.removeControl(control); // needs to be removed first, so the new extent will work
			}
		});

		map.addControl(new ZoomToExtent(options));
		this.fit(map, options.extent);
	};

	fit(map: Map, geometryOrExtent: SimpleGeometry | Extent, opt_options?: FitOptions): void {
		let options: FitOptions = opt_options || {
			padding: [10,10,10,10], // [top,right,bottom,left] in pixels
			minResolution: 16, // Minimum resolution that we zoom to. Default is 0.
		};
		options.size = map.getSize(); // always

		map.getView().fit(geometryOrExtent, options);
	};

	getLayer(styleFn: StyleFunction, zIndex: number): VectorLayer {
		let layer = this.getLayerSimple();
		layer.setZIndex(zIndex);
		layer.setStyle(styleFn);

		return layer;
	};

	getLayerSimple(): VectorLayer {
		let options = {
			updateWhileAnimating: true,
			updateWhileInteracting: true,
			visible: true,
			source: new VectorSource()
		} as LayerOptions;

		return new VectorLayer(options);
	};

	setZoomExtentDefault(map: Map) {
		let options: ZoomToExtentOptions = {
			extent: this.nl_extent,
			label: 'nl',
			tipLabel: 'Zoom naar Nederland',
			className: 'ol-zoom-extent has-auto-width'
		};
		this.setZoomExtent(map, options);
	}

	transformTo4326(c: Coordinate): Coordinate {
		return transform(c, this.PROJ_3857, this.PROJ_4326);
	}

	transformTo3857(c: Coordinate, fromLatLong: boolean = false): Coordinate {
		if(fromLatLong) {
			return transform(c, this.PROJ_4326, this.PROJ_3857);
		}

		return transform([_.toNumber(c[0]), _.toNumber(c[1])], this.PROJ_28992, this.PROJ_3857);
	}

	transformTo28992(c: Coordinate): Coordinate {
		let t = transform(c, this.PROJ_3857, this.PROJ_28992);
		t[0] = Math.round(t[0]);
		t[1] = Math.round(t[1]);
		return t;
	}
}
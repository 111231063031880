import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {AnalysePanel, PanelDisplay, PanelType} from "../../../classes/models/AnalysePanel";
import _ from "lodash";
import {Filter, FilterField, GroupOption} from "../../../types/sherpa";
import {IncidentsService} from "../../../services/data/incidents.service";
import {BehaviorSubject, Subscription} from "rxjs";
import marked from "marked";
import {MenuItem} from "primeng/api";
import {DialogService} from "primeng/dynamicdialog";
import {EditPanelInfoComponent} from "../../modals/edit-panel-info/edit-panel-info.component";

@Component({
	selector: 'base-card',
	templateUrl: './card.component.html',
	styleUrls: ['./card.component.scss']
})
export class CardComponent implements OnInit, OnDestroy {

	@Output() onClose: EventEmitter<AnalysePanel> = new EventEmitter<AnalysePanel>();
	@Output() onPanelChange: EventEmitter<AnalysePanel> = new EventEmitter<AnalysePanel>();
	@Output() onReorder: EventEmitter<{panel: AnalysePanel, toSequence: number}> = new EventEmitter<{panel: AnalysePanel, toSequence: number}>();
	@Output() onPanelDuplicateData: EventEmitter<{panel: AnalysePanel, index: number, callback: Function}> = new EventEmitter<{panel: AnalysePanel, index: number, callback: Function}>();
	@Output() onOpenFilters: EventEmitter<number> = new EventEmitter<number>();

	showWizard = false;
	wizard = 1;
	_p: AnalysePanel = null;
	chartTypes: PanelType[];
	panelDisplayOptions: PanelDisplay[];
	panelDropdownOptions: {index: number, name: string}[];
	reorderItems: MenuItem[] = [];
	showDownloadDialog = false;
	downloadUrl: string = null;
	chartToggleHelpText: string = null;
	autoSizeHelpText: boolean = false
	panelSubscription: Subscription;
	reloadPanelSubject: BehaviorSubject<AnalysePanel> = new BehaviorSubject<AnalysePanel>(null);

	@Input() panelSubject: BehaviorSubject<AnalysePanel>;
	@Input() presentationMode = false;
	@Input() groupByOptions$: Promise<GroupOption[]>;
	@Input() groupByOptionsPolice$: Promise<GroupOption[]>;
	@Input() filtersABC: Filter[];
	@Input() filtersPolice: Filter[];
	@Input() allPanels: AnalysePanel[];
	@Input() set panel(input: AnalysePanel) {
		this.showWizard = input.type == null;
		this.wizard = 1;
		this._p = input;
	};

	get panel() {
		return this._p;
	}

	constructor(private incidentsService: IncidentsService, private dialogService: DialogService) {
		this.chartTypes = [PanelType.bar, PanelType.horizontalBar, PanelType.line, PanelType.pie, PanelType.radar];
		this.panelDisplayOptions = [
			new PanelDisplay(PanelType.bar),
			new PanelDisplay(PanelType.horizontalBar),
			new PanelDisplay(PanelType.bar, true),
			new PanelDisplay(PanelType.horizontalBar, true),
			new PanelDisplay(PanelType.line),
			new PanelDisplay(PanelType.pie),
			new PanelDisplay(PanelType.radar),
			new PanelDisplay(PanelType.table),
			new PanelDisplay(PanelType.map)
		];
		this.panelDropdownOptions = [];
	}

	init() {
		this.panelDropdownOptions = [];
		this.reorderItems = [{
			label: 'Verplaats naar:',
			items: []
		}];

		const simplePanels: {index: number, label: string}[] = [];
		_.forEach(this.allPanels, p => {
			const label = p.caption?.length ? p.caption : 'Visualisatie ' + (p.index+1);
			simplePanels.push({index: p.index, label: label});
		});

		_.forEach(simplePanels, simplePanel => {
			const i = simplePanel.index;
			const label = _.truncate(simplePanel.label, {
				'length': 50,
				'separator': ' '
			});

			if(i != this.panel.index) {
				this.panelDropdownOptions.push({index: i, name: label});
			}
			this.reorderItems[0].items.push({
				label: label,
				disabled: this.panel.index == i,
				command: () => {
					this.onReorder.emit({
						panel: this.panel,
						toSequence: i
					});
					this.panelSubject.next(null);
				}
			});
		});
	}

	ngOnInit(): void {
		this.init();

		if(this.panelSubject) {
			this.panelSubscription = this.panelSubject.subscribe(panelWithUpdatedFilters => {
				if (panelWithUpdatedFilters == null) {
					this.init();
					return;
				}

				if (panelWithUpdatedFilters.index == this.panel.index) {
					this.panel = panelWithUpdatedFilters;
					this.getPanelData();
				}
			});
		}
	}

	setHorizontalSize(size: number) {
		this.panel.size = size;
		this.onPanelChange.emit(this.panel);
	}

	setVerticalSize(vertical_size: number) {
		this.panel.vertical_size = vertical_size;
		this.onPanelChange.emit(this.panel);
		this.reloadPanelSubject.next(this.panel);
	}

	onFilterFieldsChange(andFilterFields: any[]) {
		this.panel.andFilterFields = andFilterFields;
		this.getPanelData();
	}


	/* WIZARD */

	selectPanelDisplay(display: PanelDisplay, emit = false) {
		this.panel.type = display.type
		this.panel.stacked = display.stacked;

		if(emit) {
			this.reloadPanelSubject.next(this.panel);
		}

		this.panelSubject.next(null);
		this.showWizard = false;
	}

	createPanelData(table: 'incident' | 'mk_incident') {
		this.panel.table = table;
		//this.panel.andFilterFields = this.getMostRecentYearFilterField();
		this.panel.andFilterFields = [];
		this.getPanelData();
	}

	duplicatePanelData(duplicateFromPanel: number) {
		this.onPanelDuplicateData.emit({
			panel: this.panel,
			index: duplicateFromPanel,
			callback: (panel: AnalysePanel) => {
				this.panel = panel;
				this.getPanelData();
			}
		});
	}

	getMostRecentYearFilterField(): FilterField[] {
		const dateFilter = _.find(this.filtersABC, ['key', 'date']);
		if(!dateFilter) {
			return [];
		}

		const latestDateFilter = _
			.chain(dateFilter.values)
			.orderBy('label', 'desc')
			.head()
			.value();

		const valuesOnly = _.map(latestDateFilter.value, 'value');

		return [{key: 'date', expectedValue: valuesOnly}] as FilterField[];
	}


	/* API */

	getPanelData() {
		this.incidentsService.getResultForPanel(this.panel).then(result => {
			this.panel.resultData = result;

			const enabled = _.filter(this.panel.filters, 'enabled');
			const enabledKeys = _.map(enabled, 'key');
			const hadFilters = this.panel.filters.length;

			if(this.panel.table == 'mk_incident') {
				this.panel.filters = this.filtersPolice;
			}
			else {
				this.panel.filters = this.filtersABC;
			}

			if(hadFilters) {
				_.forEach(this.panel.filters, f => {
					f.enabled = _.includes(enabledKeys, f.key);
				});
			}

			this.panel.orgResultData = _.cloneDeep(result);
			this.onPanelChange.emit(this.panel); // trigger parent
			this.reloadPanelSubject.next(this.panel); // trigger child
			this.wizard = 2;
		});
	}

	requestExportData() {
		if(this.showDownloadDialog) {
			return;
		}

		this.downloadUrl = null;
		this.incidentsService.requestExportCSV(this.panel).then(url => {
			this.downloadUrl = url;
			this.showDownloadDialog = true;
		});
	}

	presentEditInfo() {
		const ref = this.dialogService.open(EditPanelInfoComponent, {
			width: '36rem',
			modal: true,
			showHeader: true,
			dismissableMask: true,
			closeOnEscape: true,
			closable: true,
			header: 'Visualisatie ' + (this.panel.index + 1),
			data: {
				panel: this.panel
			},
			baseZIndex: 1500,
		});

		ref.onClose.subscribe(d => {
			this.reloadPanelSubject.next(this.panel);
		});
	}


	/* HELPERS */


	setChartToggleHelpText(panel: AnalysePanel, display: PanelDisplay) {
		this.chartToggleHelpText = null;

		if(display.stacked && panel.groupBy.length < 2) {
			this.chartToggleHelpText = 'Let op er staat maar 1 categorie ingesteld.';
		}
	}

	getChartCase(type: PanelType): PanelType {
		if(_.includes(this.chartTypes, type)) {
			return type;
		}

		return null;
	}

	asMarkdown(txt: string) {
		return marked(txt);
	}

	ngOnDestroy() {
		if(this.panelSubscription) {
			this.panelSubscription.unsubscribe();
		}
	}
}

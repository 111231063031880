<div class="p-field-checkbox p-mb-1 p-mt-1 p-unselectable-text p-d-print-none" *ngIf="!presentationMode">
	<p-checkbox inputId="bg"
				[(ngModel)]="showBackground"
				[disabled]="!showValues"
				[binary]="true"></p-checkbox>
	<label [for]="'bg'">Toon achtergrondkleuren</label>
</div>

<div class="p-field-checkbox p-mb-3 p-mt-1 p-unselectable-text p-d-print-none" *ngIf="!presentationMode">
	<p-checkbox inputId="val"
				[(ngModel)]="showValues"
				[disabled]="!showBackground"
				(ngModelChange)="setShowValues.emit(showValues)"
				[binary]="true"></p-checkbox>
	<label [for]="'val'">Toon labels</label>

	<div class="p-ml-auto">
		<i class="fa fa-info-circle" pTooltip="Let op: Tabellen kunnen onduidelijk worden in de afdrukweergave." tooltipPosition="left"></i>
	</div>
</div>

<div id="table-wrapper" style="flex: 1; max-height: 100%;">
	<div *ngIf="exactHeight != null" [style.maxHeight]="presentationMode ? '125mm' : exactHeight" style="height: 100%;">
		<p-table #tt
		styleClass="p-datatable-dova"
		[style]="{width:'1px', minWidth: '100%'}"
		[scrollable]="true"
		[scrollHeight]="presentationMode ? '125mm' : 'flex'"
		[value]="tableData"
		[responsive]="false">

		<ng-template pTemplate="colgroup">
			<colgroup>
				<col style="width:200px">
				<ng-container *ngFor="let x of customColumns">
					<col style="width:100px">
				</ng-container>
			</colgroup>
		</ng-template>

		<ng-template pTemplate="header">
			<tr>
				<th rowspan="2">
					<strong>{{title}}</strong>
				</th>
				<th [colSpan]="customColumns.length">
					Aantal incidenten <span *ngIf="customColumns.length > 1">per <strong>{{colgroup}}</strong></span>
				</th>
			</tr>
			<tr *ngIf="customColumns.length > 1">
				<th *ngFor="let key of customColumns" class="fixed-column">
					<span *ngIf="showValues">{{key}}</span>
				</th>
			</tr>
		</ng-template>
		<ng-template pTemplate="body" let-item>
			<tr class="data-row">
				<td class="p-d-flex p-ai-center" class="">
					<strong>{{item.label}}</strong>
				</td>
				<td class="data-values" *ngFor="let key of customColumns"
					[ngStyle]="getBackgroundStyle(item[key])"
					[tooltipDisabled]="showValues"
					[pTooltip]="key +': '+ item[key]"
					[showDelay]="250"
					tooltipPosition="left"
					tooltipEvent="hover">
					<div *ngIf="showValues">{{item[key]}}</div>
				</td>
			</tr>
		</ng-template>
		<ng-template pTemplate="footer">
			<tr *ngIf="showValues">
				<td class="p-text-right"><strong>Totaal</strong></td>
				<td *ngFor="let key of customColumns">
					{{getTotal(key)}}
				</td>
			</tr>
		</ng-template>
		<ng-template pTemplate="emptymessage">
			<tr>
				<td [attr.colspan]="customColumns.length + 1">
					Geen data gevonden..
				</td>
			</tr>
		</ng-template>
	</p-table>
	</div>
</div>

<div [style.cursor]="'pointer'"
	 (click)="overLay.toggle($event);">
	<div class="fake-dropdown p-d-flex p-ai-center p-unselectable-text">

		<div class="">
			<span class="p-pr-1">{{filter.title}}</span>
			<span *ngIf="fieldLabel !== null" class="p-text-bold">{{fieldLabel}}</span>
		</div>

		<div class="fake-dropdown-icon pi p-ml-auto"
			 [ngClass]="collapsed ? 'pi-chevron-down' : 'pi-chevron-up'"></div>
	</div>
</div>

<p-overlayPanel #overLay appendTo="body" styleClass="fake-dropdown-overlay p-shadow-0 p-mt-0" [style]="{width: '608px'}"
				(onShow)="collapsed = false;"
				(onHide)="collapsed = true;"
				[showCloseIcon]=""
				[dismissable]="true">
	<ng-template pTemplate>
		<ng-container>
			<div class="p-text-italic p-text-light p-pt-3 p-px-3" *ngIf="!relevantFiltersOnly" style="color: #959595;">
				<span>
					Gebruik overige filters om de selectie te verfijnen, zodat een lijst getoond wordt. Hiervoor dient u een vinkje te zetten bij 'Toon alleen relevante filteropties'.
					U kunt ook het zoekveld gebruiken.
				</span>
			</div>
		</ng-container>

		<div *ngIf="noInitValues">
			<div class="p-p-3" style="width: 100%;">
				<span class="p-input-icon-right" style="width: inherit">
					<i class="pi" [ngClass]="loading ? 'pi-spin pi-spinner' : 'pi-search'"></i>
					<input type="text" pInputText
						   [(ngModel)]="searchQry"
						   (input)="backendSearch({filter: searchQry})"
						   style="width: inherit"
						   placeholder="Typ om te zoeken"/>
				</span>
			</div>

			<div class="filter-chips p-chips p-px-3" *ngIf="selectedValues.length" >
				<div class="p-pb-2" style="color: black">Selectie:</div>

				<div class="p-chips-multiple-container p-d-flex p-p-0">
					<div *ngFor="let kv of selectedValues; let i = index;" class="p-chips-token">
						<span class="p-unselectable-text">{{kv.label}}</span>
						<div class="p-chips-token-icon pi pi-times-circle" (click)="removeSelectedValue(kv.value);"></div>
					</div>
				</div>
			</div>

			<div class="p-p-3" *ngIf="searchValues != null">

				<div class="p-py-2" style="color: black">Zoekresultaten:</div>
				<div class="p-d-flex p-flex-column">
					<div *ngFor="let kv of searchValues"
						 (click)="addSelectedValue(kv)"
						 class="p-d-flex p-p-1 p-jc-between filter-search-result-row">
						<div>{{kv.label}}</div>
						<div class="p-hidden">
							<i class="far fa-plus-circle p-pr-2"></i>
							<span>Toevoegen</span>
						</div>
					</div>
					<div *ngIf="searchValues.length == 0"  style="font-style: italic;">
						Deze zoekopdracht komt niet voor onder de incidenten.
					</div>
				</div>

			</div>

		</div>

		<div *ngIf="!noInitValues">
			<p-tree selectionMode="checkbox"
					styleClass=""
					filterPlaceholder="Typ om te zoeken.."
					emptyMessage="Geen opties gevonden.."
					[filter]="true"
					[loading]="loading"
					(onNodeSelect)="done(selectedNodes)"
					(onNodeUnselect)="done(selectedNodes)"
					[value]="treeNodes"
					[(selection)]="selectedNodes">
			</p-tree>

			<div class="p-p-3" *ngIf="loading">Bezig met ophalen..</div>
		</div>


	</ng-template>
</p-overlayPanel>

import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {Message, MessageService, TreeNode} from "primeng/api";
import {SessionService} from "../../../modules/auth/services/session.service";

@Component({
	selector: 'base-dashboard',
	templateUrl: './dashboard.component.html',
	styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

	values: string[];
	treeNodes: TreeNode[] = [];
	selectedNode: TreeNode;
	treeNodes2: TreeNode[] = [];
	selectedNode2: TreeNode;

	data: any;
	dataOptions: any;
	data2: any;
	dataOptions2: any;
	data3: any;
	dataOptions3: any;

	sales: any[];
	totalA1: number;
	totalA2: number;
	totalB1: number;
	totalB2: number;

	constructor(private route: ActivatedRoute, public toast: MessageService, public sessionService: SessionService) {
	}

	ngOnInit() {
		this.values = ['Provincie Utrecht', 'Tram en Bus Regio Utrecht'];
		this.initTree();
		this.initTree2();
		this.initChart();
		this.initChart2();
		this.initChart3();


		this.sales = [
			{date: 'januari `18', a1: 22, a2: 6, b1: 11, b2: 16},
			{date: 'februari `18', a1: 11, a2: 8, b1: 8, b2: 9},
			{date: 'maart `18', a1: 7, a2: 6, b1: 5, b2: 5},
			{date: 'april `18', a1: 9, a2: 2, b1: 3, b2: 4},
			{date: 'mei `18', a1: 12, a2: 6, b1: 9, b2: 8},
			{date: '...', a1: 0, a2: 0, b1: 0, b2: 0},
			{date: 'juli `19', a1: 3, a2: 7, b1: 9, b2: 9},
		];

		this.calcA();
		this.calcB();
	}

	resetFilter() {
		const message = {
			severity: 'success',
			summary: 'Opgeslagen',
			detail: 'Het standaard filter is succesvol ingesteld.'
		} as Message;

		this.toast.add(message);
	}

	saveFilter() {
		const message = {
			severity: 'success',
			summary: 'Opgeslagen',
			detail: 'Het filter is succesvol ingesteld.'
		} as Message;

		this.toast.add(message);
	}

	initChart() {
		this.data = {
			labels: ['A7', 'B1b', 'B2', 'B3', 'B5', 'B6', 'C1', 'C2'],
			datasets: [
				{
					label: 'OV Incidenten per categorie',
					backgroundColor: '#3272D9',
					borderColor: '#114599',
					data: [10, 11, 6, 12, 21, 9, 43, 5]
				}
			]
		};

		this.dataOptions = {
			scales: {
				yAxes: [{
					ticks: {
						beginAtZero: true
					}
				}]
			}
		};
	}

	initChart2() {
		this.data2 = {
			labels: ['A7', 'B1b', 'B2', 'B3', 'B5', 'B6', 'C1', 'C2'],
			datasets: [
				{
					type: 'line',
					label: 'Gemiddeld Nederland',
					backgroundColor: '#0A2B29',
					borderColor: '#0A2B29',
					fill: false,
					data: [10, 12, 10, 14, 14, 14, 34, 8]
				},
				{
					label: 'OV Incidenten Midden Overijssel',
					backgroundColor: '#3272D9',
					borderColor: '#114599',
					data: [10, 11, 6, 12, 21, 9, 43, 5]
				},
				{
					label: 'OV Incidenten Ijsselmond',
					backgroundColor: '#E89C3F',
					borderColor: '#AD5F00',
					data: [8, 16, 7, 17, 18, 9, 51, 6]
				}
			]
		};

		this.dataOptions2 = {
			scales: {
				yAxes: [{
					ticks: {
						beginAtZero: true
					}
				}]
			}
		};
	}

	initChart3() {
		this.data3 = {
			datasets: [{
				data: [
					11,
					16,
					7,
					3,
					14,
					21
				],
				backgroundColor: [
					"#21C2B7",
					"#84B1FA",
					"#C79BF2",
					"#F78BB8",
					"#E89C3F",
					"#FA8E8E"
				],
				label: 'Categorie A'
			}],
			labels: [
				"Midden Overijssel",
				"Noord- en Zuidwest-Friesland",
				"Limburg",
				"Achterhoek Rivierenland",
				"Groningen-Drenthe",
				"Amsterdam Meerlanden"
			]
		}

		this.dataOptions3 = {
			legend: {
				position: 'left',
				align: 'start'
			}
		};
	}

	initTree() {

		let nodes = [{
			selectable: true,
			label: 'Concessie Arnhem Nijmegen',
			children: [
				{selectable: true, label: '83. Nijmegen CS - Gennep - Venlo Station'},
				{selectable: true, label: '57. Kranenburg - Nijmegen CS'},
				{selectable: true, label: '83. Giesbeek - Doetinchem'},
				{selectable: true, label: '566. Spijk - Zevenaar Station'},
				{selectable: true, label: '852. Arnhem - Wageningen Station'},
				{selectable: true, label: '563. Wijchen Mozaiek - Batenburg RK Kerk'},
				{selectable: true, label: '555. Aerdt - Zevenaar - Groot Holthuizen'},
			]
		}, {
			selectable: true,
			label: 'Oost-Brabant',
			children: [{selectable: true, label: 'todo'}]
		}, {
			selectable: false,
			label: 'Rail Haaglanden',
			children: []
		}, {
			selectable: true,
			label: 'Noord- en Zuidwest Fryslân en Schiermonnikoog',
			children: [{selectable: true, label: 'todo'}]
		}] as TreeNode[];


		this.treeNodes = nodes;
	}

	initTree2() {

		let nodes = [{
			selectable: true,
			label: 'Categorie A',
			children: [
				{selectable: true, label: 'A1'},
				{selectable: true, label: 'A1a'},
				{selectable: true, label: 'A1b'},
				{selectable: true, label: 'A2'},
				{selectable: true, label: 'A2a'},
				{selectable: true, label: 'A2b'},
				{selectable: true, label: 'A3'},
				{selectable: true, label: 'A3a'},
				{selectable: true, label: 'A3b'},
				{selectable: true, label: 'A4'},
				{selectable: true, label: '..'},
			]
		}, {
			selectable: true,
			label: 'Categorie B',
			children: [{selectable: true, label: 'todo'}]
		}, {
			selectable: false,
			label: 'Categorie C',
			children: [{selectable: true, label: 'todo'}]
		}
		] as TreeNode[];


		this.treeNodes2 = nodes;
	}


	calcA() {
		let total = 0;
		for(let sale of this.sales) {
			total += sale.a1;
		}

		this.totalA1 = total;

		total = 0;
		for(let sale of this.sales) {
			total += sale.a2;
		}

		this.totalA2 = total;
	}

	calcB() {
		let total = 0;
		for(let sale of this.sales) {
			total += sale.b1;
		}

		this.totalB1 = total;

		total = 0;
		for(let sale of this.sales) {
			total += sale.b2;
		}

		this.totalB2 = total;
	}
}

<div class="p-grid p-m-1">
	<div class="p-col">

		<p-card styleClass="analyse-card">
			<h1>Incidentenoverzicht</h1>
			<p class="p-m-0">Alle geimporteerde incidenten die gebruikt voor analyses.</p>

			<p-table #dt
					 styleClass="p-datatable-dova p-pb-4"
					 dataKey="incident_id"
					 selectionMode="multiple"
					 (onRowSelect)="onRowSelect($event)"
					 (onRowUnselect)="onRowUnselect($event)"
					 [(selection)]="selectedIncidents"
					 [value]="incidents"
					 [columns]="columns"
					 [loading]="loading"
					 [globalFilterFields]="fields"
					 [rows]="10"
					 [rowsPerPageOptions]="[10,25,50,100,250]"
					 [responsive]="true"
					 [autoLayout]="true"
					 [paginator]="true"
					 [showPageLinks]="false"
					 [paginatorDropdownAppendTo]="'body'"
					 [alwaysShowPaginator]="false"
					 [showCurrentPageReport]="false"
					 [sortField]="fields[0]"
					 [sortOrder]="-1">

				<ng-template pTemplate="caption">
					<div class="table-header p-pb-2">

						<div class="p-d-flex">
							<p-inplace styleClass="p-mr-2">
								<ng-template pTemplate="display">
									<button type="button"
											class="p-button p-button-rounded p-button-text p-button-secondary">
										<ibm-icon-search size="20"></ibm-icon-search>
										<span class="p-button-label p-ml-2">Zoeken</span>
									</button>
								</ng-template>
								<ng-template pTemplate="content">
									<input pInputText type="text" autofocus (input)="search($event.target);" placeholder="Zoeken op.."/>
								</ng-template>
							</p-inplace>

							<button type="button" (click)="openPanelFilter();"
									class="p-button p-button-rounded p-button-text p-button-secondary">
								<ibm-icon-settings-adjust size="20"></ibm-icon-settings-adjust>
								<span class="p-button-label p-ml-2">Filteren</span>
							</button>
						</div>

					</div>
				</ng-template>
				<ng-template pTemplate="header" let-columns>
					<tr>
						<th style="width: 3rem">
							<ibm-icon-trash-can size='20'></ibm-icon-trash-can>
						</th>
						<ng-container *ngFor="let col of columns">
							<th [pSortableColumn]="col.field">{{col.header}}
								<p-sortIcon [field]="col.field"></p-sortIcon>
							</th>
						</ng-container>
					</tr>
					<tr>
						<th>
							<p-tableHeaderCheckbox></p-tableHeaderCheckbox>
						</th>
						<ng-container *ngFor="let col of columns">
							<th>
								<input pInputText type="text"
									   (input)="searchColumn($event.target, col.field, 'startsWith')"
									   [placeholder]="'Zoeken op ' + col.header" class="p-column-filter p-inputtext-sm">
							</th>
						</ng-container>
					</tr>
				</ng-template>
				<ng-template pTemplate="body" let-item let-columns="columns">
					<tr class="p-selectable-row" (click)="onRowClick(item)">
						<td style="line-height: normal">
							<span *ngIf="!canDelete(item)">
								<ibm-icon-error size="20"></ibm-icon-error>
							</span>
							<p-tableCheckbox *ngIf="canDelete(item)" [value]="item" ></p-tableCheckbox>
						</td>
						<ng-container *ngFor="let col of columns">
							<td>
								<span class="p-column-title">{{col.header}}</span>
								{{item[col.field]}}
								<span *ngIf="item[col.field] === null"
									class="p-text-italic p-text-uppercase">-</span>
							</td>
						</ng-container>
					</tr>
				</ng-template>
				<ng-template pTemplate="summary">
					<div class="p-d-flex p-ai-center p-jc-between" *ngIf="getSelected().length > 0">
						<div *ngIf="isVervoerder === true">
							Let op: u kunt alleen incidenten verwijderen van organisatie {{ dataownercode }}.
						</div>
						<button pButton pRipple
								class="p-button-outlined p-button-danger"
								(click)="confirmDelete()"
								[label]="'Verwijder incidenten (' +getSelected().length+ ')'"
								icon="pi pi-trash"></button>
					</div>
				</ng-template>

				<ng-template pTemplate="paginatorleft">
				</ng-template>

				<ng-template pTemplate="paginatorright">
				</ng-template>

				<ng-template pTemplate="emptymessage" let-columns>
					<tr>
						<td [attr.colspan]="columns.length + 1">Geen incidenten gevonden.</td>
					</tr>
				</ng-template>
			</p-table>

		</p-card>

	</div>
</div>

<p-sidebar position="right"
		   [style]="{width:'40rem'}"
		   [showCloseIcon]="true"
		   [(visible)]="showSidebar"
		   [blockScroll]="true">
	<base-filters-sidebar
		(onPanelChange)="panelUpdated($event)"
		[groupOptions$]="groupOptions$"
		[panelSubject]="panelSubject">
	</base-filters-sidebar>
</p-sidebar>

<p-sidebar position="left"
		   [style]="{width:'25rem'}"
		   [showCloseIcon]="true"
		   [(visible)]="showIncidentSidebar"
		   [blockScroll]="true">
	<base-incident-detail *ngIf="selectedIncident !== null"
		[inputPanel]="panel"
		[incident_id]="selectedIncident.incident_id">
	</base-incident-detail>
</p-sidebar>

<p-confirmDialog #cd [style]="{width: '30rem'}" [baseZIndex]="10000">
	<p-footer>
		<button pButton pRipple type="button" label="Nee" class="p-button-secondary p-button-text p-button-rounded p-mr-2"
				(click)="cd.reject()"></button>
		<button pButton pRipple type="button" label="Ja, verwijder" class="p-button-rounded p-text-uppercase"
				(click)="cd.accept()"></button>
	</p-footer>
</p-confirmDialog>
<div *ngIf="panel.reload; else reloading" class="p-d-flex p-flex-column card-inner-body">

	<div class="table-container p-d-flex p-flex-column" *ngIf="panel.resultData.labels?.length">
		<base-incident-table
			[presentationMode]="presentationMode"
			[panel]="panel"
			[groupOptions$]="groupByOptions$"
			(setShowValues)="panel.show_labels = $event;">
		</base-incident-table>
	</div>

</div>

<ng-template #reloading>
	reloading..
</ng-template>

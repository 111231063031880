<p-toast [baseZIndex]="12000"
	[preventOpenDuplicates]="true"></p-toast>

<div class="p-grid p-m-1">
	<div class="p-col-12">
		<p-card header="Instellingen" subheader="Uw account" styleClass="default-box p-p-2">
			<p></p>

			<div class="p-d-flex p-ai-center">
				<div class="">
					<div class="setting-title">Gebruikernaam</div>
					<div class="setting-subtitle">Uw gebruikersnaam waarmee u kunt inloggen.</div>
				</div>
				<div class="p-ml-auto">
					<div>{{getUser()?.username}}</div>
				</div>
			</div>

			<div class="divider"></div>

			<div class="p-d-flex">
				<div class="">
					<div class="setting-title">Naam</div>
					<div class="setting-subtitle">De naam die wordt weergegeven bij uw account.</div>
				</div>
				<div class="p-ml-auto p-text-right">
					<div class="setting-title">{{getUser()?.name}}</div>
					<button pButton class="p-button-primary p-button-link p-text-italic p-button-sm p-button-text"
							label="(Wijzigen)" (click)="userChangeModal()"></button>
				</div>
			</div>

			<div class="divider"></div>

			<div class="p-d-flex p-ai-center">
				<div class="">
					<div class="setting-title">Wachtwoord wijzigen</div>
					<div class="setting-subtitle">Verander het wachtwoord van uw account.</div>
				</div>
				<div class="p-ml-auto">
					<button pButton pRipple
							class="p-button-rounded p-button-outlined"
							label="Wachtwoord wijzigen"
							(click)="passwordChangeModal()"></button>
				</div>
			</div>

			<div class="divider"></div>

			<div class="p-d-flex p-ai-center">
				<div class="">
					<div class="setting-title">Twee-Factor Authenticatie (2FA)</div>
					<div class="setting-subtitle">Verbeter de beveiliging van uw account.</div>
				</div>
				<div class="p-ml-auto">
					<button pButton pRipple
							[ngClass]="has2fa ? 'p-button-warning' : 'p-button-primary'"
							[label]="has2fa ? 'Uitschakelen' : 'Inschakelen'"
							(click)="twoFactorAuthModal()"
							class="p-button-rounded"></button>
				</div>
			</div>

			<div class="divider"></div>

			<div class="p-d-flex p-ai-center">
				<div class="">
					<div class="setting-title">Account verwijderen</div>
					<div class="setting-subtitle">Uw account permanent verwijderen.</div>
				</div>
				<div class="p-ml-auto">
					<button pButton pRipple
							label="Account verwijderen"
							(click)="removeAccountConfirm()"
							class="p-button-danger p-button-outlined p-button-rounded"></button>
				</div>
			</div>

		</p-card>
	</div>

	<div class="p-col-12">
		<p-card header="Divisies" subheader="Uw bevoegdheid" styleClass="default-box p-p-2">
			<p></p>

			<ng-container *ngFor="let c of getUser()?.clearance">

				<div class="p-d-flex p-ai-center">
					<div class="">{{c.division_name}}</div>
					<div *ngIf="c.division_code == 'root' && getUser()?.is_root" class="p-pl-1 root-label">r00t</div>
					<div class="p-ml-auto">{{c.role_code}}</div>
				</div>

				<div class="divider"></div>

			</ng-container>
		</p-card>
	</div>

	<div class="p-col-12">
		<p-card header="Apparaten" subheader="Door u vertrouwde apparaten" styleClass="default-box p-p-2">
			<p *ngIf="trustedDevices.length == 0">
				Er zijn geen vertrouwde apparaten gevonden voor uw account.
			</p>

			<ng-container *ngFor="let item of trustedDevices">

				<div class="p-d-flex p-ai-center">
					<div class="">{{item.timestamp | date: "dd MMM yyyy H:mm":'UTC'}}</div>
					<div class="p-pl-4" style="flex: 1;">{{item.agent}}</div>
					<div class="p-ml-auto">
						<button pButton pRipple icon="fa fa-pencil-alt"
								(click)="updateTrustedDeviceModal(item)"
								class="p-button-icon-only p-button-secondary p-button-outlined p-button-rounded"></button>
						<button pButton pRipple icon="fa fa-trash-alt"
								(click)="removeTrustedDevice(item.trusted_device_id)"
								class="p-ml-2 p-button-icon-only p-button-danger p-button-outlined p-button-rounded"></button>
					</div>
				</div>

				<div class="divider"></div>

			</ng-container>
		</p-card>
	</div>
</div>

<p-confirmDialog [style]="{width: '36rem'}" [baseZIndex]="10000"></p-confirmDialog>
import {AppLoader, Globals} from "./app/classes/Globals";

export let global: Globals = {
	loader: AppLoader.PAGE,
	loginUser: null,
	loginModal: false,
	requestedUrl: '',
	menu: true,
	hideAll: false,
	report: false,
	menuItems: [],
	disableLoader: () => {
		global.loader = AppLoader.NONE;
	},
	enableLoader: () => {
		global.loader = AppLoader.PAGE;
	}
};


<!-- DASHBOARD ANALYSE MODE -->
<div *ngIf="!presentationMode" class="p-grid p-nogutter p-flex-column p-d-print-none" style="height: 100%;">
	<div class="p-col-fixed">
		<p-toolbar styleClass="dashboard-toolbar">
			<div class="p-toolbar-group-left">
				<button pButton pRipple type="button" (click)="myFiltersPanel.toggle($event);"
						class="p-button-rounded p-button-info p-mr-2"
						icon="pi pi-filter"
						label="Mijn analyses"></button>

				<button pButton pRipple (click)="selectedStoredConfig = null; save_menu.toggle($event);"
						pTooltip="Opslaan" tooltipPosition="bottom"
						class="p-button-rounded p-button-info p-mr-2"
						icon="fal fa-lg fa-save"></button>
				<p-menu #save_menu id="save_menu" [model]="saveMenu" [popup]="true" appendTo="body"></p-menu>


				<button pButton pRipple (click)="openPresentation()"
						pTooltip="Afdrukweergave" tooltipPosition="bottom"
						class="p-button-rounded p-button-info p-mr-2"
						icon="fal fa-lg fa-print"></button>

				<button pButton pRipple (click)="copyUrl()" *ngIf="storedConfig != null && storedConfig.sharing_code != null"
						pTooltip="URL delen" tooltipPosition="bottom"
						class="p-button-rounded p-button-info"
						icon="fal fa-lg fa-share-alt"></button>

			</div>

			<div class="p-toolbar-group-right">
				<button pButton pRipple (click)="createPanel(false)"
						icon="pi pi-plus"
						label="Nieuwe visualisatie toevoegen">
				</button>
			</div>
		</p-toolbar>
	</div>
	<div class="p-col" style="overflow-y: scroll">
		<ng-container [ngTemplateOutlet]="analyseMode"></ng-container>
	</div>
</div>
<ng-template #analyseMode>

	<!-- ANALYSE TITLE / DESCRIPTION -->
	<div class="panels-header" *ngIf="mode === 'analyses'">
		<div class="p-d-flex">
			<h1 class="p-my-0 p-mr-2" *ngIf="storedConfig?.title?.length">{{storedConfig.title}}</h1>
			<h1 class="p-my-0 p-mr-2 p-text-italic" *ngIf="!storedConfig?.title?.length">Titel</h1>
			<div class="p-ml-auto">
				<button pTooltip="Bewerk" tooltipPosition="left"
						(click)="openSaveFilterDialog(storedConfig, false, (storedConfig == null));"
						class="p-button p-button-rounded p-button-info p-button-icon-only">
					<ibm-icon-edit size="20"></ibm-icon-edit>
				</button>
			</div>
		</div>
		<div class="content"
			 *ngIf="storedConfig?.description?.length"
			 [innerHTML]="asMarkdown(storedConfig.description)">
		</div>
	</div>

	<!-- THE PANELS -->
	<div id="grid-container" class="p-grid p-m-2" style="height: calc(100% - 1rem);">

		<div *ngFor="let panel of panels;" class="grid-panel"
			 [ngStyle]="panel.vertical_size === 1 ? {'height':'75%'} : {'height':'100%'}"
			 [ngClass]="panel.size === null ? 'p-col' : 'p-col-' + panel.size">
			<base-card [panel]="panel"
					   [panelSubject]="filterSubject"
					   [filtersABC]="filtersABC"
					   [filtersPolice]="filtersPolice"
					   [allPanels]="panels"
					   [groupByOptions$]="groupByOptions$"
					   [groupByOptionsPolice$]="groupByOptionsPolice$"
					   (onOpenFilters)="openPanelFilter($event)"
					   (onReorder)="movePanel($event)"
					   (onPanelDuplicateData)="panelDuplicateData($event)"
					   (onPanelChange)="panelUpdated($event)"
					   (onClose)="deselectPanel($event)">
			</base-card>
		</div>

		<!-- EMPTY MESSAGE -->
		<div class="p-col-12" *ngIf="panels.length === 0">
			<p-messages severity="info">
				<ng-template pTemplate>
					<div class="p-pr-2">
						<span class="fal fa-info-circle"></span>
					</div>
					<div>Deze analyse bevat nog geen visualisaties. Klik op "Nieuwe visualisatie toevoegen" om te beginnen.</div>
				</ng-template>
			</p-messages>
		</div>

	</div>

</ng-template>


<!-- PRESENTATION MODE -->
<ng-container [ngTemplateOutlet]="printMode" *ngIf="presentationMode"></ng-container>
<ng-template #printMode>

	<!-- TOOLBAR PRINT -->
	<div class="presentation-toolbar p-d-flex p-p-3 p-d-print-none">

		<button pButton pRipple type="button" (click)="print()"
				class="p-button-rounded p-button-outlined p-button-secondary"
				label="Afdrukken"></button>

		<button pButton pRipple type="button" (click)="downloadFile()"
				class="p-button-rounded p-button-outlined p-button-secondary p-ml-2"
				[disabled]="pdfGenerating"
				[label]="pdfGenerating ? 'Pdf genereren..' : 'Download pdf'"></button>

		<p-progressSpinner
			*ngIf="pdfGenerating"
			[style]="{width: '1.5rem', height: '1.5rem'}"
			styleClass="p-ml-3"
			class="p-d-flex p-as-center"
			strokeWidth="6"
			fill="#EEEEEE"
			animationDuration=".5s"></p-progressSpinner>

		<button type="button" pButton pRipple (click)="closePresentation();"
				icon="pi pi-times"
				class="p-ml-auto p-button-rounded p-button-text p-button-secondary"></button>
	</div>

	<div class="p-d-print-none" [style]="{'margin-top': '100px'}"></div>

	<div class="p-d-flex p-ai-center p-flex-column">

		<ng-container *ngIf="showFrontpage && storedConfig">
			<ng-template #defaultTitle>
				<span>Analyse ABC-incidenten</span>
			</ng-template>

			<div class="page4 p-shadow-2 p-p-5 p-d-flex p-flex-column">
				<div class="body">
					<img src="assets/img/voorblad_dova.jpg" style="filter: blur(5px);"/>

					<div class="title-box custom p-d-flex p-flex-column p-p-4">
						<h1>
							<span *ngIf="storedConfig.title != null && storedConfig.title?.length else defaultTitle;">
								{{storedConfig.title}}
							</span>
						</h1>
						<div class="date p-mt-6">{{ getNow() | date : 'dd-MM-yyyy' }}</div>
						<div class="date p-mt-1 p-text-italic">Door: {{ getUserInfoString() }}</div>
					</div>
				</div>
			</div>
			<div class="page4 p-shadow-2 p-p-5 p-d-flex p-flex-column">
				<div class="body">
					<div class="colophon p-mt-auto p-d-flex p-flex-row p-p-4">
						<div class="p-mr-6 colophon-text">Colofon</div>
						<div class="p-d-flex p-flex-column">
							<div class="p-pb-2">
								<span *ngIf="storedConfig.title != null && storedConfig.title?.length else defaultTitle;">
									{{storedConfig.title}}
								</span>
								<span>, {{ getNow() | date : 'dd-MM-yyyy' }}</span>
							</div>
							<div class="p-pb-2">Opgesteld door {{getUserInfoString(true)}}</div>
							<div class="p-pb-2">Met behulp van SVOVda</div>
							<div class="p-pb-2">Samenwerkingsverband DOVA<br/>Leidseveer 2-10 3511 SB Utrecht<br/>ovdata@dova.nu</div>
							<div class="p-pb-4">Foto voorblad (bewerkt): Bas Bogers</div>
							<div>Disclaimer: dit is een door een gebruiker gegenereerde analyse op basis van door derden geïmporteerde data. Samenwerkingsverband DOVA kan daarom geen garanties geven over de juistheid van de inhoud.</div>
						</div>
					</div>
				</div>
			</div>
		</ng-container>

		<ng-container *ngIf="showReportPages">
			<div class="page4 p-shadow-2 p-p-5 p-mb-3 p-d-flex p-flex-column">
				<div class="head p-mb-5">
					<img src="assets/img/rapport_logo_dova.jpg"/>
				</div>
				<div class="body">
					<img src="assets/img/rapport_voorblad_dova.jpg"/>

					<div class="title-box p-d-flex p-flex-column p-p-4">
						<h1>Maandelijkse rapportage sociale veiligheid in het openbaar vervoer</h1>
						<div class="date p-mt-auto">{{ periodDescription }}</div>
					</div>
				</div>
				<div class="footer" *ngIf="false"></div>
			</div>

			<div class="page4 p-shadow-2 p-p-5 p-mb-3 p-d-flex p-flex-column">
				<div class="head p-mb-5">
					<img src="assets/img/rapport_logo_dova.jpg"/>
				</div>
				<div class="body">
					<div class="info-box p-mt-auto p-p-4">
						<p class="p-m-0">Maandelijkse rapportage sociale veiligheid in het openbaar
							vervoer {{ periodDescription !== null ? periodDescription : '' }}</p>

						<p class="p-m-0">Aangeboden op {{ getDate() | date : 'dd MMMM yyyy':'CEST':'nl'  }}</p>
						<p>Aan {{ getUserInfoString() }}</p>

						<p>
							<span>Bron:</span>
							<a href="https://socialeveiligheid.ov-data.nl/">https://socialeveiligheid.ov-data.nl/</a>
						</p>

						<ul>
							<li>Samenwerkingsverband DOVA</li>
							<li>Leidseveer 2-10</li>
							<li>35611 SB Utrecht</li>
							<li>ovdata@dova.nu</li>
							<li>(085) 066 12 91</li>
						</ul>

						<p>
							Disclaimer: dit is een automatisch gegenereerde rapportage afhankelijk van door derden
							geïmporteerde data. Samenwerkingsverband DOVA kan daarom geen garanties geven over de
							juistheid
							van de inhoud.
						</p>
					</div>
				</div>
				<div class="footer p-mt-6 p-mb-2 p-d-flex">
					<div>Maandelijkse rapportage sociale veiligheid in het openbaar
						vervoer {{ periodDescription }}</div>
					<div class="p-ml-auto">1</div>
				</div>
			</div>

			<div class="page4 p-shadow-2 p-p-5 p-mb-3 p-d-flex p-flex-column">
				<div class="head p-mb-5">
					<img src="assets/img/rapport_logo_dova.jpg"/>
				</div>
				<div class="body p-d-flex p-flex-column index p-p-5">
					<h1>Inhoudsopgave</h1>

					<div class="p-d-flex">
						<div class="from">1</div>
						<div class="what">Inleiding</div>
						<div class="to p-ml-auto">3</div>
					</div>
					<div class="p-d-flex p-mt-1" *ngFor="let c of getChapters(4, 2)">
						<div class="from">{{ c.section }}</div>
						<div class="p-mr-2">{{ c.header }}</div>
						<div class="to p-ml-auto">{{ c.page }}</div>
					</div>

				</div>
				<div class="footer p-mt-6 p-mb-2 p-d-flex">
					<div>Maandelijkse rapportage sociale veiligheid in het openbaar
						vervoer {{ periodDescription }}</div>
					<div class="p-ml-auto">2</div>
				</div>
			</div>

			<div class="page4 p-shadow-2 p-p-5 p-mb-3 p-d-flex p-flex-column">
				<div class="head p-mb-5">
					<img src="assets/img/rapport_logo_dova.jpg"/>
				</div>
				<div class="body p-d-flex text p-pt-5">
					<div>
						<h1 style="width: 2rem;">1.</h1>
					</div>
					<div class="p-d-flex p-flex-column">
						<h1>Inleiding</h1>
						<p>
							Voor u ligt de rapportage die maandelijks wordt opgesteld door het SVOVda<sup>1</sup>
							systeem dat in beheer
							is bij Samenwerkingsverband DOVA. SVOVda helpt gebruikers bij ov-autoriteiten en vervoerders
							om
							eenvoudig en snel inzicht te krijgen in de veiligheidsontwikkelingen in en rond het
							regionaal
							openbaar vervoer. Dit inzicht is van groot belang om de sociale veiligheid voor reizigers en
							ov-personeel te garanderen. Binnen SVOVda wordt op nationaal niveau data gedeeld om
							onderlinge
							afstemming en samenwerking tussen vervoerders en ov-autoriteiten te bevorderen en om de
							sociale
							veiligheid te verbeteren.
						</p>
						<p>
							Deze rapportage wordt automatisch gegenereerd op basis van de door vervoerders aangeleverde
							ABC-data volgens de daarvoor vastgestelde standaard. De ontvanger ontvangt de
							rapportage alleen voor de concessiegebieden waarvoor hij/zij een voorkeur heeft opgeslagen.
							Indien gewenst is deze voorkeur door de gebruiker aan te passen in SVOVda.
						</p>
						<p>
							De tien indicatoren uit in deze rapportage zijn in samenspraak met de ov-autoriteiten en
							vervoerders zijn vastgesteld en geven een integraal beeld van de sociale veiligheid in
							concessiegebied(en). Dat wil zeggen dat alle veiligheidsincidenten worden getoond,
							onafhankelijk
							van het feit welke vervoerder de incidenten heeft geregistreerd. Voor elke indicator is de
							meest
							recente aangeleverde data gevisualiseerd in een grafiek of diagram en met een beknopte
							beschrijving toegelicht.
						</p>
						<p>
							Indien u geïnteresseerd bent in aanvullende informatie en/of verdiepende vragen wilt
							beantwoorden, dan verwijzen wij u graag naar het SVOVda-systeem, waarin gebruikers eigen
							sociale
							veiligheids analyses kunnen worden uitgewerkt. Wanneer u nog niet over inloggegevens
							beschikt,
							kunt u contact opnemen met Samenwerkingsverband DOVA.
						</p>

						<div class="p-mt-auto">
							<p>
								<sup>1</sup>SVOVda staat voor ‘Sociale Veiligheid in het OV data analyse’ en is via de
								volgende url te openen:
								<a href="https://socialeveiligheid.ov-data.nl/">https://socialeveiligheid.ov-data.nl/</a>
							</p>
						</div>
					</div>

				</div>
				<div class="footer p-mt-6 p-mb-2 p-d-flex">
					<div>Maandelijkse rapportage sociale veiligheid in het openbaar
						vervoer {{ periodDescription }}</div>
					<div class="p-ml-auto">3</div>
				</div>
			</div>
		</ng-container>

		<ng-container *ngIf="showCustomPage">
			<div class="page4 p-shadow-2 p-p-5 p-d-flex p-flex-column">

				<div class="body">
					<div class="p-d-flex p-flex-column p-p-4">
						<h1 class="p-mt-0 p-mb-3" *ngIf="storedConfig.title?.length">
							{{storedConfig.title}}
						</h1>
						<div *ngIf="storedConfig.description?.length">
							<div class="content" [innerHTML]="asMarkdown(storedConfig.description)"></div>
						</div>
					</div>
				</div>
				<div class="footer" *ngIf="false"></div>
			</div>
			<div class="p-mb-3 p-d-print-none"></div>
		</ng-container>

		<ng-container *ngFor="let panel of panels; let i = index; let last = last;">

			<ng-container *ngIf="panel.reload">

				<div class="page4 p-shadow-2 p-p-5">

					<div class="pager p-d-print-none" *ngIf="!showReportPages">{{i + 1}}</div>

					<div class="page-content preview">

						<h1 class="p-mt-0" *ngIf="!showReportPages">{{panel.caption}}</h1>
						<h1 class="p-mt-0 p-text-bold" style="color: #9D4D96" *ngIf="showReportPages">{{i + 2}}
							. {{panel.caption}}</h1>

						<base-card [panel]="panel"
								   [groupByOptions$]="groupByOptions$"
								   [presentationMode]="true"
								   [filtersABC]="filtersABC">
						</base-card>

						<div class="content"
							 *ngIf="panel.description !== null"
							 [innerHTML]="asMarkdown(panel.description)">
						</div>

					</div>

					<div class="footer p-mb-2 p-d-flex" *ngIf="showReportPages">
						<div>Maandelijkse rapportage sociale veiligheid in het openbaar
							vervoer {{ periodDescription }}</div>
						<div class="p-ml-auto">{{i + 4}}</div>
					</div>

				</div>

				<div *ngIf="true" class="p-mb-3 p-d-print-none"></div>

				<div *ngIf="last" class="p-mb-3 p-d-print-none"></div>

			</ng-container>
		</ng-container>



		<ng-container *ngIf="showImportSummary && importSummaryTables != null">
			<ng-container *ngFor="let table of importSummaryTables | keyvalue " >
				<div class="page4 p-shadow-2 p-p-5 p-d-flex p-flex-column">
					<div class="head p-mb-5">
						<img src="assets/img/rapport_logo_dova.jpg"/>
					</div>
					<div class="body p-d-flex p-flex-column index p-p-5">
						<h1 class="p-m-0">Aantal geïmporteerde incidenten per concessie per maand<span *ngIf="table.key > 0"> (vervolg)</span>.</h1>
						<p class="p-mb-4" *ngIf="table.key == 0">
							Het aantal geïmporteerde nieuwe incidenten per concessie. <span class="p-text-italic">Let op</span>: de tabel toont wanneer incidenten zijn geïmporteerd, niet wanneer de incidenten hebben plaatsgevonden.
						</p>


						<div class="summary-table p-datatable-dova p-datatable p-mt-2">
							<table style="border-spacing: unset !important;">
								<thead class="p-datatable-thead">
								<tr>
									<th>Uw concessie selectie</th>
									<th></th>
									<th *ngFor="let m of importSummaryColumns">
										{{friendlyMonth(m)}}
									</th>
								</tr>
								</thead>
								<tbody class="p-datatable-tbody">
								<ng-container *ngFor="let item of table.value">
									<tr>
										<td rowspan="2" style="font-weight: 500 !important;">{{item.key}}</td>
										<td>1 of meer imports</td>
										<td *ngFor="let summary of item.value" [style.background-color]="summary.imported ? '#a3e6de' : '#FED7BB'">
											<span *ngIf="summary.imported">ja</span>
											<span *ngIf="!summary.imported">nee</span>
										</td>
									</tr>
									<tr>
										<td>Incidenten</td>
										<td *ngFor="let summary of item.value">
											<span *ngIf="summary.amount_incidents > 0">
												{{summary.amount_incidents || 0}}
											</span>
											<span *ngIf="summary.amount_incidents == 0">

											</span>
										</td>
									</tr>
								</ng-container>
								</tbody>
							</table>
						</div>
					</div>
					<div class="footer p-mt-6 p-mb-2 p-d-flex">
						<div>Maandelijkse rapportage sociale veiligheid in het openbaar vervoer</div>
						<div class="p-ml-auto"></div>
					</div>
				</div>
			</ng-container>
		</ng-container>

		<ng-container *ngIf="false">
			<div class="page4 p-p-5 p-d-flex p-flex-column" id="page-appendix-1">
				<div class="head p-mb-5">
					<img src="assets/img/rapport_logo_dova.jpg"/>
				</div>
				<div class="body p-d-flex p-flex-column index p-p-5">
					<h1>Bijlagen</h1>

					<div class="appendix-table p-datatable-dova p-datatable">
						<table>
							<thead class="p-datatable-thead">
							<tr>
								<th>Naam</th>
								<th>Type</th>
								<th colspan="3">Definitie</th>
								<th>Verplicht</th>
							</tr>
							</thead>
							<tbody class="p-datatable-tbody"></tbody>
						</table>
					</div>
				</div>
				<div class="footer p-mt-6 p-mb-2 p-d-flex">
					<div>Maandelijkse rapportage sociale veiligheid in het openbaar vervoer</div>
					<div class="p-ml-auto"></div>
				</div>
			</div>
		</ng-container>

	</div>

</ng-template>


<!-- SAVE ANALYSE DIALOG -->
<p-dialog *ngIf="showSaveFilterDialog"
		  [header]="selectedStoredConfig === null ? 'Nieuwe analyse opslaan' : 'Analyse bewerken'"
		  [(visible)]="showSaveFilterDialog"
		  [draggable]="false"
		  [resizable]="false"
		  [modal]="true"
		  [style]="{width: '46rem'}"
		  [baseZIndex]="10000">
	<base-my-filter [storedConfig]="selectedStoredConfig"
					[myConfigs]="myStoredConfigs"
					[panels]="panels"
					(onSave)="afterSaveFilter($event)"
					(onDelete)="afterDeleteFilter($event)"
					(onClose)="selectedStoredConfig = null; showSaveFilterDialog = false;"></base-my-filter>
</p-dialog>

<!-- MY-STORED ANALYSES OVERLAY -->
<p-overlayPanel #myFiltersPanel [style]="{width: '450px'}" [showCloseIcon]="true" [dismissable]="false">
	<ng-template pTemplate>
		<p-table [value]="myStoredConfigs" selectionMode="single" styleClass="p-datatable-dova"
				 [paginator]="true"
				 (onRowSelect)="onRowSelect($event)"
				 [rows]="5"
				 [alwaysShowPaginator]="false"
				 [autoLayout]="true">
			<ng-template pTemplate="header">
				<tr>
					<th pSortableColumn="name">Naam
						<p-sortIcon field="name"></p-sortIcon>
					</th>
					<th>Toon in menu</th>
					<th></th>
				</tr>
			</ng-template>
			<ng-template pTemplate="body" let-myFilter>
				<tr [pSelectableRow]="myFilter">
					<td>{{myFilter.label}}</td>
					<td>{{myFilter.show_in_menu ? 'ja' : 'nee'}}</td>
					<td class="p-text-right">
						<button pButton pRipple (click)="openSaveFilterDialog(myFilter, false, false);"
								class="p-button-rounded p-button-secondary p-button-text"
								icon="fa fa-pencil">
						</button>
					</td>
				</tr>
			</ng-template>
		</p-table>
	</ng-template>
</p-overlayPanel>

<!-- FILTER-SIDEBAR -->
<p-sidebar position="right"
		   [style]="{width:'40rem'}"
		   [showCloseIcon]="true"
		   [(visible)]="showSidebar"
		   [blockScroll]="true">
	<base-filters-sidebar
		(onPanelChange)="applyFilters($event)"
		[groupOptions$]="groupByOptions$"
		[groupByOptionsPolice$]="groupByOptionsPolice$"
		[panelSubject]="panelSubject">
	</base-filters-sidebar>
</p-sidebar>

<!-- SHARED-CONFIG -->
<p-dialog header="Gedeelde analyse"
		  [draggable]="false"
		  [resizable]="false"
		  [modal]="true"
		  [style]="{width: '36rem'}"
		  [baseZIndex]="10000"
		  *ngIf="showSharingDialog"
		  [(visible)]="showSharingDialog">

	<div class="p-pl-2" style="border-left: 3px solid">
		<h2>{{storedConfig.label}}</h2>
		<i *ngIf="storedConfig.title?.length">{{storedConfig.title}}</i>
	</div>

	<p>Dit is een gedeelde analyse van een andere gebruiker. Wilt u deze gelijk opslaan of u hem alleen bekijken? U kunt hem ook later opslaan.</p>

	<ng-template pTemplate="footer">
		<div class="p-d-flex p-jc-end">
			<p-button (click)="showSharingDialog = false;"
					  label="Sluiten"
					  styleClass="p-button-secondary p-button-text p-button-rounded"></p-button>
			<p-button (click)="openSaveFilterDialog(storedConfig, true, true)"
					  label="Opslaan in 'Mijn analyses'"
					  styleClass="p-button-primary p-button-rounded"></p-button>
		</div>
	</ng-template>
</p-dialog>
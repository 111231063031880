import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators} from "@angular/forms";
import {ConfirmationService, Message, MessageService} from "primeng/api";
import {ConfigsService} from "../../../services/data/configs.service";
import {sherpa, util} from "../../../../services";
import {MultiChartQueryConfiguration, QueryConfiguration, StoredConfig} from "../../../types/sherpa";
import _ from "lodash";
import {AnalysePanel} from "../../../classes/models/AnalysePanel";
import {IncidentsService} from "../../../services/data/incidents.service";
import {UtilService} from "../../../services/util.service";
import {SessionService} from "../../../modules/auth/services/session.service";

@Component({
	selector: 'base-my-filter',
	templateUrl: './my-filter.component.html',
	styleUrls: ['./my-filter.component.scss']
})
export class MyFilterComponent implements OnInit {

	@Input() storedConfig: StoredConfig;
	@Input() panels: AnalysePanel[];
	@Input() myConfigs: StoredConfig[];

	@Output() onClose: EventEmitter<void> = new EventEmitter<void>();
	@Output() onDelete: EventEmitter<number> = new EventEmitter<number>();
	@Output() onSave: EventEmitter<StoredConfig> = new EventEmitter<StoredConfig>();

	form: FormGroup;
	duplicateError = false;
	pending = false;

	validDomains = ['irias.nl', 'dova.nu'];
	myDomain: string;

	public get controls() {
		return this.form.controls;
	}

	constructor(private _fb: FormBuilder, public messageService: MessageService, private incidentsService: IncidentsService, private confirmationService: ConfirmationService, public configsService: ConfigsService, private session: SessionService, private util: UtilService) {
	}

	containError(code: string) {
		return this.controls.errors.getError(code);
	}

	ngOnInit() {
		this.myDomain = _.last(_.split(this.session.sessionUser.username, '@'));
		this.validDomains.push(this.myDomain);

		if (this.storedConfig === null) {
			this.storedConfig = {} as StoredConfig;
			this.storedConfig.label = null;
			this.storedConfig.show_in_menu = true;
		}

		if (this.storedConfig.show_in_menu === null) {
			this.storedConfig.show_in_menu = true;
		}

		this.form = this._fb.group({
			name: [this.storedConfig.label, Validators.required],
			showInMenu: [this.storedConfig.show_in_menu, Validators.required],
			title: [this.storedConfig.title],
			description: [this.storedConfig.description],
			recipients: [this.storedConfig.recipients, this.recipientsValidator()]
		});
	}

	recipientsValidator(): ValidatorFn {
		return (control: AbstractControl): ValidationErrors | null => {

			const value = control.value;

			if (!value || _.isEmpty(value)) {
				return null;
			}

			let error = false;
			let target;
			_.forEach(value, (email: string) => {

				// const validDomain = _.endsWith(email, '@irias.nl');
				const domainParts = _.split(email, '@');
				let validDomain = false;

				if (domainParts.length != 2) {
					error = true;
				} else {
					validDomain = _.includes(this.validDomains, domainParts[1]);
				}

				if (error || !validDomain) {
					error = true;
					target = email;
					return false;
				}

				return true;
			});

			return error ? {invalidRecipient: target} : null;
		}
	}

	submit() {
		this.duplicateError = false;
		if (this.form.invalid) {
			return Promise.reject('Het formulier bevat fouten.');
		}

		const label = this.controls.name.value;
		const already = _.some(this.myConfigs, ['label', label]);

		if (already && (this.storedConfig === null || this.storedConfig === undefined)) {
			this.duplicateError = true;
			return Promise.reject('De naam is niet uniek.');
		}

		let cleanPanels: AnalysePanel[] = null;

		if ((this.storedConfig === null || this.storedConfig === undefined) && this.myConfigs !== null && this.myConfigs !== undefined) {
			// workaround
			this.storedConfig = {
				label: "",
				month_window_size: 0,
				show_in_menu: false,
				stored_config_id: null,
				user_email: "",
				_createNewConfig: true,
				_updateConfig: true,
				config: this.myConfigs,
				sharing_code: null,
				title: "",
				description: "",
				subscribe: false,
				recipients: []
			}
		} else if (this.storedConfig.config === null || this.storedConfig.config === undefined) {
			this.storedConfig.config = this.myConfigs;
			if (this.storedConfig._createNewConfig === null || this.storedConfig._createNewConfig === undefined) {
				this.storedConfig._createNewConfig = true;
			}
			if (this.storedConfig._updateConfig === null || this.storedConfig._updateConfig === undefined) {
				this.storedConfig._updateConfig = true;
			}
		}


		if (this.storedConfig?._updateConfig) {
			cleanPanels = [];

			_.forEach(this.panels, panel => {
				let clean = _.omit(panel, this.incidentsService.panelAdditionProperties) as AnalysePanel;
				cleanPanels.push(clean);
			});

			let range = _.range(cleanPanels.length);
			_.forEach(range, i => {
				if (!this.panels[i].visible) {
					cleanPanels[i] = null;
				}
			});
		}

		const storedConfig = {
			stored_config_id: this.storedConfig?._createNewConfig ? null : this.storedConfig?.stored_config_id,
			label: this.controls.name.value,
			show_in_menu: this.controls.showInMenu.value,
			user_email: null,
			config: cleanPanels,
			title: this.controls.title.value,
			description: this.controls.description.value,
			recipients: this.controls.recipients.value
		} as StoredConfig;

		this.pending = true;
		return this.configsService.saveConfig(storedConfig)
			.then((result: StoredConfig) => {
				this.storedConfig = result;
				this.onSave.emit(result);

				return result;
			})
			.finally(() => {
				_.delay(() => {
					this.pending = false;
				}, 500);
			});
	}

	close() {
		this.onClose.emit();
	}

	confirmDelete() {
		this.confirmationService.confirm({
			key: 'confirm-delete',
			message: 'Weet u zeker dat u deze analyse wilt verwijderen?',
			header: 'Verwijderen bevestigen',
			icon: 'pi pi-info-circle',
			accept: () => {
				this.configsService.deleteConfig(this.storedConfig.stored_config_id)
					.then(() => this.onDelete.emit(this.storedConfig.stored_config_id));
			},
			reject: () => {
			}
		});
	}

	emailRecipients() {
		this.confirmationService.confirm({
			key: 'confirm-email-to-recipients',
			message: 'U staat op het punt om de betreffende ontvangers te emailen, wilt u de eventuele wijzigingen opslaan en doorgaan met versturen?',
			header: 'Versturen bevestigen',
			icon: 'pi pi-info-circle',
			accept: () => {
				this.submit()
					.then((result: StoredConfig) => {
						this.configsService.sendSubscribedStoredConfig(result.stored_config_id)
							.then(() => {
								this.util.toast('De analyse is verzonden', null, 'info');
							});
					});
			},
			reject: () => {
			}
		});
	}

}

import {Component, OnInit} from '@angular/core';
import {util} from "../../../../services";

@Component({
	selector: 'base-app-loader',
	templateUrl: './app-loader.component.html',
	styleUrls: ['./app-loader.component.scss']
})
export class AppLoaderComponent implements OnInit {

	loading: boolean;
	mode: string = 'indeterminate';

	constructor() {
		util.isLoading.subscribe((load) => {
			this.loading = load;
		});
	}

	ngOnInit() {
	}

}

import {Component, OnInit, ViewChild} from '@angular/core';
import {TableColumn} from '../../../../classes/primeng/TableColumn';
import _ from 'lodash';
import {util} from '../../../../../services';
import {SourceMessage, SourceView} from '../../../../types/sherpa';
import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {IncidentsService} from '../../../../services/data/incidents.service';
import {Table} from 'primeng/table';

@Component({
	selector: 'base-import-messages-modal',
	templateUrl: './import-messages-modal.component.html',
	styleUrls: ['./import-messages-modal.component.scss']
})
export class ImportMessagesModalComponent implements OnInit {

	source: SourceView;
	messages: SourceMessage[] = [];
	cols: TableColumn[] = [];
	visibleColumns: TableColumn[] = [];

	showActionButtons = true;
	@ViewChild('dt') table: Table;
	errorCodes: any;

	constructor(public ref: DynamicDialogRef, public config: DynamicDialogConfig, private incidentsService: IncidentsService) {
		this.source = this.config.data.sourceView;
	}

	ngOnInit(): void {

		this.cols = [
			{field: 'sheet', header: 'Blad'},
			{field: '_field', header: 'Regel'},
			{field: 'message', header: 'Bericht'},
			{field: 'incident', header: 'Incident'},
		] as TableColumn[];

		this.visibleColumns = _.take(this.cols, 4);
		this.setMessages(this.config.data.messages);
		this.showActionButtons = this.config.data.showActionButtons;

		this.errorCodes = [
			{label: 'Goed', value: {error_code: 0, class: 'p-tag-success'}},
			{label: 'Let op', value: {error_code: 1, class: 'p-tag-warning'}},
			{label: 'Waarde genegeerd', value: {error_code: 2, class: 'p-tag-danger'}},
			{label: 'Rij genegeerd', value: {error_code: 3, class: 'p-tag-danger'}},
			{label: 'Sheet afgekeurd', value: {error_code: 4, class: 'p-tag-danger'}}
		];
	}

	setMessages(messages: SourceMessage[]) {
		this.messages = [];
		const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';

		// when just 1 sheet, dont show the column
		const maxSheet = _.maxBy(messages, 'sheet');
		if (maxSheet.sheet === 0) {
			this.visibleColumns = _.without(this.cols, this.cols[0]);
		} else {
			this.visibleColumns = _.take(this.cols, 4);
		}

		// add property _field to set friendly excel-field like A1, B2, C3
		_.forEach(messages, (m: SourceMessage) => {

			if(m.column == null) {
				// @ts-ignore
				m._field = m.line;
			}
			else {
				const result = m.column / alphabet.length;
				let position = _.floor(result);
				let rest = m.column % alphabet.length;
				if (rest === 0) {
					rest = 26;
					position -= 1;
				}

				if (position > 0) {
					// @ts-ignore
					m._field = alphabet[position - 1] + alphabet[rest - 1] + ':' + m.line;
				} else {
					// @ts-ignore
					m._field = alphabet[rest - 1] + ':' + m.line;
				}
			}

			this.messages.push(m);
		});
	}

	searchTable(target: any) {
		this.table.filterGlobal(target.value, 'contains');
	}

	searchColumn(target: any, field: string, mode: 'startsWith' | 'contains' | 'endsWith' | 'equals' | 'notEquals' | 'in' | 'lt' | 'lte' | 'gt' | 'gte') {
		this.table.filter(target.value, field, mode);
	}

	onErrorCodeChange(event: any) {
		const errorCodes = _.map(event.value, x => {
			return x.value.error_code;
		});

		this.table.filter(errorCodes, 'error_code', 'in');
	}

	cancelImport() {
		this.incidentsService.cancelImport(this.source.importsource_id)
			.then((result) => {
				this.ref.close(this.source);
			});
	}

	commitImport(overwrite_non_uniques: boolean) {
		this.incidentsService.commitImport(this.source.importsource_id, overwrite_non_uniques)
			.then((result) => {
				this.ref.close(this.source);
			});
	}

	format(size: number): string {
		return util.formatBytes(size);
	}
}

<form (ngSubmit)="submit();" class="dovaForm">
	<div class="p-fluid">

		<p-table [value]="panels" [reorderableColumns]="true">
			<ng-template pTemplate="header" let-columns>
				<tr>
					<th style="width:3rem"></th>
					<th pReorderableColumn>
						Naam
					</th>
				</tr>
			</ng-template>
			<ng-template pTemplate="body" let-panel let-columns="columns" let-index="rowIndex">
				<tr [pReorderableRow]="index">
					<td>
						<span class="pi pi-bars" ></span>
					</td>
					<td>
						{{panel.name}}
					</td>
				</tr>
			</ng-template>
		</p-table>

	</div>

	<div class="p-grid p-formgrid p-mt-5">
		<div class="p-col-12 p-text-right">
			<button pButton pRipple type="button" label="Annuleren"
					class="p-button-secondary p-button-text p-button-rounded p-mr-2"
					(click)="ref.destroy()"></button>
			<button pButton pRipple type="submit" label="Ok"
					class="p-button-rounded p-text-uppercase">
			</button>
		</div>
	</div>

</form>
import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from "@angular/router";
import {global} from "../../globals";
import {IncidentsService} from "../services/data/incidents.service";
import {Filter, GroupOption} from "../types/sherpa";

@Injectable({
	providedIn: 'root'
})
export class GroupOptionsResolver implements Resolve<GroupOption[]> {

	constructor(private incidentsService: IncidentsService) {
	}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<GroupOption[]> {
		global.enableLoader();
		return this.incidentsService.getGroupOptions('incident');
	}
}
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {AuthRoutingModule} from './auth-routing.module';
import {PasswordComponent} from "./components/password/password.component";
import {ProfileComponent} from "./components/profile/profile.component";
import {SettingsComponent} from "./components/settings/settings.component";
import {ToastModule} from "primeng/toast";
import {ConfirmDialogModule} from "primeng/confirmdialog";
import {MessagesModule} from "primeng/messages";
import {TableModule} from "primeng/table";
import {InputTextModule} from "primeng/inputtext";
import {ReactiveFormsModule} from "@angular/forms";
import {LoginComponent} from "./components/login/login.component";
import {RippleModule} from "primeng/ripple";
import {PasswordModule} from "primeng/password";
import {PasswordResetComponent} from "./components/password-reset/password-reset.component";
import {TwofactorComponent} from "./components/twofactor/twofactor.component";
import {CardModule} from "primeng/card";
import {TrustedDeviceEditComponent} from "./components/trusted-device-edit/trusted-device-edit.component";

@NgModule({
	declarations: [
		PasswordComponent,
		ProfileComponent,
		SettingsComponent,
		TwofactorComponent,
		LoginComponent,
		PasswordResetComponent,
		TrustedDeviceEditComponent
	],
	imports: [
		CommonModule,
		AuthRoutingModule,
		ToastModule,
		ConfirmDialogModule,
		MessagesModule,
		TableModule,
		InputTextModule,
		ReactiveFormsModule,
		RippleModule,
		PasswordModule,
		CardModule
	],
	exports: [
		LoginComponent
	]
})
export class AuthModule {}
import {Injectable} from '@angular/core';

import _ from "lodash";
import {global} from 'src/globals';
import {BehaviorSubject, Observable, of} from "rxjs";
import {DialogService} from "primeng/dynamicdialog";
import {UserModel} from "../classes/UserModel";
import {SherpaService} from "../../../services/sherpa.service";
import {LoginResult} from "../classes/LoginResult";
import {authApi} from "../guards/auth.guard";
import {SherpaError} from "../../../classes/SherpaError";
import {ConfirmationService, MessageService} from "primeng/api";
import {LoginCredentials} from "../classes/LoginCredentials";
import DeviceDetector from "device-detector-js";

@Injectable({
	providedIn: 'root'
})
export class SessionService {

	authSubject = new BehaviorSubject<boolean>(false);
	appCode = 'dova-svovda';
	sessionUser: UserModel = null;

	constructor(private sherpa: SherpaService, private dialogService: DialogService, private confirmationService: ConfirmationService, private msg: MessageService) {
	}

	getAppParam(): any {
		return {
			app_code: this.appCode
		};
	}

	public login(credentials: LoginCredentials): Promise<LoginResult> {
		const appParam = this.getAppParam();

		return authApi.login<LoginResult>(credentials.username, credentials.password, appParam)
			.then((result: LoginResult) => {
				this.setUser(result.user);
				this.setToken(result.token);
				return result;
			});
	}

	public loginWith2FA(credentials: LoginCredentials, code: string): Promise<LoginResult> {
		const appParam = this.getAppParam();
		const parsedAgent = new DeviceDetector().parse(navigator.userAgent);
		let friendlyUserAgent = '';

		if(parsedAgent.device.type === 'desktop') {
			friendlyUserAgent = `${parsedAgent.os.name} ${parsedAgent.os.version} - `;
		}
		else if(parsedAgent.device.type === 'smartphone') {
			friendlyUserAgent = `${parsedAgent.device.brand} ${parsedAgent.device.model} - `;
		}
		friendlyUserAgent += `${parsedAgent.client.name} (${parsedAgent.client.version})`;


		const twoFA = {
			totp_token: code,
			trusted_token: this.getTrustedToken(),
			agent: friendlyUserAgent
		};

		return authApi.login3<LoginResult>(credentials.username, credentials.password, twoFA, appParam)
			.then((result: LoginResult) => {
				this.setUser(result.user);
				this.setToken(result.token);

				if(this.getTrustedToken() !== result.trusted_token) {
					this.addTrustedDeviceModal(result.trusted_token);
				}
				return result;
			})
			.catch((e: SherpaError) => {
				if(_.includes(['sherpaBadAuth', 'sherpaPermissionDenied'], e.code)) {
					this.removeTrustedToken();
				}

				return Promise.reject(e);
			});
	}

	logout(url = '/'): Promise<void> {
		return authApi.logout<void>(this.token())
			.then(() => {
				global.loginModal = true;
				global.requestedUrl = url;
			})
			.catch((e: SherpaError) => {
				this.clear(true);
			});
	}

	whoami(): Promise<UserModel | SherpaError> {
		if (_.isEmpty(this.token())) {
			const error = new SherpaError('error', 'No token found');
			return Promise.reject(error);
		}

		return authApi.whoami2<UserModel>(this.token())
			.then(user => {
				this.setUser(user);
				return user;
			})
			.catch(error => {
				this.clear(false);
				return Promise.reject(error);
			});
	}

	getUserParameterValue(key: string) {
		if (!_.isNil(this.sessionUser.params)) {
			const f = _.find(this.sessionUser.params, ['key', key]) || null;

			if (f !== null) {
				return f.value;
			}
		}

		return null;
	}

	clear(reload: boolean = true): void {
		this.removeToken();
		this.setUser(null);
		if (reload) {
			window.location.reload();
		}
	}

	setUser(user: any): void {
		this.sessionUser = user;
		global.loginUser = user;
		global.loginModal = user === null;
		global.requestedUrl = '/';
	}

	getUser() {
		return this.sessionUser || null;
	}

	token(): string {
		return sessionStorage.getItem('token') || '';
	}

	setToken(token: string): void {
		sessionStorage.setItem('token', token);
	}

	removeToken(): void {
		sessionStorage.removeItem('token');
	}


	/* two-factor-auth */

	is2faEnabled(){
		return authApi.is2faEnabled<boolean>(this.token());
	}

	enable2fa(tfa_password: string, b: boolean) {
		return authApi.enable2fa<VoidFunction>(this.token(), tfa_password, b);
	}

	getTrustedToken() {
		const token = localStorage.getItem('trusted_token');
		if(token == 'null') {
			this.removeTrustedToken();
			return null;
		}

		return token || null;
	}

	setTrustedToken(trusted_token: string): void {
		localStorage.setItem('trusted_token', trusted_token);
	}

	removeTrustedToken() {
		localStorage.removeItem('trusted_token');
	}


	/* modals */

	loginModal(requested_url: string): void {
		this.clear(false);
		global.loginModal = true;
		global.requestedUrl = requested_url;
	}

	addTrustedDeviceModal(trusted_token: string) {
		this.confirmationService.confirm({
			key: 'trustedDialog',
			message: 'Wilt u dit apparaat toevoegen aan uw vertrouwde apparaten?',
			header: 'Apparaat toevoegen?',
			icon: 'pi pi-exclamation-triangle',
			acceptLabel: 'Ja',
			rejectLabel: 'Nee',
			acceptButtonStyleClass: 'p-button-rounded',
			rejectButtonStyleClass: 'p-button-rounded p-button-secondary p-button-text',
			accept: () => {
				this.setTrustedToken(trusted_token);
				this.msg.add({severity:'success', summary:'Opgeslagen', detail:'Dit apparaat is toegevoegd'});
			},
			reject: (type: any) => {
			}
		});
	}

}

import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, SimpleChange} from '@angular/core';
import {Filter, FilterField, IncidentField, QueryConfiguration, StoredConfig} from "../../../types/sherpa";
import _ from "lodash";
import {Observable, Subscription} from "rxjs";
import {TreeNode} from "primeng/api";
import {AnalysePanel} from "../../../classes/models/AnalysePanel";
import {ConfigsService} from "../../../services/data/configs.service";

@Component({
	selector: 'base-filter-multiple',
	templateUrl: './filter-multiple.component.html',
	styleUrls: ['./filter-multiple.component.scss']
})
export class FilterMultipleComponent implements OnInit {

	@Input() filter: Filter;
	@Input() panelObservable: Observable<AnalysePanel>;
	subscription: Subscription;

	@Output() onFilterFieldChanged: EventEmitter<FilterField> = new EventEmitter<FilterField>();

	collapsed: boolean = true;
	fieldLabel: string = null;

	treeNodes: TreeNode[] = null;
	selectedNodes: TreeNode[];
	relevantFiltersOnly: boolean = false;
	loading: boolean = false;
	noInitValues: boolean;
	searchedOnce = false;

	selectedValues: { value: any, label: string }[] = [];

	searchTimerId = -1;
	searchFilterCounter = 0;
	searchQry: string = null;
	searchValues: { value: any, label: string }[] = null;

	constructor(private cd: ChangeDetectorRef, private configsService: ConfigsService) {
	}

	ngOnInit(): void {
		this.initTree(); // create tree object
		this.noInitValues = this.filter.values === null;

		this.subscription = this.panelObservable.subscribe(panel => {
			if (panel === null) {
				return;
			}

			this.relevantFiltersOnly = panel.relevantFiltersOnly;
			this.setSelectedValues(panel.andFilterFields);
			this.cd.markForCheck();
		});
	}

	initTree() {
		let key: number = 1;
		let nodes: TreeNode[] = [];

		_.forEach(this.filter.values, item => {
			let node = {
				key: `${key}`,
				selectable: true,
				leaf: true,
				label: item.label,
				data: item.value
			} as TreeNode;

			nodes.push(node);
			key++;
		});

		const root = {
			key: 'root',
			selectable: true,
			expanded: true,
			styleClass: 'no-children',
			label: `--alles--`,
			children: nodes
		} as TreeNode;

		this.treeNodes = [];
		if (nodes.length) {
			this.treeNodes.push(root);
		}

		this.cd.markForCheck();
	}

	done(nodes: TreeNode[]) {
		const childrenOnly = _.filter(nodes, node => {
			return !_.isNil(node.data);
		});

		const valuesOnly = _.map(childrenOnly, 'data');

		// trigger parent
		this.onFilterFieldChanged.emit({key: this.filter.key, expectedValue: valuesOnly});

		this.updateFieldLabel();
	}

	setSelectedValues(newFilterFieldConfig: FilterField[]) {
		const matchedField = _.find(newFilterFieldConfig, ['key', this.filter.key]) as FilterField;

		this.selectedNodes = [];
		this.selectedValues = [];

		if (matchedField) {

			_.forEach(matchedField.expectedValue, value => {

				if (this.treeNodes.length) {
					const node = _.find(this.treeNodes[0].children, {data: value});

					if (node) {
						this.treeNodes[0].partialSelected = true;
						this.selectedNodes.push(node);
					}
				}

				if(this.noInitValues) {
					this.selectedValues.push({value: value, label: value});
				}

			});
		}


		// update the displayed label
		this.updateFieldLabel();
	}

	backendSearch(event: any) {
		if (!this.noInitValues) {
			return;
		}

		const searchQuery: string = event.filter;

		clearTimeout(this.searchTimerId);

		if (searchQuery.length > 1) {
			this.searchTimerId = _.delay(() => {
				this.search(event.filter);
			}, 500);
		}
		else if(searchQuery.length == 0) {
			this.searchValues = null;
		}
	}

	search(qry: string) {
		this.searchFilterCounter++;

		this.loading = true;
		const expect = this.searchFilterCounter;
		this.configsService.searchFilter(this.filter.key, qry)
			.then(results => {
				if (expect == this.searchFilterCounter) {
					this.searchValues = results;
				}
			})
			.finally(() => {
				this.loading = false;
				this.searchedOnce = true;
				this.cd.detectChanges();
			})
	}

	updateFieldLabel() {
		this.fieldLabel = null;

		if (this.selectedNodes.length) {
			this.fieldLabel = `(${this.selectedNodes.length} geselecteerd)`;
		}
		else if(this.selectedValues.length) {
			this.fieldLabel = `(${this.selectedValues.length} geselecteerd)`;
		}
	}

	ngOnDestroy() {
		this.subscription.unsubscribe();
	}

	addSelectedValue(kv: { label: string, value: any }) {
		this.selectedValues = _.unionBy(this.selectedValues, [kv], 'value');

		const valuesOnly = _.map(this.selectedValues, 'value');
		this.onFilterFieldChanged.emit({key: this.filter.key, expectedValue: valuesOnly});
		this.updateFieldLabel();
	}

	removeSelectedValue(v: string) {
		_.remove(this.selectedValues, {value: v});

		const valuesOnly = _.map(this.selectedValues, 'value');
		this.onFilterFieldChanged.emit({key: this.filter.key, expectedValue: valuesOnly});
		this.updateFieldLabel();
	}
}

import {Component, OnInit} from '@angular/core';
import {SvovdaFields} from "../../../../types/sherpa";
import {IncidentsService} from "../../../../services/data/incidents.service";
import _ from "lodash";
import marked from "marked";

@Component({
	selector: 'base-docs-tab',
	templateUrl: './docs-tab.component.html',
	styleUrls: ['./docs-tab.component.scss']
})
export class DocsTabComponent implements OnInit {
	fields: SvovdaFields = null;

	constructor(private incidentsService: IncidentsService) {
	}

	ngOnInit(): void {
		this.incidentsService.getFields().then((result: SvovdaFields) => {
			this.fields = result;
		});
	}

	arrToString(arr: string []) {
		return _.join(arr, ', ');
	}

	asMarkdown(txt: string) {
		return marked(txt);
	}
}

import {Component, OnInit} from '@angular/core';
import {DynamicDialogConfig, DynamicDialogRef} from "primeng/dynamicdialog";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {AnalysePanel} from "../../../classes/models/AnalysePanel";

@Component({
	selector: 'base-edit-panel-info',
	templateUrl: './edit-panel-info.component.html',
	styleUrls: ['./edit-panel-info.component.scss']
})
export class EditPanelInfoComponent implements OnInit {

	form: FormGroup;
	panel: AnalysePanel;

	public get controls() {
		return this.form.controls;
	}

	constructor(private _fb: FormBuilder, public ref: DynamicDialogRef, private config: DynamicDialogConfig) {
	}

	ngOnInit() {
		this.panel = this.config.data.panel;

		this.form = this._fb.group({
			caption: [this.panel.caption],
			description: [this.panel.description],
		});
	}

	submit() {
		this.panel.caption = this.controls.caption.value;
		this.panel.description = this.controls.description.value;

		this.ref.close(this.panel);
	}

}

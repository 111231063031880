<div class="p-d-flex" *ngIf="showActionButtons">
	<div class="p-mr-2" *ngIf="source.import_result.non_uniques > 0">
		<button pButton pRipple class="p-button-outlined" icon="fa fa-thumbs-up" label="Overschrijf duplicaten"
				(click)="commitImport(true)"></button>
	</div>
	<div class="p-mr-2" *ngIf="source.import_result.non_uniques > 0">
		<button pButton pRipple class="p-button-outlined" icon="fa fa-thumbs-up" label="Negeer duplicaten"
				(click)="commitImport(false)"></button>
	</div>
	<div class="p-mr-2" *ngIf="source.import_result.non_uniques === 0">
		<button pButton pRipple class="p-button-outlined" icon="fa fa-thumbs-up" label="Importeer incidenten"
				(click)="commitImport(false)"></button>
	</div>
	<div class="p-mr-2">
		<button pButton pRipple class="p-button-outlined" icon="fa fa-trash-alt" label="Import verwerpen"
				(click)="cancelImport()"></button>
	</div>
</div>


<p-table #dt
		 styleClass="p-datatable-dova p-mt-4"
		 [columns]="visibleColumns"
		 [value]="messages"
		 [paginator]="true"
		 paginatorDropdownAppendTo="body"
		 [showCurrentPageReport]="true"
		 currentPageReportTemplate="Toont {first} tot {last} van de {totalRecords} items"
		 [rows]="10"
		 [sortField]="'_field'"
		 [rowsPerPageOptions]="[10,25,50,100,500]"
		 [rowHover]="false">

	<ng-template pTemplate="header" let-columns>
		<tr>
			<th>Toetsing</th>
			<th *ngFor="let col of columns" [pSortableColumn]="col.field">
				{{col.header}}
				<p-sortIcon [field]="col.field" ariaLabel="Activate to sort"
							ariaLabelDesc="Activate to sort in descending order"
							ariaLabelAsc="Activate to sort in ascending order"></p-sortIcon>
			</th>
		</tr>
		<tr>
			<th class="pl-0">
				<p-multiSelect [options]="errorCodes"
							   placeholder="Selecteer"
							   (onChange)="onErrorCodeChange($event)"
							   appendTo="body"
							   scrollHeight="300px"
							   styleClass="p-column-filter w-full"
							   optionLabel="label">
					<ng-template let-option pTemplate="item">
						<div class="p-multiselect-representative-option">
							<span class="p-tag" [ngClass]="option.value.value.class">
								{{option.label}}
							</span>
						</div>
					</ng-template>
				</p-multiSelect>
			</th>
			<th class="pl-0">
				<input pInputText type="text" placeholder="Zoeken"
					   (input)="searchColumn($event.target, '_field', 'contains')"
					   class="p-column-filter p-inputtext-sm">
			</th>
			<th class="pl-0">
				<input pInputText type="text" placeholder="Zoeken"
					   (input)="searchColumn($event.target, 'message', 'contains')"
					   class="p-column-filter p-inputtext-sm">
			</th>
			<th class="pl-0">
				<input pInputText type="text" placeholder="Zoeken"
					   (input)="searchColumn($event.target, 'incident', 'contains')"
					   class="p-column-filter p-inputtext-sm">
			</th>
		</tr>
	</ng-template>
	<ng-template pTemplate="body" let-rowData let-columns="columns">
		<tr>
			<td>
				<span class="p-tag p-tag-success" *ngIf="rowData.error_code === 0">
					Goed
				</span>
				<span class="p-tag p-tag-warning" *ngIf="rowData.error_code === 1">
					Let op
				</span>
				<span class="p-tag p-tag-danger" *ngIf="rowData.error_code === 2">
					Waarde genegeerd
				</span>
				<span class="p-tag p-tag-danger" *ngIf="rowData.error_code === 3">
					Rij genegeerd
				</span>
				<span class="p-tag p-tag-danger" *ngIf="rowData.error_code === 4">
					Sheet afgekeurd
				</span>
			</td>
			<td *ngFor="let col of columns">
				{{rowData[col.field]}}
			</td>
		</tr>
	</ng-template>
	<ng-template pTemplate="emptymessage" let-columns="columns">
		<tr>
			<td colspan="4">
				Geen resultaten gevonden..
			</td>
		</tr>
	</ng-template>

</p-table>
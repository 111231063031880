import {Component, OnDestroy, OnInit} from '@angular/core';
import {Filter, GroupOption, StoredConfig, StoredConfigsWrapper, SvovdaFields} from "../../../types/sherpa";
import _ from "lodash";
import {global} from "../../../../globals";
import {AnalysePanel, PanelType} from "../../../classes/models/AnalysePanel";
import {Subscription} from "rxjs";
import {UtilService} from "../../../services/util.service";
import {ActivatedRoute, Router} from "@angular/router";
import {ConfigsService} from "../../../services/data/configs.service";
import {MessageService} from "primeng/api";

@Component({
	selector: 'base-card-view',
	templateUrl: './card-view.component.html',
	styleUrls: ['./card-view.component.scss']
})
export class CardViewComponent implements OnInit, OnDestroy {

	storedConfigs: StoredConfigsWrapper;
	filtersABC: Filter[];

	queryParamSubscription: Subscription;

	groupByOptions$: Promise<GroupOption[]>;
	storedConfig: StoredConfig = null;

	stored_config_id = -1;
	panel_index = -1;
	panel: AnalysePanel = null;

	constructor(private util: UtilService, private route: ActivatedRoute, private configsService: ConfigsService, private msg: MessageService) {
	}

	ngOnInit(): void {
		this.filtersABC = this.route.snapshot.data['filters'] as Filter[];
		this.storedConfigs = this.route.snapshot.data['storedConfigs'] as StoredConfigsWrapper;

		this.initSubscribers();
	}

	initSubscribers() {
		this.queryParamSubscription = this.route.queryParamMap.subscribe(async params => {
			this.stored_config_id = +params.get('id');
			this.panel_index = +params.get('index') -1;

			// remove menu and topbar
			global.hideAll = true;
			this.setup();
		});
	}

	setup() {
		this.configsService.getPanelsForConfigId(this.stored_config_id).then(wrapper => {

			_.forEach(wrapper.config, (config, key) => {
				if (config !== null && config.type !== null && config.type !== PanelType.map) {
					config.resultData = wrapper.chartData[key];
				}
			});

			const configs = _.filter(wrapper.config, _.negate(_.isNull));
			const config = configs[this.panel_index];

			if(!config) {
				this.msg.add({
					key: 'dova-toast',
					summary: 'Niet gevonden',
					detail: 'Url is niet geldig',
					life: 5000
				})
				global.hideAll = false;
				return;
			}

			this.panel = _.assignIn(new AnalysePanel(), config);
		});
	}

	ngOnDestroy() {
		global.hideAll = false;
		this.queryParamSubscription.unsubscribe();
	}
}

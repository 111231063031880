<p-toast></p-toast>

<div class="p-grid p-m-1">
	<div class="p-col">


		<p-card header="Importeren">

			<p>Kies een bestand dat voldoet aan de richtlijnen.</p>

			<p-tabView>
				<p-tabPanel header="Uploaden bestand">
					<base-import-tab [imports$]="imports$"></base-import-tab>
				</p-tabPanel>
				<p-tabPanel header="Archief">
					<base-archive-tab></base-archive-tab>
				</p-tabPanel>
				<p-tabPanel header="Documentatie">
					<base-docs-tab></base-docs-tab>
				</p-tabPanel>
			</p-tabView>

		</p-card>

	</div>
</div>

<div *ngIf="panel.reload; else reloading" class="p-d-flex p-flex-column card-inner-body">

	<div class="p-field-checkbox p-mb-2 p-mt-1 p-unselectable-text" *ngIf="panel.resultData.labels?.length && !presentationMode">
		<p-checkbox inputId="showLabels" [(ngModel)]="panel.show_labels"
					(ngModelChange)="refreshChart()" [binary]="true">
		</p-checkbox>

		<label [for]="'showLabels'">Toon labels</label>

		<div class="download-map-icon p-ml-auto">
			<button (click)="copyRequest()" pTooltip="Kopieer afbeelding" tooltipPosition="left"
					class="p-button p-button-rounded p-button-info p-button-icon-only">
				<ibm-icon-copy size="20"></ibm-icon-copy>
			</button>
		</div>
	</div>

	<div class="chart-container" #chartContainer>
		<p-chart #chart
				 *ngIf="!presentationMode"
				 [id]="'p-'+panel.index"
				 [type]="panel.type"
				 [data]="panel.resultData"
				 [options]="panel.chartOptions"
				 [plugins]="[plugin]"
				 [responsive]="true"
				 height="100%">
		</p-chart>
		<p-chart #chart
				 *ngIf="presentationMode"
				 [id]="'p-'+panel.index"
				 [type]="panel.type"
				 [data]="panel.resultData"
				 [options]="panel.chartOptions"
				 [plugins]="[plugin]"
				 [responsive]="false"
				 width="650px"
				 height="450px">
		</p-chart>
	</div>

</div>

<ng-template #reloading>
	reloading..
</ng-template>
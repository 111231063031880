<div class="help-box">
	<div class="p-mb-3 p-text-bold">Verklaring gebruiker-acties:</div>

	<div class="p-d-flex p-flex-column p-text-light">
		<div class="p-d-flex p-align-center p-pb-2">
			<i class="fas fa-fw fa-check-circle text-success"></i>
			<span class="p-pl-2">De gebruiker heeft het bestand en het validatieresultaat goedgekeurd, de incidenten zijn geimporteerd en zichtbaar in de analyses.</span>
		</div>
		<div class="p-d-flex p-align-center p-pb-2">
			<i class="fas fa-fw fa-clock text-info"></i>
			<span class="p-pl-2">De gebruiker heeft een bestand geupload, maar nog niet gereageerd op het validatieresultaat.</span>
		</div>
		<div class="p-d-flex p-align-center p-pb-2">
			<i class="fas fa-fw fa-minus-circle text-warning"></i>
			<span class="p-pl-2">De gebruiker heeft het bestand verworpen. De incidenten zijn dus niet toegevoegd, en zijn niet zichtbaar in de analyses.</span>
		</div>
		<div class="p-d-flex p-align-center p-pb-2">
			<i class="fas fa-fw fa-exclamation-triangle text-danger"></i>
			<span class="p-pl-2">Er is een onbekende (server)fout opgetreden.</span>
		</div>
	</div>

</div>

<p-table #dt
	[value]="imports"
	[responsive]="true"
	[autoLayout]="true"
	[paginator]="true"
	[showCurrentPageReport]="true"
	[rows]="10"
	sortField="processed_at"
	[sortOrder]="-1"
	styleClass="p-datatable-dova">
	<ng-template pTemplate="header">
		<tr>
			<th style="width: 1px;"></th>
			<th pSortableColumn="filename" style="white-space: nowrap;">Bestand <p-sortIcon field="filename"></p-sortIcon></th>
			<th pSortableColumn="processed_at" style="white-space: nowrap;">Tijdstip <p-sortIcon field="processed_at"></p-sortIcon></th>
			<th pSortableColumn="user_email" style="white-space: nowrap;">Gebruiker <p-sortIcon field="user_email"></p-sortIcon></th>
			<th>
				<div class="p-d-flex p-align-center p-flex-nowrap">
					<span class="p-pr-1">Geimporteerd</span>
					<i class="fas fa-xs fa-info-circle"
					   pTooltip="Aantal goedgekeurde incidenten tijdens het validatieproces / Aantal regels in het geuploade bestand dat een incident bevat, die al dan niet goedgekeurd is."></i>
				</div>
			</th>
			<th>
				<div class="p-d-flex p-align-center p-flex-nowrap">
					<span class="p-pr-1">Afgekeurd</span>
					<i class="fas fa-xs fa-info-circle"
					   pTooltip="Aantal afgekeurde incidenten. Deze incidenten zijn afgekeurd op basis van inhoudelijke fouten (zoals niet bestaande buslijn) of syntactisch (bijvoorbeeld een tekstwaarde waar een getal werd verwacht). Het kan ook zijn dat een verplicht veld niet was ingevuld."></i>
				</div>
			</th>
			<th>
				<div class="p-d-flex p-align-center p-flex-nowrap">
					<span class="p-pr-1">Uniek</span>
					<i class="fas fa-xs fa-info-circle" pTooltip="Aantal unieke incidentnummers"></i>
				</div>
			</th>
			<th style="width: 1px;">
				<div class="p-d-flex p-align-center p-flex-nowrap">
					<span class="p-pr-1">Waarschuwingen</span>
					<i class="fas fa-xs fa-info-circle" pTooltip="Totaal aantal fouten of waarschuwingen. Per regel in het bestand kunnen er meerdere
			fouten of waarschuwingen worden gegeven."></i>
				</div>
			</th>
		</tr>
		<tr>
			<th></th>
			<th>
				<input pInputText type="text"
					   (input)="searchColumn($event.target, 'filename', 'startsWith')"
					   placeholder="Zoeken op bestand" class="p-column-filter p-inputtext-sm">
			</th>
			<th>
				<p-calendar
							placeholder="Zoeken op datum"
							dateFormat="dd-mm-yy"
							inputStyleClass="p-column-filter p-inputtext-sm"
							appendTo="body"
							[showButtonBar]="true"
							[readonlyInput]="true"
							(onClearClick)="searchColumnDate(null)"
							(onSelect)="searchColumnDate($event)"></p-calendar>

			</th>
			<th>
				<input pInputText type="text"
					   (input)="searchColumn($event.target, 'user_email', 'contains')"
					   placeholder="Zoeken op gebruiker" class="p-column-filter p-inputtext-sm">
			</th>
			<th></th>
			<th></th>
			<th></th>
			<th></th>
		</tr>
	</ng-template>
	<ng-template pTemplate="body" let-f>
		<tr>
			<td>
				<i class="fas fa-check-circle text-success"
				   [pTooltip]="translateUserAction(f.import_result?.user_action)"
				   *ngIf="f.import_result?.user_action == 'committed'"></i>
				<i class="fas fa-clock text-info" [pTooltip]="translateUserAction(f.import_result?.user_action)"
				   *ngIf="f.import_result?.user_action == 'pending'"></i>
				<i class="fas fa-minus-circle text-warning"
				   [pTooltip]="translateUserAction(f.import_result?.user_action)"
				   *ngIf="f.import_result?.user_action == 'canceled'"></i>
				<i class="fas fa-exclamation-triangle text-danger"
				   [pTooltip]="translateUserAction(f.import_result?.user_action)"
				   *ngIf="f.import_result?.user_action == 'error'"></i>
			</td>
			<td>
				<span class="">{{ f.filename }}</span>
				<span class="file-type" style="background-color: '#ad5f00'; color: 'white'" *ngIf="f.type == 'mk_incidents'">Politie</span>
			</td>
			<td class="">{{ f.processed_at | date : 'dd-MM-yyyy HH:mm' }}</td>
			<td>{{ f.user_email }}</td>
			<td>
				<span class=""
					  [ngClass]="{'text-success p-text-bold': f.import_result?.number_of_incidents_imported > 0}">{{ f.import_result?.number_of_incidents_imported }}</span>
				<span class="p-px-1">/</span>
				<span>{{ f.import_result?.number_of_incidents_in_file }}</span>
			</td>
			<td>
				<div class="p-d-flex p-align-center">
					<span
						[ngClass]="{'text-danger p-text-bold': f.import_result?.number_of_validation_skipped_rows > 0}">
						{{ f.import_result?.number_of_validation_skipped_rows }}
					</span>
				</div>
			</td>
			<td>
				<span class="">{{ f.import_result?.uniques }}</span>
			</td>
			<td>
				<button pButton pRipple
						[disabled]="f.msg_count == 0"
						style="width: 100%;"
						[label]="f.msg_count == 0 ? 'n.v.t.' : f.msg_count + ' bericht' + (f.msg_count == 1 ? '' : 'en')"
						class="p-button-sm p-button-warning p-text-nowrap"
						(click)="getDetail(f);"></button>
			</td>
		</tr>
	</ng-template>
</p-table>

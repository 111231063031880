<nav class="level is-mobile">
	<div class="level-left">
		<img src="assets/img/dova--icoon.svg">
		<a routerLink="/dashboard" class="level-title">DOVA<span>Concessies</span></a>
	</div>

	<div class="level-right" *ngIf="global.loginUser">
		<div class="level-item">
			<div class="dropdown is-right" [ngClass]="{'is-active':activeButton}">
				<div class="dropdown-trigger" (click)="activeButton = !activeButton;">
					<button class="button is-black" style="background-color: black;" aria-haspopup="true" aria-controls="dropdown-user">
						<span>{{global.loginUser.email}}</span>
						<ibm-icon-user-avatar-filled-alt size="24" class="icon" [style.fill]="'white'"></ibm-icon-user-avatar-filled-alt>
					</button>
				</div>
				<div class="dropdown-menu" id="dropdown-user" role="menu" >
					<div class="dropdown-content">
						<div class="dropdown-item">
							<article class="media">
								<figure class="media-left">
									<ibm-icon-user-avatar-filled-alt size="32" class="icon is-medium" [style.fill]="'black'"></ibm-icon-user-avatar-filled-alt>
								</figure>
								<div class="media-content">
									<div class="content">
										<p>
											<span class="title is-6">{{global.loginUser.name}}</span>
											<br/>
											<span class="subtitle is-7">{{global.loginUser.email}}</span>
										</p>
									</div>
								</div>
							</article>
						</div>

						<button class="dropdown-item button is-text has-text-centered" (click)="this.activeButton = false; passwordChange()">
							<span>Wachtwoord wijzigen</span>
						</button>
						<hr class="dropdown-divider">
						<button class="dropdown-item has-text-centered button is-info is-rounded has-text-weight-bold" (click)="logout()">
							<span class="">Uitloggen</span>
						</button>
					</div>
				</div>
			</div>

		</div>
	</div>
</nav>


import {Component, OnInit} from '@angular/core';
import {PasswordComponent} from "../password/password.component";
import {DialogService} from "primeng/dynamicdialog";
import {ConfirmationService, MessageService} from 'primeng/api';
import {ProfileComponent} from "../profile/profile.component";
import {SessionService} from "../../services/session.service";
import {TwofactorComponent} from "../twofactor/twofactor.component";
import _ from 'lodash';
import {TrustedDevice} from "../../classes/TrustedDevice";
import {UserService} from "../../services/user.service";
import DeviceDetector from "device-detector-js";
import {TrustedDeviceEditComponent} from "../trusted-device-edit/trusted-device-edit.component";

@Component({
	selector: 'base-settings',
	templateUrl: './settings.component.html',
	styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {

	has2fa = false;
	trustedDevices: TrustedDevice[] = [];

	constructor(private session: SessionService, private user: UserService, private dialogService: DialogService, private messageService: MessageService, private confirmationService: ConfirmationService) {
	}

	ngOnInit() {
		this.check2fa();

		this.user.getTrustedDevices().then(devices => {
			this.trustedDevices = _.orderBy(devices, 'timestamp', 'desc');
		});
	}

	check2fa() {
		this.session.is2faEnabled().then(value => {
			this.has2fa = value;
			this.has2fa = _.clone(this.has2fa); // trigger html
		})
	}

	getUser() {
		return this.session.getUser();
	}

	removeTrustedDevice(deviceId: number) {
		this.confirmationService.confirm({
			message: 'Wilt u dit apparaat verwijderen uit uw vertrouwde apparaten?',
			header: 'Apparaat verwijderen',
			icon: 'far fa-exclamation-triangle',
			acceptLabel: "Verwijder",
			acceptButtonStyleClass: "p-button-danger",
			acceptIcon: 'fa fa-trash-alt',
			rejectVisible: false,
			defaultFocus: 'none',
			accept: () => {
				this.user.deleteTrustedDevice(deviceId).then(() => {
					_.remove(this.trustedDevices, ['trusted_device_id', deviceId]);
					this.messageService.add({severity:'success', summary:'Geslaagd', detail:'Apparaat is verwijderd.'});
				});
			},
			reject: () => {
			}
		});
	}

	updateTrustedDeviceModal(device: TrustedDevice) {
		const ref = this.dialogService.open(TrustedDeviceEditComponent, {
			width: '36rem',
			modal: true,
			showHeader: true,
			dismissableMask: true,
			closeOnEscape: true,
			closable: true,
			header: 'Apparaat aanpassen',
			data: {
				device,
				update: (device: TrustedDevice) => {
					// replace device
					const index = _.findIndex(this.trustedDevices, ['trusted_device_id', device.trusted_device_id]);
					this.trustedDevices.splice(index, 1, device);
				}
			},
			baseZIndex: 1500
		});

		ref.onClose.subscribe(x => {
		});
	}

	twoFactorAuthModal(): void {
		const ref = this.dialogService.open(TwofactorComponent, {
			width: '36rem',
			modal: true,
			showHeader: true,
			dismissableMask: true,
			closeOnEscape: true,
			closable: true,
			header: '2FA beheren',
			data: {
				me: this.getUser()
			},
			baseZIndex: 1500
		});

		ref.onClose.subscribe(x => {
			this.check2fa();
		});

		ref.onDestroy.subscribe(x => {
			this.check2fa();
		});
	}

	userChangeModal(): void {
		this.user.updateProfileModal();
	}

	passwordChangeModal(): void {
		this.user.updatePasswordModal();
	}

	removeAccountConfirm(): void {
		this.confirmationService.confirm({
			message: `Indien u uw account en alle bijbehorende gegevens wilt laten verwijderen, kunt u een mail sturen naar <a href='mailto:ovdata@dova.nu?subject=Account verwijderen'>ovdata@dova.nu</a>.`,
			header: 'Account verwijderen',
			icon: 'far fa-info-circle',
			acceptLabel: "Sluit",
			acceptIcon: 'far fa-check',
			rejectVisible: false,
			defaultFocus: 'none',
			accept: () => {
			},
			reject: () => {
			}
		});
	}


}

import {Component, OnInit} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {DynamicDialogConfig} from "primeng/dynamicdialog";
import {MessageService} from "primeng/api";
import _ from "lodash";
import {UserService} from "../../services/user.service";
import {UserModel} from "../../classes/UserModel";
import {SessionService} from "../../services/session.service";

@Component({
	selector: 'base-profile',
	templateUrl: './profile.component.html',
	styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

	formGroup: FormGroup;
	pending: boolean = false;
	done: boolean = false;

	public get controls() {
		return this.formGroup.controls;
	}

	constructor(private fb: FormBuilder, public config: DynamicDialogConfig, public messageService: MessageService, private session: SessionService, private user: UserService) {
	}

	ngOnInit() {
		let user = this.config.data.me;

		this.formGroup = this.fb.group({
			name: [user.name, Validators.required],
			notes: [user.notes]
		});
	}

	hasError(control: AbstractControl, errorCode: string = null): boolean {

		// no interaction yet
		if(control.pristine || control.untouched) {
			return false;
		}

		// match specific errorCode
		if(errorCode !== null) {
			return control.hasError(errorCode);
		}

		// return true when some errorCode
		return !_.isNil(control.errors);
	}

	submit() {
		if (this.formGroup.invalid) {
			return;
		}

		this.pending = true;
		this.done = false;

		const userParam = {
			user_id: this.config.data.me.user_id,
			name: this.controls.name.value
		} as Partial<UserModel>;

		this.user.updateUser(userParam)
			.then(user => {

				_.delay(() => {
					this.done = true;

					_.delay(() => {
						this.session.setUser(user);
						this.pending = false;
					}, 1500);

				}, 500);


			});
	}
}

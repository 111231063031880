import {Component, OnInit, ViewChild} from '@angular/core';
import {SourceView, SourceViewWithMessages} from "../../../../types/sherpa";
import {IncidentsService} from "../../../../services/data/incidents.service";
import {ImportMessagesModalComponent} from "../import-messages-modal/import-messages-modal.component";
import _ from "lodash";
import {DialogService} from "primeng/dynamicdialog";
import {Table} from "primeng/table";
import {$e} from "codelyzer/angular/styles/chars";

@Component({
	selector: 'base-archive-tab',
	templateUrl: './archive-tab.component.html',
	styleUrls: ['./archive-tab.component.scss']
})
export class ArchiveTabComponent implements OnInit {

	imports: SourceView[] = [];
	@ViewChild('dt') table: Table;

	constructor(private incidentsService: IncidentsService, private dialogService: DialogService) {

	}

	ngOnInit(): void {
		this.incidentsService.getImports().then(result => {
			this.imports = result;

			_.forEach(this.imports, i => {
				// @ts-ignore
				i.processed_at_string = new Date(i.processed_at).toDateString();
			})
		});
	}

	searchColumn(target: any, field: string, mode: 'startsWith' | 'contains' | 'endsWith' | 'equals' | 'notEquals' | 'in' | 'lt' | 'lte' | 'gt' | 'gte') {
		this.table.filter(target.value, field, mode);
	}

	translateUserAction = (user_action: string) => {

		switch (user_action) {
			case 'pending' :
				return 'Wacht op actie gebruiker';
			case 'canceled' :
				return 'Verworpen';
			case 'committed' :
				return 'Geimporteerd';
			default:
				return "Onbekende (server)fout";
		}
	};

	getDetail(sourceView: SourceView, selectionMode: boolean = true) {
		this.incidentsService.getSourceMessages(sourceView.importsource_id)
			.then((result: SourceViewWithMessages) => {
				this.openModal(result);
			});
	}

	openModal(viewWithMessages: SourceViewWithMessages) {
		let ref = this.dialogService.open(ImportMessagesModalComponent, {
			width: '70%',
			showHeader: true,
			modal: true,
			header: viewWithMessages.source.filename,
			data: {
				messages: viewWithMessages.messages,
				sourceView: viewWithMessages.source,
				showActionButtons: false
			}
		});

		ref.onDestroy.subscribe((success: boolean) => {

		});

		ref.onClose.subscribe((sourceView: SourceView) => {
			if (_.isUndefined(sourceView)) {
				return; // abort action
			}
		});
	}

	searchColumnDate($event: Date) {
		if($event == null) {
			this.table.filter(null, 'processed_at_string', 'notEquals');
		}
		else {
			this.table.filter($event.toDateString(), 'processed_at_string', 'startsWith');
		}
	}
}

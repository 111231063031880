import {Injectable} from '@angular/core';
import {
	ActivatedRouteSnapshot,
	CanActivate,
	CanLoad,
	Route,
	RouterStateSnapshot,
	UrlSegment,
	UrlTree
} from "@angular/router";
import {Observable, of, Subscription} from "rxjs";
import {global} from 'src/globals';
import _ from 'lodash';
import {environment} from "../../../../environments/environment";
import {SessionService} from "../services/session.service";
import {SherpaService} from "../../../services/sherpa.service";
import {BackendFunctions} from "../../../classes/Sherpa";
import {SherpaJson} from "../../../classes/SherpaJson";
import {SherpaError} from "../../../classes/SherpaError";

export let authApi: BackendFunctions;
export let authJson: SherpaJson;

@Injectable({
	providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanLoad {

	constructor(private sessionService: SessionService, private sherpaService: SherpaService) {
	}

	// canLoad is used to prevent the entire module of app

	canLoad(route: Route, segments: UrlSegment[]): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {

		return this.sherpaService.setupSherpa(environment.authApi)
			.toPromise()
			.then(sherpaResult => {
				authApi = sherpaResult.api;
				authJson = sherpaResult.json;

				console.log('AuthApi is set');
				return true;
			})
			.catch(() => {
				console.error('AuthApi not set');
				return false;
			});
	}

	// canActivate is used to prevent an unauthorized user

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

		global.requestedUrl = state.url;
		global.loginModal = false;
		global.report = route.queryParams['hideAll'] || route.queryParams['asReport'];

		const tokenFromUrl = route.queryParams['login'] as string;
		if(!_.isNil(tokenFromUrl)) {
			this.sessionService.setToken(tokenFromUrl);
		}

		return this.sessionService.whoami()
			.then(user => {
				this.sessionService.setUser(user);
				this.sessionService.authSubject.next(true);
				return true;
			})
			.catch((error: SherpaError) => {
				console.error('Access denied:', error.message);
				this.sessionService.clear(false);
				global.requestedUrl = state.url;
				global.loginModal = true;
				this.sessionService.authSubject.next(false);
				return false;
			});
	}

}

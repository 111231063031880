import {Component, OnInit} from '@angular/core';
import _ from 'lodash';
import {Map as OLMap} from "ol";
import VectorLayer from "ol/layer/Vector";
import Select from "ol/interaction/Select";
import {MapService} from "../../../services/map.service";
import {StyleService} from "../../../services/style.service";
import {StyleFunction} from "ol/style/Style";
import {SelectItem} from "primeng/api";

@Component({
	selector: 'base-beheer',
	templateUrl: './beheer.component.html',
	styleUrls: ['./beheer.component.scss']
})
export class BeheerComponent implements OnInit {

	menu: SelectItem[] = [];
	chartData: any;
	data: any;

	// openlayers
	map: OLMap;
	layer: VectorLayer;
	selectClick: Select;

	constructor(public mapService: MapService, public styles: StyleService) {
	}

	ngAfterViewInit() {
		_.delay(() => {
			this.map = this.mapService.createMap('incidents-map');
			this.layer = this.mapService.getLayer(this.styles.concessionLineStyle as StyleFunction, 10);
			this.map.addLayer(this.layer);

			// this.selectClick.on('select', this.onFeatureSelect);
			//this.map.addInteraction(this.selectClick);

		}, 0);
	}

	ngOnInit(): void {
		this.menu.push({label: 'Autoriteiten', value: true});
		this.menu.push({label: 'Concessies', value: false});

		this.chartData = {
			labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
			datasets: [
				{
					label: 'My First dataset',
					backgroundColor: '#42A5F5',
					borderColor: '#1E88E5',
					data: [65, 59, 80, 81, 56, 55, 40]
				},
				{
					label: 'My Second dataset',
					backgroundColor: '#9CCC65',
					borderColor: '#7CB342',
					data: [28, 48, 40, 19, 86, 27, 90]
				}
			]
		}

		this.data = {
			datasets: [{
				data: [11,16,7,3,14],
				backgroundColor: [
					"#FF6384",
					"#4BC0C0",
					"#FFCE56",
					"#E7E9ED",
					"#36A2EB"
				],
				label: 'My dataset'
			}],
			labels: ["Red","Green","Yellow","Grey","Blue"]
		}

	}

	select(index: number) {
		_.forEach(this.menu, (item: SelectItem) => {
			item.value = false;
		});

		this.menu[index].value = true;
	}



}

<p-card styleClass="analyse-card" *ngIf="panel != null" [ngClass]="{'presentation': presentationMode}">

	<ng-template pTemplate="header" *ngIf="!presentationMode">
		<div *ngIf="showWizard && wizard == 1">1. Kies een visualisatie</div>
		<div *ngIf="showWizard && wizard == 2">2. Kies een weergave</div>

		<div *ngIf="!showWizard" style="width: 100%;">

			<div class="p-d-flex p-ai-center">
				<h2 class="p-m-0" *ngIf="panel.caption?.length;">{{panel.caption}}</h2>
				<span *ngIf="!panel.caption?.length;" style="color: #cfcfcf;">Visualisatie {{panel.index + 1}}</span>

				<span class="label" style="background-color: '#ad5f00'; color: 'white'" *ngIf="panel.table == 'mk_incident'">Politie</span>
			</div>

		</div>

		<button pButton pRipple icon="fal fa-lg fa-times"
				pTooltip="Verwijder" tooltipPosition="left"
				class="p-button-rounded p-button-text p-button-secondary p-ml-auto p-d-flex p-as-start"
				(click)="onClose.emit(panel);">
		</button>
	</ng-template>

	<div class="p-d-flex p-flex-column" [ngSwitch]="panel.type" *ngIf="!showWizard; else panelWizard" style="height: 100%;">

		<!-- PANEL TOOLBAR -->
		<ng-container *ngTemplateOutlet="!presentationMode && panelToolbar"></ng-container>

		<base-filters-topbar [initPanel]="panel"
							 [reloadSubject]="reloadPanelSubject"
							 [preview]="presentationMode"
							 [groupOptions$]="groupByOptions$"
							 [groupByOptionsPolice$]="groupByOptionsPolice$"
							 (onFilterFieldsChange)="onFilterFieldsChange($event)">
		</base-filters-topbar>

		<!-- TABLE VIEW -->
		<base-card-table *ngSwitchCase="'table'"
						 [panel]="panel" [presentationMode]="presentationMode" [reloadSubject]="reloadPanelSubject"
						 [groupByOptions$]="groupByOptions$"></base-card-table>

		<!-- OPENLAYERS-MAP VIEW -->
		<base-card-map *ngSwitchCase="'map'"
					   [panel]="panel" [presentationMode]="presentationMode" [reloadSubject]="reloadPanelSubject"></base-card-map>

		<!-- CHART VIEW -->
		<base-card-chart *ngSwitchCase="getChartCase(panel.type)"
						 [panel]="panel" [presentationMode]="presentationMode" [reloadSubject]="reloadPanelSubject"></base-card-chart>
	</div>

	<div class="p-pt-3" *ngIf="!presentationMode && !showWizard">
		<span *ngIf="!panel.description?.length; else panelDescription" style="color: #cfcfcf;">
			Geen beschrijving
		</span>
		<ng-template #panelDescription>
			<div class="content" [innerHTML]="asMarkdown(panel.description)"></div>
		</ng-template>
	</div>

</p-card>



<!-- TOOLBAR -->
<ng-template #panelToolbar>
	<div class="p-d-flex p-ai-center">
		<div>


		</div>
		<div class="p-d-flex p-ml-auto p-jc-end p-my-2">
			<p-menu #reorderMenu [popup]="true" [model]="reorderItems" appendTo="body"></p-menu>
			<button type="button" (click)="reorderMenu.toggle($event);" pTooltip="Volgorde" tooltipPosition="bottom"
					class="p-button p-button-icon-only p-button-rounded p-button-text p-button-secondary p-mr-1">
				<ibm-icon-watson-health-page-scroll size="20"></ibm-icon-watson-health-page-scroll>
			</button>

			<button type="button" (click)="presentEditInfo();" pTooltip="Teksten wijzigen" tooltipPosition="bottom"
					class="p-button p-button-icon-only p-button-rounded p-button-text p-button-secondary p-mr-1">
				<ibm-icon-watson-health-text-annotation-toggle size="20"></ibm-icon-watson-health-text-annotation-toggle>
			</button>

			<button type="button" (click)="overLay2.toggle($event);" pTooltip="Schalen" tooltipPosition="bottom"
					class="p-button p-button-icon-only p-button-rounded p-button-text p-button-secondary p-mr-1">
				<ibm-icon-scale size="20"></ibm-icon-scale>
			</button>

			<button type="button" (click)="overLay.toggle($event);" pTooltip="Weergave" tooltipPosition="bottom"
					class="p-button p-button-icon-only p-button-rounded p-button-text p-button-secondary p-mr-1">
				<ibm-icon-chart-multitype size="20"></ibm-icon-chart-multitype>
			</button>

			<button type="button" (click)="requestExportData();" pTooltip="CSV genereren" tooltipPosition="bottom"
					class="p-button p-button-icon-only p-button-rounded p-button-text p-button-secondary p-mr-1">
				<ibm-icon-document-export size="20"></ibm-icon-document-export>
			</button>


			<button type="button" (click)="onOpenFilters.emit(panel.index);"
					class="p-button p-button-rounded p-button-outlined p-button-text p-button-secondary p-ml-3">
				<ibm-icon-settings-adjust size="20"></ibm-icon-settings-adjust>
				<span class="p-pl-2">Configureer</span>
			</button>
		</div>
	</div>

</ng-template>

<!-- NEW PANEL WIZARD -->
<ng-template #panelWizard>
	<div class="p-d-flex p-flex-column" style="min-width: 200px; height: 100%">
		<div class="p-d-flex p-jc-center p-ai-center" style="height: 100%">
			<div class="p-d-flex p-flex-column p-jc-center p-ai-center" *ngIf="wizard == 1">
				<div class="p-d-flex p-flex-column">
					<button pButton pRipple label="Nieuwe visualisatie ABC-incidenten" icon="fal fa-plus"
							(click)="createPanelData('incident')">
					</button>

					<button pButton pRipple label="Nieuwe visualisatie politie-incidenten"
							class="p-mt-3 p-button-info"
							icon="fal fa-plus"
							(click)="createPanelData('mk_incident')">
					</button>
				</div>
				<div class="p-py-3 p-text-center p-text-italic p-text-light" *ngIf="panelDropdownOptions.length > 0">
					- of -
				</div>
				<div *ngIf="panelDropdownOptions.length > 0">
					<p-dropdown [options]="panelDropdownOptions" panelStyleClass="card-dropdown"
								placeholder="Kopieer van andere visualisatie"
								(onChange)="duplicatePanelData($event.value.index)" optionLabel="name">
					</p-dropdown>
				</div>
			</div>
			<div class="p-grid p-m-0" *ngIf="wizard == 2">
				<div class="p-col-4 p-p-1" *ngFor="let item of panelDisplayOptions">
					<div class="box icon-box" (click)="selectPanelDisplay(item);">
						<ng-container
							*ngTemplateOutlet="chartIcons; context: {type: item.type, stacked: item.stacked, size: 32}"></ng-container>
					</div>
				</div>
			</div>
		</div>
	</div>
</ng-template>

<!-- CHART TYPES ICONS -->
<ng-template #chartIcons let-type="type" let-stacked="stacked" let-size="size">
	<ibm-icon-chart-column [size]="size" *ngIf="type === 'bar' && !stacked"></ibm-icon-chart-column>
	<ibm-icon-chart-stacked [size]="size" *ngIf="type === 'bar' && stacked"></ibm-icon-chart-stacked>
	<ibm-icon-chart-bar [size]="size" *ngIf="type === 'horizontalBar' && !stacked"></ibm-icon-chart-bar>
	<ibm-icon-chart-bar-stacked [size]="size" *ngIf="type === 'horizontalBar' && stacked"></ibm-icon-chart-bar-stacked>
	<ibm-icon-chart-line-data [size]="size" *ngIf="type === 'line'"></ibm-icon-chart-line-data>
	<ibm-icon-diagram [size]="size" *ngIf="type === 'pie'"></ibm-icon-diagram>
	<ibm-icon-chart-radar [size]="size" *ngIf="type === 'radar'"></ibm-icon-chart-radar>
	<ibm-icon-cross-tab [size]="size" *ngIf="type === 'table'"></ibm-icon-cross-tab>
	<ibm-icon-map [size]="size" *ngIf="type === 'map'"></ibm-icon-map>
</ng-template>

<!-- EXPORT DIALOG -->
<p-dialog *ngIf="showDownloadDialog"
		  [header]="'Download incidenten'"
		  [(visible)]="showDownloadDialog"
		  [draggable]="false"
		  [resizable]="false"
		  [modal]="true"
		  [style]="{width: '50vw'}"
		  [baseZIndex]="1500">
	<base-link
		[action]="'Download'"
		[showLink]="false"
		[message]="'Klik om de incidenten te downloaden die gebruikt worden in deze grafiek, tabel of kaart.'"
		[url]="downloadUrl"></base-link>

	<ng-template pTemplate="footer">
		<div class="p-d-flex p-jc-end">

			<button pButton pRipple type="button" (click)="showDownloadDialog = false;"
					label="Sluiten"
					class="p-button-secondary p-button-text p-button-rounded p-mr-2"></button>
		</div>
	</ng-template>

</p-dialog>

<!-- TYPE -->
<p-overlayPanel #overLay appendTo="body" styleClass="no-arrow"
				[style]="{width: '190px'}"
				[showCloseIcon]=""
				[dismissable]="true">
	<ng-template pTemplate>
		<div class="p-grid">
			<div class="p-col-4 p-p-1" *ngFor="let item of panelDisplayOptions">
				<div class="box icon-box"
					 (mouseenter)="setChartToggleHelpText(panel, item)"
					 (mouseleave)="chartToggleHelpText = null"
					 (click)="selectPanelDisplay(item, true)"
					 [ngClass]="{'active':panel.type === item.type && panel.stacked === item.stacked}">
					<ng-container
						*ngTemplateOutlet="chartIcons; context: {type: item.type, stacked: item.stacked, size: 32}"></ng-container>
				</div>
			</div>
			<div class="p-col-12" *ngIf="chartToggleHelpText !== null">
				<p class="p-mb-0 p-unselectable-text">{{chartToggleHelpText}}</p>
			</div>
		</div>
	</ng-template>
</p-overlayPanel>

<!-- SIZE -->
<p-overlayPanel #overLay2 appendTo="body" styleClass="no-arrow"
				[style]="{width: '190px'}"
				[showCloseIcon]=""
				[dismissable]="true">
	<ng-template pTemplate>
		<div class="p-grid">
			<div class="p-col-4 p-p-1" *ngFor="let size of [6, 12, null]">
				<div class="box icon-box"
					 (mouseenter)="autoSizeHelpText = (size === null)"
					 (mouseleave)="autoSizeHelpText = false"
					 (click)="setHorizontalSize(size); overLay2.hide();"
					 [ngClass]="{'active':panel.size === size}">
					<ibm-icon-number-1 size="32" *ngIf="size === 6"></ibm-icon-number-1>
					<ibm-icon-number-2 size="32" *ngIf="size === 12"></ibm-icon-number-2>
					<div *ngIf="size === null">auto</div>
				</div>
			</div>
			<ng-container>
				<div class="p-col-6 p-p-1" *ngFor="let size of [1, 2]">
					<div class="box icon-box"
						 (click)="setVerticalSize(size); overLay2.hide();"
						 [ngClass]="{'active': panel.vertical_size === size}">
						<ibm-icon-row-collapse size="32" *ngIf="size === 1"></ibm-icon-row-collapse>
						<ibm-icon-row-expand size="32" *ngIf="size === 2"></ibm-icon-row-expand>
					</div>
				</div>
			</ng-container>
			<div class="p-col-12" *ngIf="autoSizeHelpText">
				<p class="p-mb-0 p-unselectable-text">Vult automatisch uit</p>
			</div>
		</div>
	</ng-template>
</p-overlayPanel>

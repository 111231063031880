import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {Injector, NgModule} from '@angular/core';
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {CommonModule} from "@angular/common";
import {app, setInjector} from "../services";
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {PageNotFoundComponent} from './components/common/page-not-found/page-not-found.component';
import {DashboardComponent} from './components/pages/dashboard/dashboard.component';
import {LinkComponent} from './components/modals/link/link.component';
import {TopbarComponent} from './components/common/topbar/topbar.component';
import {CopyComponent} from './components/modals/copy/copy.component';
import {BeheerComponent} from './components/pages/beheer/beheer.component';
import {
	AddAltModule, CaretDownModule,
	CaretLeftModule,
	CaretRightModule, CaretUpModule,
	ChartBarModule,
	ChartBarStackedModule,
	ChartColumnModule,
	ChartLineDataModule,
	ChartMultitypeModule,
	ChartPieModule,
	ChartRadarModule,
	ChartStackedModule,
	CheckboxCheckedFilledModule,
	CheckboxCheckedModule,
	CheckboxModule as CheckboxIconModule,
	ChevronDownModule,
	ChevronUpModule,
	CloseModule,
	CloseOutlineModule,
	ColumnModule, CopyModule,
	CrossTabModule,
	DeleteModule,
	DiagramModule, DocumentExportModule, DownloadModule,
	EditModule, ErrorModule, ExportModule, InformationModule,
	LaunchModule,
	LinkModule,
	LockedModule,
	MapModule,
	MaximizeModule,
	MenuModule as IconMenuModule,
	MisuseOutlineModule,
	Number_1Module,
	Number_2Module,
	RenewModule,
	RowCollapseModule,
	RowExpandModule,
	RowModule,
	ScaleModule,
	SearchModule,
	SettingsAdjustModule, TrashCanModule,
	UnlinkModule,
	UserAvatarFilledAltModule,
	WarningAltModule, WatsonHealthPageScrollModule, WatsonHealthTextAnnotationToggleModule
} from "@carbon/icons-angular";
import {AppLoaderComponent} from './components/common/app-loader/app-loader.component';
import {LoaderInterceptorService} from "./services/interceptors/loader-interceptor.service";
import {CdkVirtualScrollViewport} from "@angular/cdk/scrolling";
import {ImportsComponent} from './components/pages/admin/imports/imports.component';
import {ImportTabComponent} from './components/pages/admin/import-tab/import-tab.component';
import {RippleModule} from "primeng/ripple";
import {TableModule} from "primeng/table";
import {FileUploadModule} from "primeng/fileupload";
import {ToastModule} from "primeng/toast";
import {CardModule} from "primeng/card";
import {TabViewModule} from "primeng/tabview";
import {MessageModule} from "primeng/message";
import {InputTextModule} from "primeng/inputtext";
import {PasswordModule} from "primeng/password";
import {DropdownModule} from "primeng/dropdown";
import {ChipsModule} from "primeng/chips";
import {ConfirmationService, MessageService} from "primeng/api";
import {DialogService} from "primeng/dynamicdialog";
import {ChartModule} from "primeng/chart";
import {MenuModule} from "primeng/menu";
import {MenubarModule} from "primeng/menubar";
import {ProgressSpinnerModule} from "primeng/progressspinner";
import {DialogModule} from "primeng/dialog";
import {PanelModule} from "primeng/panel";
import {ImportMessagesModalComponent} from './components/pages/admin/import-messages-modal/import-messages-modal.component';
import {SidebarModule} from "primeng/sidebar";
import {TreeModule} from "primeng/tree";
import {DocsTabComponent} from './components/pages/admin/docs-tab/docs-tab.component';
import {FilterMultipleComponent} from './components/common/filter-multiple/filter-multiple.component';
import {FilterTreeComponent} from './components/common/filter-tree/filter-tree.component';
import {AccordionModule} from "primeng/accordion";
import {ToolbarModule} from "primeng/toolbar";
import {FilterSingleComponent} from './components/common/filter-single/filter-single.component';
import {RadioButtonModule} from "primeng/radiobutton";
import {TooltipModule} from "primeng/tooltip";
import {CheckboxModule} from "primeng/checkbox";
import {PanelMenuModule} from "primeng/panelmenu";
import {OverlayPanelModule} from "primeng/overlaypanel";
import {MyFilterComponent} from './components/modals/my-filter/my-filter.component';
import {IncidentsComponent} from './components/pages/incidents/incidents.component';
import {ConfirmDialogModule} from "primeng/confirmdialog";
import {FiltersResolver} from "./resolvers/filters.resolver";
import {GroupOptionsResolver} from "./resolvers/groupOptions.resolver";
import {StoredConfigsResolver} from "./resolvers/storedConfigs.resolver";
import {ToggleButtonModule} from "primeng/togglebutton";
import {FilterDateComponent} from './components/common/filter-date/filter-date.component';
import {CalendarModule} from "primeng/calendar";
import {FilterExtentComponent} from './components/common/filter-extent/filter-extent.component';
import {InputSwitchModule} from "primeng/inputswitch";
import {SelectButtonModule} from "primeng/selectbutton";
import {IncidentTableComponent} from './components/common/incident-table/incident-table.component';
import {ArchiveTabComponent} from './components/pages/admin/archive-tab/archive-tab.component';
import {InplaceModule} from "primeng/inplace";
import {MultiSelectModule} from "primeng/multiselect";
import {ScrollPanelModule} from "primeng/scrollpanel";
import {IncidentsOverviewComponent} from './components/pages/incidents-overview/incidents-overview.component';
import {ReportsComponent} from './components/pages/reports/reports.component';
import {FiltersSidebarComponent} from './components/common/filters-sidebar/filters-sidebar.component';
import {SplitButtonModule} from "primeng/splitbutton";
import {FilterBasicComponent} from './components/common/filter-basic/filter-basic.component';
import {FilterGroupbyComponent} from './components/common/filter-groupby/filter-groupby.component';
import {EditFilterMultipleComponent} from './components/common/edit-filter-multiple/edit-filter-multiple.component';
import {TriStateCheckboxModule} from "primeng/tristatecheckbox";
import {FilterPipe} from './pipes/filter.pipe';
import {FiltersTopbarComponent} from './components/common/filters-topbar/filters-topbar.component';
import {FilterComparisonComponent} from './components/common/filter-comparison/filter-comparison.component';
import {EditFilterExtentComponent} from './components/common/edit-filter-extent/edit-filter-extent.component';
import {IncidentDetailComponent} from './components/common/incident-detail/incident-detail.component';
import {MyDefaultFiltersComponent} from './components/modals/my-default-filters/my-default-filters.component';
import {InputTextareaModule} from "primeng/inputtextarea";
import {AuthModule} from "./modules/auth/auth.module";
import {LoginComponent} from "./modules/auth/components/login/login.component";
import {MessagesModule} from "primeng/messages";
import { CardHeaderComponent } from './components/cards/card-header/card-header.component';
import { CardMapComponent } from './components/cards/card-map/card-map.component';
import { CardChartComponent } from './components/cards/card-chart/card-chart.component';
import { CardTableComponent } from './components/cards/card-table/card-table.component';
import { CardComponent } from './components/cards/card/card.component';
import { CardFooterComponent } from './components/cards/card-footer/card-footer.component';
import { EditPanelInfoComponent } from './components/modals/edit-panel-info/edit-panel-info.component';
import { CardViewComponent } from './components/pages/card-view/card-view.component';
import {InputNumberModule} from "primeng/inputnumber";
import { EditPanelOrderComponent } from './components/modals/edit-panel-order/edit-panel-order.component';
import {FiltersPoliceResolver} from "./resolvers/filtersPolice.resolver";


@NgModule({
	declarations: [
		AppComponent,
		PageNotFoundComponent,
		DashboardComponent,
		LinkComponent,
		TopbarComponent,
		CopyComponent,
		BeheerComponent,
		AppLoaderComponent,
		IncidentsComponent,
		ImportsComponent,
		ImportTabComponent,
		ImportMessagesModalComponent,
		DocsTabComponent,
		FilterMultipleComponent,
		FilterTreeComponent,
		FilterSingleComponent,
		MyFilterComponent,
		FilterDateComponent,
		FilterExtentComponent,
		IncidentTableComponent,
		ArchiveTabComponent,
		IncidentsOverviewComponent,
		ReportsComponent,
		FiltersSidebarComponent,
		FilterBasicComponent,
		FilterGroupbyComponent,
		EditFilterMultipleComponent,
		FilterPipe,
		FiltersTopbarComponent,
		FilterComparisonComponent,
		EditFilterExtentComponent,
		IncidentDetailComponent,
		MyDefaultFiltersComponent,
		CardHeaderComponent,
		CardMapComponent,
		CardChartComponent,
		CardTableComponent,
		CardComponent,
		CardFooterComponent,
		EditPanelInfoComponent,
		CardViewComponent,
		EditPanelOrderComponent,
	],
	imports: [
		AppRoutingModule,
		AuthModule,
		CommonModule,
		BrowserModule,
		BrowserAnimationsModule,
		ReactiveFormsModule,
		HttpClientModule,
		TableModule,
		FormsModule,
		DropdownModule,
		InputTextModule,
		ChipsModule,
		ChartModule,
		ToastModule,
		UserAvatarFilledAltModule,
		CloseModule,
		MenuModule,
		AddAltModule,
		DeleteModule,
		ChevronUpModule,
		ChevronDownModule,
		UnlinkModule,
		CheckboxIconModule,
		CheckboxCheckedFilledModule,
		CheckboxCheckedModule,
		MaximizeModule,
		LaunchModule,
		CardModule,
		LinkModule,
		RippleModule,
		TabViewModule,
		PasswordModule,
		FileUploadModule,
		MessageModule,
		TableModule,
		FileUploadModule,
		ToastModule,
		CardModule,
		TabViewModule,
		MessageModule,
		InputTextModule,
		PasswordModule,
		DropdownModule,
		ChipsModule,
		MenubarModule,
		ProgressSpinnerModule,
		DialogModule,
		PanelModule,
		SidebarModule,
		TreeModule,
		AccordionModule,
		ToolbarModule,
		RadioButtonModule,
		TooltipModule,
		CheckboxModule,
		PanelMenuModule,
		OverlayPanelModule,
		ConfirmDialogModule,
		ToggleButtonModule,
		CalendarModule,
		InputSwitchModule,
		SelectButtonModule,
		InplaceModule,
		MultiSelectModule,
		IconMenuModule,
		EditModule,
		ScrollPanelModule,
		ChartColumnModule,
		ChartLineDataModule,
		ChartPieModule,
		ChartRadarModule,
		CrossTabModule,
		DiagramModule,
		RowModule,
		ColumnModule,
		LockedModule,
		SplitButtonModule,
		MisuseOutlineModule,
		CloseOutlineModule,
		TriStateCheckboxModule,
		ChartStackedModule,
		ChartMultitypeModule,
		ChartBarModule,
		ChartBarStackedModule,
		MapModule,
		SettingsAdjustModule,
		ScaleModule,
		Number_1Module,
		Number_2Module,
		SearchModule,
		CaretLeftModule,
		CaretRightModule,
		RenewModule,
		WarningAltModule,
		RowCollapseModule,
		RowExpandModule,
		ErrorModule,
		TrashCanModule,
		DownloadModule,
		ExportModule,
		DocumentExportModule,
		InputTextareaModule,
		MessagesModule,
		CaretDownModule,
		CaretUpModule,
		InformationModule,
		CopyModule,
		WatsonHealthPageScrollModule,
		WatsonHealthTextAnnotationToggleModule,
		InputNumberModule
	],
	providers: [
		CdkVirtualScrollViewport,
		MessageService, DialogService, ConfirmationService,
		FiltersResolver, FiltersPoliceResolver, GroupOptionsResolver, StoredConfigsResolver,
		{provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptorService, multi: true}
	],
	bootstrap: [AppComponent],
	entryComponents: [
		CopyComponent,
		LinkComponent,
		ImportMessagesModalComponent,
		LoginComponent,
		EditPanelInfoComponent
	]
})

export class AppModule {
	constructor(_injector: Injector) {
		//Set the Injector to be able to open modal in static functions
		if (app) {
			console.error('Programming error: AppInjector was already set');
		} else {
			setInjector(_injector);
		}
	}
}

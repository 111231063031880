import {Component, ElementRef, HostListener, OnInit} from '@angular/core';
import {global} from 'src/globals';
import {Globals} from "../../../classes/Globals";
import _ from 'lodash';
import {Router} from "@angular/router";
import {SessionService} from "../../../modules/auth/services/session.service";
import {UserService} from "../../../modules/auth/services/user.service";

@Component({
	selector: 'main-topbar',
	templateUrl: './topbar.component.html',
	styleUrls: ['./topbar.component.scss']
})
export class TopbarComponent implements OnInit {

	global: Globals = global;
	activeButton: boolean = false;

	constructor(private router: Router, private elementRef: ElementRef, public session: SessionService, private user: UserService) {
	}

	@HostListener('document:click', ['$event'])
	@HostListener('document:touchstart', ['$event'])
	handleOutsideClick(event: Event) {
		if (!this.elementRef.nativeElement.contains(event.target)) {
			this.activeButton = false;
		}
	}

	ngOnInit() {
	}

	account() {
	}

	passwordChange() {
		this.user.updatePasswordModal();
	}

	logout() {
		return this.session.logout()
			.then(() => {
				this.session.clear(false);
				this.session.loginModal('/')
			});
	}

}

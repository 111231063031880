import {Component, OnInit} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {SherpaError} from "../../../../classes/SherpaError";
import {global} from 'src/globals';
import {Router} from "@angular/router";
import _ from "lodash";
import {MessageService} from "primeng/api";
import {SessionService} from "../../services/session.service";
import {LoginCredentials} from "../../classes/LoginCredentials";
import {UserService} from "../../services/user.service";

@Component({
	selector: 'base-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

	form: FormGroup;
	signing: boolean = false;
	passwordInvalid: boolean = false;
	trustedToken: string = null;

	get controls() {
		return this.form.controls;
	}

	constructor(private fb: FormBuilder, public router: Router, public sessionService: SessionService, private msg : MessageService, private user: UserService) {
	}

	ngOnInit() {
		this.form = this.fb.group({
			username: [null, [Validators.required, Validators.email]],
			password: [null, Validators.required],
			twofa: [null]
		});

		this.trustedToken = this.sessionService.getTrustedToken();
	}

	submit() {
		if (this.form.invalid) {
			_.forEach(this.controls, control => {
				control.markAsDirty();
				control.markAsTouched();
			});
			return;
		}

		let credentials: LoginCredentials = {
			username: this.controls.username.value,
			password: this.controls.password.value
		};

		this.passwordInvalid = false;
		this.signing = true;

		const twofa = this.controls.twofa.value

		// no page loader for login modal
		global.disableLoader();

		if((twofa == null || twofa.length == 0) && this.sessionService.getTrustedToken() == null) {
			this._login(credentials);
		}
		else {
			this._loginWith2FA(credentials, twofa);
		}
	}

	private _login(c: LoginCredentials) {
		// store
		const requestedUrl = global.requestedUrl;

		this.sessionService.login(c)
			.then((loginResult) => {

				if(_.isNil(requestedUrl)) {
					return;
				}

				let url = '/';
				if (!!requestedUrl && requestedUrl.length) {
					url = requestedUrl;
				}

				this.router.navigateByUrl(url).then(r => console.log('redirected to', url));

			})
			.catch((error: SherpaError) => {
				this.msg.add({key: 'dova-toast', severity:'info', summary:'Ongeldige login', detail: error.message});
			})
			.finally(() => {
				this.signing = false;
				global.enableLoader();
			});
	}

	private _loginWith2FA(c: LoginCredentials, code: string) {
		// store
		const requestedUrl = global.requestedUrl;

		this.sessionService.loginWith2FA(c, code)
			.then((loginResult) => {

				if(_.isNil(requestedUrl)) {
					return;
				}

				let url = '/';
				if (!!requestedUrl && requestedUrl.length) {
					url = requestedUrl;
				}

				this.router.navigateByUrl(url).then(r => console.log('redirected to', url));

			})
			.catch((error: SherpaError) => {
				this.msg.add({key: 'dova-toast', severity:'info', summary:'Ongeldige login', detail: error.message});
				this.trustedToken = this.sessionService.getTrustedToken();
			})
			.finally(() => {
				this.signing = false;
				global.enableLoader();
			});
	}

	hasError(control: AbstractControl, errorCode: string = null): boolean {

		// no interaction yet
		if(control.pristine || control.untouched) {
			return false;
		}

		// match specific errorCode
		if(errorCode !== null) {
			return control.hasError(errorCode);
		}

		// return true when some errorCode
		return !_.isNil(control.errors);
	}

	resetPassword() {
		this.user.resetPasswordModal();
	}

}
import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthGuard} from "./modules/auth/guards/auth.guard";

const routes: Routes = [
	{
		path: 'auth',
		canLoad: [AuthGuard],
		canActivate: [AuthGuard],
		loadChildren: () => import('./modules/auth/auth.module').then(m => m.AuthModule)
	}, {
		path: '',
		canLoad: [AuthGuard],
		canActivate: [AuthGuard],
		loadChildren: () => import('./modules/main/main.module').then(m => m.MainModule)
	}
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes)
	],
	exports: [RouterModule],
	providers: []
})
export class AppRoutingModule {
}

<form [formGroup]="formGroup" (ngSubmit)="submit();" class="">

	<ng-container>

		<p class="p-mt-0 p-mb-4">
			Beheer hier uw gegevens. Deze gegevens zijn ook zichtbaar voor admins.
		</p>

		<div class="p-fluid">

			<div class="p-field">
				<label for="name" class="p-text-normal">Uw naam</label>

				<input id="name" pInputText autofocus formControlName="name"
					   [ngClass]="{'p-invalid': hasError(controls.name)}"/>

				<small id="name-help" class="p-invalid" *ngIf="hasError(controls.name, 'required')">
					Naam is verplicht.
				</small>
			</div>

			<div class="p-d-flex p-ai-center p-mt-5">
				<div class="p-text-light" *ngIf="pending && !done">Bezig met opslaan..</div>
				<div class="p-text-light" *ngIf="pending && done">Opgeslagen!</div>

				<div class="p-ml-auto">
					<button pButton pRipple type="submit"
							label="Opslaan"
							[disabled]="pending"
							class="p-button-primary p-text-uppercase">
					</button>
				</div>
			</div>
		</div>

		<p-table></p-table>

	</ng-container>

</form>

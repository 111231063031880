<h2>1. Kies een bestand en upload.</h2>
<p-fileUpload
	name="importfile"
	url="/rest/upload"
	chooseLabel="Kies een bestand"
	uploadLabel="Start upload"
	cancelLabel="Annuleer bestand"
	[showCancelButton]="true"
	[previewWidth]="0"
	[headers]="headers"
	(onUpload)="onUpload($event)">
	<ng-template let-file pTemplate="file">
		<p-table [responsive]="true" [value]="[file]">
			<ng-template pTemplate="body" let-item>
				<tr>
					<td colspan="2" class="p-text-nowrap p-text-truncate">{{item.name}}</td>
					<td>{{format(item.size)}}</td>
					<td colspan="2">
						<span class="p-tag p-tag-info p-text-uppercase">Klaar om te uploaden</span>
					</td>
				</tr>
			</ng-template>
		</p-table>
	</ng-template>
	<ng-template pTemplate="content">

	</ng-template>
</p-fileUpload>

<h2 class="p-mt-5">2. Bestanden worden gevalideerd.</h2>
<p-panel [showHeader]="false">
	<p-table [responsive]="true" [value]="validatingImports">
		<ng-template pTemplate="body" let-item>
			<tr>
				<td colspan="2" class="p-text-nowrap p-text-truncate">{{item.filename}}</td>
				<td>{{format(item.filesize)}}</td>
				<td>
					<span class="p-tag p-text-uppercase p-tag-warning">
						{{translateStatus(item.status)}}
					</span>
				</td>
				<td class="p-text-right">
					<p-progressSpinner [style]="{width: '1rem', height: '1rem'}" strokeWidth="6" fill="#EEEEEE"
									   animationDuration=".5s"></p-progressSpinner>
				</td>
			</tr>
		</ng-template>
		<ng-template pTemplate="emptymessage">
			<tr>
				<td colspan="6"><i>Er worden op dit moment geen bestanden gevalideerd.</i></td>
			</tr>
		</ng-template>
	</p-table>
</p-panel>

<h2 class="p-mt-5">3. Gevalideerde bestanden die een vervolgactie nodig hebben.</h2>
<p-panel [showHeader]="false">
	<p-table [responsive]="true" [autoLayout]="true" [value]="pendingImports" sortField="processed_at" sortMode="single"
			 [sortOrder]="-1">
		<ng-template pTemplate="body" let-item>
			<tr>
				<td colspan="2" class="p-text-nowrap p-text-truncate">{{item.filename}}</td>
				<td>{{format(item.filesize)}}</td>
				<td>
					<span class="p-tag p-text-uppercase p-tag-success">{{translateStatus(item.status)}}</span>
				</td>
				<td>
					<div class="p-d-flex">
						<span class="p-overlay-badge p-mr-4">
							<button pButton pRipple label="Validatie-resultaat"
									class="p-button-sm p-button-warning"
									(click)="getDetail(item);"></button>
							<span class="p-badge p-badge-danger">{{item.msg_count}}</span>
						</span>
						<div class="p-mr-2" *ngIf="item.import_result.non_uniques > 0">
							<button pButton pRipple class="p-button-outlined p-button-sm"
									[label]="'Overschrijf '+item.import_result.non_uniques+' duplicaten'"
									(click)="commitImport(item, true)"></button>
						</div>
						<div class="p-mr-2" *ngIf="item.import_result.non_uniques > 0">
							<button pButton pRipple class="p-button-outlined p-button-sm"
									[label]="'Negeer '+item.import_result.non_uniques+ ' duplicaten'"
									(click)="commitImport(item, false)"></button>
						</div>
						<div class="p-mr-2" *ngIf="item.import_result.non_uniques === 0">
							<button pButton pRipple class="p-button-outlined p-button-sm"
									[label]="'Importeer '+item.import_result.uniques+ ' incidenten'"
									(click)="commitImport(item, false)"></button>
						</div>
						<div class="p-mr-2 p-ml-auto">
							<button pButton pRipple class="p-button-danger p-button-text p-button-rounded p-button-sm" icon="fa fa-trash-alt"
									(click)="cancelImport(item)"></button>
						</div>
					</div>

				</td>
			</tr>
		</ng-template>
		<ng-template pTemplate="emptymessage">
			<tr>
				<td colspan="6"><i>Er zijn geen vervolgacties gevonden.</i></td>
			</tr>
		</ng-template>
	</p-table>
</p-panel>
<h2 class="p-mt-5">4. Gevalideerde bestanden die momenteel worden verwerkt.</h2>
<p-panel [showHeader]="false">
	<p-table [responsive]="true"
			 [autoLayout]="true"
			 [value]="committingImports" sortField="processed_at" sortMode="single"
			 [sortOrder]="-1">
		<ng-template pTemplate="body" let-item>
			<tr>
				<td colspan="2" class="p-text-nowrap p-text-truncate">{{item.filename}}</td>
				<td>{{format(item.filesize)}}</td>
				<td>
					<span class="p-tag p-text-uppercase p-tag-success">{{translateStatus(item.status)}}</span>
				</td>
				<td>
					<p-progressSpinner [style]="{width: '1rem', height: '1rem'}" strokeWidth="6" fill="#EEEEEE"
									   animationDuration=".5s"></p-progressSpinner>
				</td>
			</tr>
		</ng-template>
		<ng-template pTemplate="emptymessage">
			<tr *ngIf="finishedUploadedFile !== null">
				<td colspan="2" class="p-text-nowrap p-text-truncate">{{finishedUploadedFile.filename}}</td>
				<td>{{format(finishedUploadedFile.filesize)}}</td>
				<td>
					<span class="p-tag p-text-uppercase p-tag-success">Klaar</span>
				</td>
			</tr>
			<tr >
				<td colspan="6"><i>Klaar. Er zijn geen gevalideerde bestanden die momenteel worden verwerkt of nog moeten worden verwerkt.</i></td>
			</tr>
		</ng-template>
	</p-table>
</p-panel>
<form [formGroup]="form" (ngSubmit)="submit();" class="dovaForm">
	<div class="p-fluid">

		<div class="p-field">
			<label for="caption" class="p-text-normal">Titel</label>
			<input id="caption" pInputText autofocus type="text" formControlName="caption" />
		</div>

		<div class="p-field">
			<label for="descr" class="p-text-normal">Beschrijving</label>
			<textarea id="descr" pInputTextarea formControlName="description"
					  [style]="{'width': '100%'}"
					  [rows]="8"
					  [autoResize]="true"></textarea>
			<small id="username-help">Gebruik 'Markdown' voor eventuele opmaak</small>

		</div>

	</div>

	<div class="p-grid p-formgrid p-mt-5">
		<div class="p-col-12 p-text-right">
			<button pButton pRipple type="button" label="Annuleren"
					class="p-button-secondary p-button-text p-button-rounded p-mr-2"
					(click)="ref.destroy()"></button>
			<button pButton pRipple type="submit" label="Ok"
					class="p-button-rounded p-text-uppercase">
			</button>
		</div>
	</div>

</form>
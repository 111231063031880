import {Injectable} from '@angular/core';
import {SherpaError} from "../classes/SherpaError";
import {LinkComponent} from "../components/modals/link/link.component";
import {CopyComponent} from "../components/modals/copy/copy.component";
import {HttpErrorResponse} from "@angular/common/http";
import {BehaviorSubject, Subject} from "rxjs";
import _ from "lodash";
import {MenuItem, MessageService} from "primeng/api";
import {DialogService} from "primeng/dynamicdialog";
import {SessionService} from "../modules/auth/services/session.service";

@Injectable({
	providedIn: 'root'
})
export class UtilService {

	isLoading = new BehaviorSubject(false);
	globalChanged = new Subject<void>();
	menuChanged = new Subject<void>();

	constructor(public messageService: MessageService, public dialogService: DialogService, private sessionService: SessionService) {}

	// common error handler for showing error message
	public handleError(error: SherpaError|HttpErrorResponse) {
		if(error instanceof SherpaError) {
			if(error.message === 'Bad token') {
				this.sessionService.clear(false);
				this.sessionService.loginModal(null);
			}
			else {
				this.alert(error);
			}
		}
		else if(error instanceof HttpErrorResponse) {
			this.toast(error.message, error.name, 'error', true);
		}
		else {
			this.toast(`Onbekende fout opgetreden`, `${error}`, 'error', true);
		}

		return Promise.reject(error);
	}

	// update or insert an element in an array
	public upsert(array: any[], target: any, key: string): any[] {
		const index = _.findIndex(array, [key, target[key]]);

		if (index > -1) {
			array[index] = target;
		}
		else {
			array.push(target);
		}

		return array;
	}

	// remove element from array
	remove(array: any[], unique_value: any, key: string): any[] {
		const item = _.find(array, [key, unique_value]);
		if(_.isUndefined(item)) {
			return array;
		}

		return _.without(array, item);
	}

	public alert(error: SherpaError): void {
		this.toast(error.message, null, 'error', true);
	}

	public toast(message: string, title: string|null, severity: 'error' | 'info' | 'warning' | 'success', sticky: boolean = false): void {

		let preFix = 'Bericht';
		switch (severity) {
			case "error": preFix = 'Fout opgetreden'; break;
			case "info": preFix = 'Informatie'; break;
			case "warning": preFix = 'Let op'; break;
			case "success": preFix = 'Actie geslaagd'; break;
		}

		let summary = title?.length ? `${preFix} - ${title}` : preFix;

		this.messageService.add({
			key: 'dova-toast',
			sticky: sticky,
			closable: true, // true, because custom close will close all..
			severity: severity,
			summary: summary,
			detail: message
		});
	}

	link(url: string, buttonLabel: string, message: string): void {
		console.log('disabled through service');
		// this.dialogService.open(LinkComponent, {
		// 	width: '70%',
		// 	showHeader: true,
		// 	header: 'Open link',
		// 	data: {
		// 		message: message,
		// 		path: url,
		// 		action: buttonLabel
		// 	}
		// });
	}

	copy(url: string, header = 'Kopieren'): void {
		this.dialogService.open(CopyComponent, {
			width: '36rem',
			modal: true,
			showHeader: true,
			dismissableMask: true,
			closeOnEscape: true,
			closable: true,
			header: header,
			data: {
				path: url
			}
		});
	}

	formatBytes(bytes: number, decimals: number = 2) {
		if (bytes === 0) {
			return '0 Bytes';
		}

		const k = 1024;
		const dm = decimals < 0 ? 0 : decimals;
		const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

		const i = Math.floor(Math.log(bytes) / Math.log(k));

		return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
	}

	readFile(file: any[]) {
		return new Promise((resolve, reject) => {
			if (file.length !== 1) {
				reject('Bad input type=file');
			}
			let files = file[0].files;
			if (files.length !== 1) {
				reject('Need exactly 1 file');
			}

			let currentFile: File = files[0];
			let reader: FileReader = new FileReader();

			reader.onloadend = (e) => {
				resolve(e);
			};
			reader.onerror = (e) => {
				reject('Error reading file');
			};

			reader.readAsDataURL(currentFile);
		});
	}

	copyCanvas(canvas: HTMLCanvasElement) {

		// SAFARI -> but not showing the features
		//
		// // @ts-ignore
		// navigator.clipboard.write([
		// 	// @ts-ignore
		// 	new ClipboardItem({
		// 		"image/png": new Promise(async (resolve) => {
		// 			canvas.toBlob(resolve);
		// 		})
		// 	})
		// ]);


		canvas.toBlob(blob => {
			// @ts-ignore
			navigator.clipboard.write([new ClipboardItem({["image/png"]: blob })])
				.then(() => {
					this.messageService.add({
						key: 'dova-toast',
						severity: 'info',
						detail: 'Gekopieerd naar klembord',
						closable: false,
						life: 3000
					});
				})
				.catch((err: any) => {
					this.messageService.add({
						key: 'dova-toast',
						severity: 'error',
						detail: 'Fout bij kopieren',
						closable: false
					});

					console.error(err.name, err.message);
				});
		});
	}
}

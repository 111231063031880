
<div *ngIf="panel.reload; else reloading" class="p-d-flex p-flex-column card-inner-body"
	 [style.height]="presentationMode ? '125mm' : '100%'">

	<div class="map-container" #mapContainer
		 >
		<div class="p-field-checkbox map-checkbox" *ngIf="!presentationMode">
			<p-checkbox [(ngModel)]="panel.show_police" (onChange)="moveEndHandler()"
						[binary]="true"
						inputId="binaryPolice"></p-checkbox>
			<label [for]="'binaryPolice'" *ngIf="panel.table == 'incident'">Toon politie-incidenten</label>
			<label [for]="'binaryPolice'" *ngIf="panel.table == 'mk_incident'">Toon ABC-incidenten</label>
		</div>

		<div class="download-map-icon" *ngIf="!presentationMode">
			<button (click)="copyRequest()" pTooltip="Kopieer afbeelding" tooltipPosition="left"
					class="p-button p-button-rounded p-button-info p-button-icon-only">
				<ibm-icon-copy size="20"></ibm-icon-copy>
			</button>
		</div>

		<div class="loader" *ngIf="loading">
			<i class="fal fa-1x fa-spinner-third fa-spin"></i>
		</div>

		<div tabindex="1" [id]="mapElementId" class="map"></div>


		<div class="legend-map" *ngIf="currZoom <= zoomSwitchLevel && !presentationMode">
			<div class="p-p-1">
				<p class="p-m-0">
					Kaart toont een indicatie van aantal incidenten in een gebied. Donkere kleur
					betekent meer incidenten.
				</p>
				<p class="p-m-0">
					Zoom in om individuele incidenten te bekijken.
				</p>
			</div>
		</div>

		<div class="legend-map" *ngIf="currZoom > zoomSwitchLevel">
			<h3 class="p-mt-0 p-mb-1">Legenda</h3>
			<ul>
				<li *ngFor="let c of mapColors">
					<span class="p-pr-2">
						<i class="fa fa-square" [style.color]="c.color"></i>
					</span>
					<span>{{c.label}}</span>
				</li>
				<li>
					<span class="p-pr-2">
						<i class="fa fa-square" [style.color]="policeColor"></i>
					</span>
					<span>Politie</span>
				</li>
			</ul>
		</div>

	</div>

</div>

<ng-template #reloading>
	reloading..
</ng-template>

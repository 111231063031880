import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import _ from "lodash";
import {Subscription} from "rxjs";
import {GroupOption} from "../../../types/sherpa";
import {AnalysePanel} from "../../../classes/models/AnalysePanel";

@Component({
	selector: 'base-incident-table',
	templateUrl: './incident-table.component.html',
	styleUrls: ['./incident-table.component.scss']
})
export class IncidentTableComponent implements OnInit {

	@Input() panel: AnalysePanel;
	@Input() presentationMode: boolean;
	@Input() groupOptions$: Promise<GroupOption[]>;

	showBackground = true; // always background
	panelGroupBy: GroupOption[];
	showValues: boolean; // show values(labels) in columns

	@Output() removeDataEvent: EventEmitter<any> = new EventEmitter<any>();
	@Output() setShowValues: EventEmitter<boolean> = new EventEmitter<boolean>();

	subscription: Subscription;
	groupOptions: GroupOption[];
	title: string;
	colgroup: string;
	plain: number[] = [];
	tableData: any[] = [];
	customColumns: any[] = [];

	exactHeight: string = null;

	constructor() {
	}

	ngOnInit(): void {
		this.panelGroupBy = this.panel.groupBy;
		this.showValues = this.panel.show_labels;

		this.groupOptions$.then(options => {
			this.groupOptions = [];

			_.forEach(options, groupOption => {
				if (groupOption.subGroups === null) {
					this.groupOptions.push(groupOption);
					return; // continue;
				}

				_.forEach(groupOption.subGroups, sub => {
					this.groupOptions.push(sub);
				});
			});

			if(this.panelGroupBy.length > 0) {
				const option = this.panelGroupBy[0];
				this.title = _.find(this.groupOptions, ['key', option.key])?.title;
			}

			if(this.panelGroupBy.length > 1) {
				const option = this.panelGroupBy[1];
				this.colgroup = _.find(this.groupOptions, ['key', option.key])?.title;
			}
		});

		this.initTable(this.panel.resultData);
	}

	ngAfterViewInit() {
		_.delay(() => {
			const height = document.getElementById('table-wrapper').getBoundingClientRect().height;
			this.exactHeight = _.floor(height) + 'px';
		},250);
	}

	initTable(chartData: any) {

		this.tableData = [];
		this.customColumns = [];
		this.plain = [];

		_.forEach(chartData.labels, label => {
			this.tableData.push({
				label: label
			});
		});

		let i: number = 0;

		_.forEach(chartData.datasets, set => {
			if(set.label === 'Trend') {
				return; // continue
			}

			this.customColumns.push(set.label);

			_.forEach(set.data, data => {
				this.tableData[i][set.label] = data;
				i++;
				this.plain.push(data);
			});
			i=0;
		});

		this.plain = _.orderBy(this.plain);
	}

	getBackgroundStyle(data: number) {
		if(!this.showBackground) {
			return {};
		}

		let color: string = '#000000';
		let bg: string;

		if(data < this.getPercentile(20)) {
			bg = '#ffffff';
		}
		else if(data <= this.getPercentile(40)) {
			bg = '#CFE0FC';
		}
		else if(data <= this.getPercentile(60)) {
			bg = '#84B1FA';
		}
		else if(data <= this.getPercentile(80)) {
			bg = '#3272D9';
			color = '#ffffff';
		}
		else {
			bg = '#114599';
			color = '#ffffff';
		}

		let style = {
			'background-color': bg,
			'color': color
		};

		return style;
	}

	getPercentile(percentage: number) {
		let position = (this.plain.length * percentage) / 100;
		const index = _.round(position);
		return this.plain[index];
	}

	getTotal(key: string) {
		let count: number = 0;

		_.forEach(this.tableData, row => {
			count += row[key];
		});

		return count;
	}

	getPercentage(key: string, data: number) {
		const total = this.getTotal(key);
		return (100 * data) / total;
	}
}

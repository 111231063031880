<p-toast></p-toast>

<p-card header="DOVA Sociale Veiligheid">
	<p>Welkom bij 'Sociale Veiligheid', beheer en analyse van incidenten.</p>
	<br/>


	<div class="p-d-flex p-jc-between">
		<div>
			<h2>Mijn filter sinds laatste login:</h2>
		</div>
		<div></div>
	</div>

	<div class="p-fluid p-formgrid p-grid">
		<div class="p-field p-col-12 p-md-6">
			<label for="authority">Autoriteit</label>
			<p-dropdown id="authority" [options]="[]"></p-dropdown>
		</div>
		<div class="p-field p-col-12 p-md-6">
			<label for="concession">Concessie</label>
			<p-chips inputId="concession" [(ngModel)]="values"></p-chips>
		</div>
		<div class="p-field p-col-12 p-md-6">
			<label for="time">Tijdsvak</label>
			<input id="time" type="text" pInputText value="2019">
		</div>
		<div class="p-field p-col-12 p-md-6">
			<label for="context">Context</label>
			<input id="context" type="text" pInputText value="Lijnen">
		</div>
		<div class="p-field p-col-12 p-md-6">
			<label for="time">Type incident</label>
			<input id="time" type="text" pInputText value="A, B, C">
		</div>
		<div class="p-field p-col-12 p-md-6">
			<label for="context">Extra</label>
			<input id="context" type="text" pInputText value="123456">
		</div>
	</div>

	<ng-template pTemplate="footer">
		<p-button label="Herstel naar standaard filter" icon="fa fa-sync" styleClass="p-button-sm p-mr-2"
				  (onClick)="resetFilter()"></p-button>
		<p-button label="Opslaan" icon="pi pi-check" styleClass="p-button-sm p-button-success"
				  (onClick)="saveFilter()"></p-button>
	</ng-template>

</p-card>

<p-card styleClass="p-mt-3">
	<h1>Multiple Selection with Checkbox</h1>
	<p-tree [value]="treeNodes" selectionMode="checkbox" [(selection)]="selectedNode"></p-tree>
</p-card>


<p-card styleClass="p-mt-3">
	<h1>ABC-categorie</h1>
	<p-tree [value]="treeNodes2" selectionMode="checkbox" [(selection)]="selectedNode2"></p-tree>
</p-card>

<p-card styleClass="p-mt-3">
	<h1>Bar-chart</h1>
	<p-chart type="bar" [data]="data" [options]="dataOptions"></p-chart>

	<h1>Mixed chart</h1>
	<p-chart type="bar" [data]="data2" [options]="dataOptions2"></p-chart>
</p-card>


<p-card styleClass="p-mt-3">
	<h1>Polar-chart</h1>
	<p-chart type="polarArea" [data]="data3" [options]="dataOptions3"></p-chart>
</p-card>

<p-card styleClass="p-mt-3">
	<h1>Table demo</h1>
	<p-table [value]="sales">
		<ng-template pTemplate="header">
			<tr>
				<th rowspan="3">1/1/2018 - 1/7/2019</th>
				<th colspan="4">OV Incidenten per categorie</th>
			</tr>
			<tr>
				<th colspan="2">Categorie A</th>
				<th colspan="2">Categorie B</th>
			</tr>
			<tr>
				<th>A1</th>
				<th>A1a</th>
				<th>B1</th>
				<th>B1a</th>
			</tr>
		</ng-template>
		<ng-template pTemplate="body" let-sale>
			<tr>
				<td>{{sale.date}}</td>
				<td>{{sale.a1}}</td>
				<td>{{sale.a2}}</td>
				<td>{{sale.b1}}</td>
				<td>{{sale.b2}}</td>
			</tr>
		</ng-template>
		<ng-template pTemplate="footer">
			<tr>
				<td colspan="1" class="p-text-right">Totalen</td>
				<td>{{totalA1}}</td>
				<td>{{totalA2}}</td>
				<td>{{totalB1}}</td>
				<td>{{totalB2}}</td>
			</tr>
		</ng-template>
	</p-table>
</p-card>
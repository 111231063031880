import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {
	Filter,
	FilterField,
	GroupOption
} from "../../../types/sherpa";
import {BehaviorSubject, Observable, Subscription} from "rxjs";
import {util} from "../../../../services";
import _ from "lodash";
import {IncidentsService} from "../../../services/data/incidents.service";
import {AnalysePanel} from "../../../classes/models/AnalysePanel";
import {ActivatedRoute} from "@angular/router";
import {SelectItem} from "primeng/api";
import {PeriodComparison} from "../../../classes/models/PeriodComparison";

@Component({
	selector: 'base-filters-sidebar',
	templateUrl: './filters-sidebar.component.html',
	styleUrls: ['./filters-sidebar.component.scss']
})
export class FiltersSidebarComponent implements OnInit {

	@Input() panelSubject: BehaviorSubject<AnalysePanel>;
	@Input() groupOptions$: Promise<GroupOption[]>;
	@Input() groupByOptionsPolice$: Promise<GroupOption[]>;

	@Output() onPanelChange: EventEmitter<AnalysePanel> = new EventEmitter<AnalysePanel>();

	filters: Filter[];
	panelSubscription: Subscription;
	panel: AnalysePanel = null;

	submitted: boolean = false;
	periodComparisonChecked: boolean = false;
	monthWindowSizeChecked: boolean = false;
	hasDateFilter: boolean = false;
	useShortLabels: boolean = false;
	autoApply: boolean = false;
	autoCloseFilters: boolean = false;
	filterExtentEnabled: boolean;
	toggleMoreFilters: boolean = false;

	groupOptions: GroupOption[] = [];
	sortFilterOptions: SelectItem[] = [];
	topFilterOptions: SelectItem[] = [];
	orderFilterOptions: SelectItem[] = [];

	constructor(private cd: ChangeDetectorRef, private route: ActivatedRoute, private incidentsService: IncidentsService) {

		this.sortFilterOptions = [
			{value: false, label: "Categorie op x-as"},
			{value: true, label: "Aantal incidenten"}
		];

		this.orderFilterOptions = [
			{value: 'asc', label: "Op", icon: 'far fa-sort-amount-down-alt fa-rotate-270'},
			{value: 'desc', label: "Neer", icon: 'far fa-sort-amount-up-alt fa-flip-and-rotate'}
		];

		this.topFilterOptions = [
			{label: '5', value: 5},
			{label: '10', value: 10},
			{label: '15', value: 15},
			{label: '25', value: 25},
			{label: '100', value: 100},
			{label: 'Onbeperkt', value: null, icon: 'far fa-infinity'}
		];
	}

	ngOnInit(): void {

		this.panelSubscription = this.panelSubject.subscribe(panel => {
			if (panel === null) {
				return;
			}

			// console.log('panelSubscription', panel.filters);

			// new panel selected
			this.panel = panel;
			this.filters = panel.filters;
			this.initFiltersCheckboxSelections();
			this.initGroupOptions();


			// refresh filters first
			if(this.panel.relevantFiltersOnly || this.filters.length === 0) {
				this.getFilters();
			}

			this.useShortLabels = panel.use_short_labels;
			this.periodComparisonChecked = panel.comparison !== null;

			this.setHasDateFilter();
			this.monthWindowSizeChecked = panel.month_window_size !== null;

			this.initComparison(panel.comparison);
			this.cd.markForCheck();
		});
	}

	initGroupOptions() {
		if(this.panel.table == 'mk_incident') {
			this.groupByOptionsPolice$.then(options => {
				this.groupOptions = [];

				_.forEach(options, groupOption => {
					if (groupOption.subGroups === null) {
						this.groupOptions.push(groupOption);
						return; // continue;
					}

					_.forEach(groupOption.subGroups, sub => {
						this.groupOptions.push(sub);
					});
				});
			});
		}
		else {
			this.groupOptions$.then(options => {
				this.groupOptions = [];

				_.forEach(options, groupOption => {
					if (groupOption.subGroups === null) {
						this.groupOptions.push(groupOption);
						return; // continue;
					}

					_.forEach(groupOption.subGroups, sub => {
						this.groupOptions.push(sub);
					});
				});
			});
		}
	}

	initComparison(comparison: PeriodComparison) {
		if(comparison === null) {
			comparison = {a: 0, asPerc: false, b: 0, key: "date_year"} as PeriodComparison;
		}

		this.panel.comparison = this.periodComparisonChecked ? comparison : null;
	}

	initMonthWindow() {
		this.panel.month_window_size = this.monthWindowSizeChecked ? 1 : null;
	}

	setHasDateFilter() {
		const found = _.find(this.panel.andFilterFields, ['key', 'date']);
		if(!found) {
			this.hasDateFilter = false;
			return;
		}

		this.hasDateFilter = found.expectedValue.length > 0;
	}

	comparisonChanged(comparison: PeriodComparison) {
		this.panel.comparison = comparison;

		if (this.autoApply) {
			this.applyFilters();
		}
	}

	panelPropertyChanged(property: string, value: any) {
		if(_.has(this.panel, property)) {
			_.set(this.panel, property, value);
		}

		if (this.autoApply) {
			this.applyFilters();
		}
	}

	filterChanged(filterField: FilterField) {
		// changes found but no api-call yet
		this.submitted = false;

		//console.log('filterChanged', filterField);

		// update special cases
		if (filterField.key === 'extent') {
			this.panel.extent = filterField.expectedValue;
		} else if (filterField.key === 'orderByIncidents') {
			this.panel.orderByIncidents = filterField.expectedValue;
		} else if (filterField.key === 'top') {
			this.panel.top = filterField.expectedValue;
		} else if (filterField.key === 'groupBy') {
			this.panel.groupBy = filterField.expectedValue;
		} else if (filterField.key === 'sortOrder') {
			this.panel.sortOrder = filterField.expectedValue;
		} else {
			this.panel.andFilterFields = util.upsert(this.panel.andFilterFields, filterField, 'key');
		}

		this.setHasDateFilter();

		if (this.autoApply) {
			this.applyFilters();
		}
	}

	applyFilters() {
		// remove nulls
		this.panel.andFilterFields = _.filter(this.panel.andFilterFields, (ff: FilterField) => {
			return ff.expectedValue !== null && _.size(ff.expectedValue) > 0;
		});

		// remove incomplete comparison
		if (this.panel.comparison !== null) {
			if (this.panel.comparison.a === 0 || this.panel.comparison.b === 0) {
				this.panel.comparison = null;
			}
		}

		// set display options
		this.panel.use_short_labels = this.useShortLabels;

		// set filters
		this.panel.filters = this.filters;

		this.onPanelChange.emit(this.panel);

		// fetch new filters with the new analyse-config
		if(this.panel.relevantFiltersOnly) {
			this.getFilters();
		}
	}

	clearFilters() {
		this.panel.andFilterFields = [];
		this.panel.groupBy = [];
		this.panel.comparison = null;
		this.panel.extent = null;
		this.panel.top = null;
		this.panel.sortOrder = false;
		this.panel.orderByIncidents = false;
		this.panel.relevantFiltersOnly = false;

		// broadcast this (changed)panel to update all filter-checkboxes
		this.panelSubject.next(this.panel);

		// commit
		this.applyFilters();
	}

	getFilters() {

		if(this.panel.table == 'mk_incident') {
			this.panel.analyse_type = 'mk_incidents';
		}
		const typeOnly = {analyse_type: this.panel.analyse_type} as AnalysePanel;
		const panelConfig = this.panel.relevantFiltersOnly ? this.panel : typeOnly;

		this.incidentsService.getFilters(panelConfig).then(updatedFilters => {
			this.filters = updatedFilters;
		});
	}

	initFiltersCheckboxSelections() {
		this.filterExtentEnabled = this.panel === null ? false : this.panel.extent !== null;

		_.forEach(this.filters, filter => {
			// when disabled (by backend-end) check if filter is in use and set filter.enabled
			if (!filter.enabled) {
				let hasAlreadyPanel: boolean = this.panel === null ? false : _.some(this.panel.andFilterFields, ['key', filter.key]);
				if (hasAlreadyPanel) {
					filter.enabled = true;
				}
			}
		});

		this.toggleMoreFilters = !_.some(this.filters, 'enabled');
	}

	saveFilterSettings() {
		this.incidentsService.saveFilterSettings(this.filters);
	}

	ngOnDestroy() {
		this.panelSubscription.unsubscribe();
		this.panelSubject.unsubscribe();
	}
}

<div class="p-grid" style="height: 100%">
	<div class="p-col-7">
		<p-card header="Autoriteiten" styleClass="is-fullheight">
			<p>Overzicht incidenten</p>
			<div id="incidents-map" class="map"></div>
		</p-card>
	</div>

	<div class="p-col-5">
		<p-card header="Charts">
			<p-chart type="bar" [data]="chartData"></p-chart>
		</p-card>
	</div>
</div>


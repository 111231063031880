
<div class="p-d-flex p-flex-wrap p-pt-1 p-pb-2" *ngIf="panel !== null && (groupOptions$ | async)">
	<div class="p-mr-2 p-mt-1 p-mb-1" *ngIf="panel.extent !== null">
		<button pButton pRipple class="p-button-sm p-p-1"
				  (click)="!preview && openSingleFilter({key: 'extent', expectedValue: null})"
				  [label]="'Gebied'"></button>
	</div>

	<div class="p-mr-2 p-mt-1 p-mb-1"
		 [ngClass]="{'p-d-none': field.key == 'mk_code' && panel.type == 'map' && panel.table == 'incident'}"
		 *ngFor="let field of panel.andFilterFields">
		<button pButton pRipple class="p-button-sm p-p-1"
				  (click)="!preview && openSingleFilter(field)"
				  [label]="getButtonTagLabel(field)"></button>
	</div>

	<div class="p-mr-2 p-mt-1 p-mb-1" *ngIf="panel.month_window_size != null && !hasDateFilter">
		<button pButton pRipple class="p-button-sm p-p-1 p-button-secondary" [disabled]="true"
				[label]="getButtonDateLabel()"></button>
	</div>

	<!-- always show -->
	<div class="p-mr-2 p-mt-1 p-mb-1" *ngIf="panel.type === 'map' && panel.table == 'incident'">
		<button pButton pRipple class="p-button-sm p-p-1 p-button-warning"
				(click)="!preview && openSingleFilter({key: 'mk_code', expectedValue: null})"
				[label]="getButtonTagLabel({key: 'mk_code', expectedValue: null})"></button>
	</div>

	<ng-container *ngIf="!submitted">
		<div class="far fa-exclamation-triangle p-d-flex p-ai-center"
			pTooltip="Let op: Je hebt niet op toepassen geklikt"></div>
	</ng-container>
</div>


<!-- QUICK-FILTER MODAL -->
<p-dialog [header]="'Filter [' + (selectedField !== null ? filtersMap.get(selectedField.key)?.title : '..') + ']'"
		  [(visible)]="showEditFilterDialog"
		  [style]="{width: '50vw'}"
		  [draggable]="true"
		  [modal]="true"
		  [position]="'top'"
		  [styleClass]="'edit-filter-dialog'"
		  [baseZIndex]="10000">

	<ng-container *ngIf="showEditFilterDialog">
		<base-edit-filter-extent *ngIf="selectedField.key === 'extent'"
			(onFilterFieldChanged)="filterChanged($event)"
			[panel]="panel">
		</base-edit-filter-extent>

		<ng-container *ngFor="let filter of filters; let i = index;">
			<base-edit-filter-multiple *ngIf="filter.key === selectedField?.key"
									   (onFilterFieldChanged)="filterChanged($event)"
									   [panel]="panel"
									   [filter]="filter">
			</base-edit-filter-multiple>
		</ng-container>

		<base-edit-filter-multiple *ngIf="selectedField.key === 'mk_code' && panel.table == 'incident' && mkFilter !== null"
								   (onFilterFieldChanged)="filterChanged($event)"
								   [panel]="panel"
								   [filter]="mkFilter">
		</base-edit-filter-multiple>
	</ng-container>

	<ng-template pTemplate="footer">
		<p-toolbar styleClass="p-p-0 p-mt-3">
			<div class="p-toolbar-group-left"></div>

			<div class="p-toolbar-group-right">
				<button pButton pRipple type="button" (click)="showEditFilterDialog = false;"
						label="Sluiten"
						class="p-button-secondary p-button-text p-button-rounded p-mr-2"></button>
				<button pButton pRipple type="button" (click)="applyFilters(); showEditFilterDialog = false;"
						label="Toepassen"
						class="p-button-rounded p-text-uppercase p-m-0"></button>
			</div>
		</p-toolbar>
	</ng-template>
</p-dialog>
export enum TileCacheType {
	OSM = "openstreetmap",
	OSM_BRIGHT = "osm-bright",
	POSITRON = "positron",
	ROUTEMAKER = "routemaker-compleet",
	OPENTOPO = "opentopo",
	SATELLITE = "luchtfoto",
	DEFAULT = "",
	MAPNIK = "mapnik",
	HYDDA = "hydda",
	HYDDA_FULL = "hydda_full",
	IRIAS = "irias"
}
import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {AnalysePanel} from "../../../classes/models/AnalysePanel";
import {BehaviorSubject, Subscription} from "rxjs";
import {GroupOption} from "../../../types/sherpa";
import _ from "lodash";

@Component({
	selector: 'base-card-table',
	templateUrl: './card-table.component.html',
	styleUrls: ['./card-table.component.scss']
})
export class CardTableComponent implements OnInit, OnDestroy {

	@Input() panel: AnalysePanel = null;
	@Input() presentationMode = false;
	@Input() reloadSubject: BehaviorSubject<AnalysePanel>;
	@Input() groupByOptions$: Promise<GroupOption[]>;

	reloadSubscription: Subscription;

	constructor() {
	}

	ngOnInit(): void {
		this.reloadSubscription = this.reloadSubject.subscribe(panelWithUpdates => {
			if(panelWithUpdates == null) {
				return;
			}

			if(panelWithUpdates.index == this.panel.index) {
				this.panel = panelWithUpdates;
				this.reload();
			}
		});
	}

	reload() {
		this.panel.reload = false;
		_.delay(() => {
			this.panel.reload = true;
		}, 0);
	}

	ngOnDestroy() {
		this.reloadSubscription.unsubscribe();
	}
}

import {Component, OnInit} from '@angular/core';
import {HttpHeaders} from "@angular/common/http";
import { util} from "../../../../../services";
import {SourceView, SvovdaFields} from "../../../../types/sherpa";
import _ from "lodash";
import {IncidentsService} from "../../../../services/data/incidents.service";
import {MessageService} from "primeng/api";

@Component({
	selector: 'base-imports',
	templateUrl: './imports.component.html',
	styleUrls: ['./imports.component.scss']
})
export class ImportsComponent implements OnInit {

	headers: HttpHeaders;
	imports$: Promise<SourceView[]>;
	fields : SvovdaFields = null;

	constructor(private messageService: MessageService, private incidentsService: IncidentsService) {
	}

	ngOnInit(): void {
		this.imports$ = this.incidentsService.getImports();

	}

}

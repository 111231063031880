import {Component, OnInit} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {DynamicDialogConfig, DynamicDialogRef} from "primeng/dynamicdialog";
import {MessageService} from "primeng/api";
import _ from "lodash";
import {UserService} from "../../services/user.service";
import {SessionService} from "../../services/session.service";
import {TrustedDevice} from "../../classes/TrustedDevice";
import DeviceDetector from "device-detector-js";

@Component({
	selector: 'base-trusted-device-edit',
	templateUrl: './trusted-device-edit.component.html',
	styleUrls: ['./trusted-device-edit.component.scss']
})
export class TrustedDeviceEditComponent implements OnInit {

	formGroup: FormGroup;
	pending: boolean = false;
	done: boolean = false;

	device: TrustedDevice;
	friendlyUserAgent: string = null;

	public get controls() {
		return this.formGroup.controls;
	}

	constructor(private fb: FormBuilder, public config: DynamicDialogConfig, public ref: DynamicDialogRef, public messageService: MessageService, private session: SessionService, private user: UserService) {
	}

	ngOnInit() {
		this.device = this.config.data.device as TrustedDevice;

		if(_.startsWith(this.device.agent, 'Mozilla/')) {
			const deviceDetector = new DeviceDetector();
			const parsedAgent = deviceDetector.parse(this.device.agent);

			if(parsedAgent.device !== null) {
				if(parsedAgent.device.type === 'desktop') {
					this.friendlyUserAgent = `${parsedAgent.os.name} ${parsedAgent.os.version} - `;
				}
				else if(parsedAgent.device.type === 'smartphone') {
					this.friendlyUserAgent = `${parsedAgent.device.brand} ${parsedAgent.device.model} - `;
				}

				this.friendlyUserAgent += `${parsedAgent.client.name} (${parsedAgent.client.version})`;
			}
		}


		this.formGroup = this.fb.group({
			agent: [this.device.agent, Validators.required],
		});
	}

	hasError(control: AbstractControl, errorCode: string = null): boolean {

		// no interaction yet
		if(control.pristine || control.untouched) {
			return false;
		}

		// match specific errorCode
		if(errorCode !== null) {
			return control.hasError(errorCode);
		}

		// return true when some errorCode
		return !_.isNil(control.errors);
	}

	submit() {
		if (this.formGroup.invalid) {
			return;
		}

		this.pending = true;
		this.done = false;

		const param = {
			trusted_device_id: this.device.trusted_device_id,
			agent: this.controls.agent.value
		} as Partial<TrustedDevice>;

		this.user.updateTrustedDevice(param)
			.then(x => {

				_.delay(() => {
					this.done = true;

					param.timestamp = this.device.timestamp;
					this.config.data.update(param);

					_.delay(() => {
						this.pending = false;
					}, 1500);

				}, 500);


			});
	}
}
